import { withStyles, Styles, css, extractFont } from '@recruitrobin/robin-theme/web-styles';
import { cssBreakpoints } from 'styles/breakpoints';

const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  loginForm: () => css`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1001px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  `,
  rightColumnWrapper: ({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    background: ${theme.colors.secondary.emerald[60]};

    @media screen and (max-width: 1000px) {
      display: none;
    }
  `,
  logoWrapper: () =>
    css({
      gap: 25,
      marginBottom: 44,
      justifyContent: 'center',
      alignItems: 'center',
    }),
  nameLogo: () =>
    css({
      height: 78,
    }),
  title: () => css({}),
  form: () =>
    css({
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 32,
    }),
  input: () =>
    css({
      gap: 14,
      width: '100%',
      marginBottom: 32,
    }),
  options: ({ theme }) =>
    css({
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.colors.neutral.variant[90],
      a: {
        ...extractFont((font) => font.supporting.label, 'web'),
        textDecoration: 'none',
        color: theme.colors.primary.fuchsia[50],
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
  links: ({ theme }) =>
    css({
      display: 'block',
      a: {
        textDecoration: 'none',
        color: theme.colors.primary.fuchsia[80],
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
  error: ({ theme }) =>
    css({
      color: theme.colors.tertiary.red[80],
      width: '100%',
      textAlign: 'left',
      margin: 0,
    }),
  keyVisual: () =>
    css({
      width: '100%',
      objectFit: 'cover',
      [cssBreakpoints.mobile]: {
        height: 'auto',
        width: '100%',
        objectFit: 'cover',
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

import { useQuery } from '@tanstack/react-query';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { jobBoardLabel } from 'helpers/jobBoards';
import { Candidate } from 'model';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useFiltersContext } from 'shared/contexts/SearchContext/FiltersContext/helpers';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useBrowserExtension } from 'shared/hooks';
import { CandidateContextProps, OutreachInformationWhyNotLabel, OutreachInformationWhyNotLabels } from '../types';
import { DISABLED_OUTREACH_ERROR } from './errors';

export const useIsWerknlActive = () => {
  const { data = false } = useQuery(
    ['crawler-is-werknl-active'],
    async () => {
      try {
        const response = await crawlerClient.isWerknlActive();

        return response.status === 200;
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          return false;
        }

        throw e;
      }
    },
    { keepPreviousData: true, retry: 0 },
  );

  return useMemo(() => {
    return data;
  }, [data]);
};

export const useOutreach: UseOutreachHook = (candidate) => {
  const { dictionary } = useContext(LocalizationContext);
  const { info: browserExtensionInfo, indeedStatus, linkedinStatus } = useBrowserExtension();
  const { currentUser } = useContext(UserContext);
  const [locations] = useFiltersContext('locations');
  const isWerknlActive = useIsWerknlActive();

  const disabledMessage: OutreachInformationWhyNotLabels = useMemo(() => {
    return {
      unavailableOnThisContactMethod: {
        label: dictionary.unavailableOnThisContactMethod,
      },
      browserExtensionNotInstalled: {
        label: dictionary.browserExtensionNotInstalled,
      },
      needsLoginOnJobboard: {
        label: dictionary.needsLoginOnJobboard,
      },
    };
  }, [dictionary]);

  const outreachInformationWhyNotEnabled: CandidateContextProps['outreachInformationWhyNotEnabled'] = useMemo(() => {
    const disabledError: CandidateContextProps['outreachInformationWhyNotEnabled']['disabledError'] = {
      linkedin: undefined,
      indeed: undefined,
      email: undefined,
      whatsapp: undefined,
      call: undefined,
      werknl: undefined,
    };
    let whyLinkedinNotEnabled = undefined;
    let whyIndeedNotEnabled = undefined;
    let whyEmailNotEnabled = undefined;
    let whyWhatsappNotEnabled = undefined;
    let whyCallNotEnabled = undefined;
    let whyWerknlNotEnabled = undefined;

    // Check browser extensions
    if (Object.keys(browserExtensionInfo).length === 0) {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.BROWSER_EXTENSION_NOT_INSTALLED;
      disabledError.indeed = DISABLED_OUTREACH_ERROR.BROWSER_EXTENSION_NOT_INSTALLED;

      whyLinkedinNotEnabled = disabledMessage.browserExtensionNotInstalled;
      whyIndeedNotEnabled = disabledMessage.browserExtensionNotInstalled;
    }

    // Needs login
    if (linkedinStatus === 'Logged Out') {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.LOGGED_OUT;
      whyLinkedinNotEnabled = disabledMessage.needsLoginOnJobboard;
    }

    if (indeedStatus === 'Logged Out') {
      disabledError.indeed = DISABLED_OUTREACH_ERROR.LOGGED_OUT;
      whyIndeedNotEnabled = disabledMessage.needsLoginOnJobboard;
    }

    // Unavailable on this contact method
    if (!candidate?.contact_info.email) {
      disabledError.email = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyEmailNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (!candidate?.contact_info.phone) {
      disabledError.whatsapp = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyWhatsappNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (candidate?.activeJobBoard !== 'cvt') {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyLinkedinNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (candidate?.activeJobBoard !== 'indeed') {
      disabledError.indeed = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyIndeedNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (candidate?.activeJobBoard !== 'werknl' || !isWerknlActive) {
      disabledError.werknl = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyWerknlNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (!candidate?.contact_info.phone) {
      disabledError.call = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyCallNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    return {
      disabledError,
      whyEmailNotEnabled,
      whyWhatsappNotEnabled,
      whyLinkedinNotEnabled,
      whyIndeedNotEnabled,
      whyCallNotEnabled,
      whyWerknlNotEnabled,
    };
  }, [
    browserExtensionInfo,
    linkedinStatus,
    indeedStatus,
    candidate?.contact_info.email,
    candidate?.contact_info.phone,
    candidate?.activeJobBoard,
  ]);

  const outreachMethods: CandidateContextProps['outreachMethods'] = useMemo(() => {
    return {
      shouldEnableCall: !!candidate?.contact_info.phone,
      shouldEnableEmail: !!candidate?.contact_info.email,
      shouldEnableWhatsapp: !!candidate?.contact_info.phone,
      shouldEnableLinkedin: candidate?.activeJobBoard === 'cvt' && linkedinStatus === 'Logged In',
      shouldEnableIndeed: candidate?.activeJobBoard === 'indeed' && indeedStatus === 'Logged In',
      shouldEnableWerknl: candidate?.activeJobBoard === 'werknl' && isWerknlActive,
    };
  }, [
    candidate?.contact_info.email,
    candidate?.contact_info.phone,
    candidate?.activeJobBoard,
    indeedStatus,
    linkedinStatus,
    isWerknlActive,
  ]);

  const personalizationTokens: CandidateContextProps['personalizationTokens'] = useMemo(
    () => ({
      firstName: candidate?.firstName || '',
      lastName: candidate?.lastName || '',
      fullName: [candidate?.firstName, candidate?.lastName].filter(Boolean).join(' ') || '',
      lastCompany: candidate?.lastThreePositions?.[0]?.companyName || '',
      lastPosition: candidate?.lastThreePositions?.[0]?.positionName || '',
      recruiterFullName: [currentUser?.first_name, currentUser?.last_name].filter(Boolean).join(' '),
      NameJobboard: candidate?.activeJobBoard ? jobBoardLabel(candidate.activeJobBoard) : '',
      NameVacancy: candidate?.campaign?.name || '',
      Location: locations.filter(Boolean) ? locations?.map((value) => value.key).join(', ') : '',
    }),
    [
      candidate?.firstName,
      candidate?.lastName,
      candidate?.lastThreePositions?.[0],
      currentUser?.first_name,
      currentUser?.last_name,
    ],
  );

  return useMemo(
    () => ({
      outreachMethods,
      personalizationTokens,
      outreachInformationWhyNotEnabled,
    }),
    [
      JSON.stringify(outreachMethods),
      JSON.stringify(personalizationTokens),
      JSON.stringify(outreachInformationWhyNotEnabled),
    ],
  );
};

type UseOutreachHook = (candidate: Candidate | undefined) => {
  outreachMethods: {
    shouldEnableCall: boolean;
    shouldEnableEmail: boolean;
    shouldEnableWhatsapp: boolean;
    shouldEnableLinkedin: boolean;
    shouldEnableIndeed: boolean;
    shouldEnableWerknl: boolean;
  };
  personalizationTokens: {
    firstName: string;
    fullName: string;
    lastCompany: string;
    lastName: string;
    lastPosition: string;
    recruiterFullName: string;
    NameJobboard: string;
    NameVacancy: string;
    Location: string;
  };
  outreachInformationWhyNotEnabled: {
    whyLinkedinNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyIndeedNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyEmailNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyWhatsappNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyCallNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyWerknlNotEnabled: OutreachInformationWhyNotLabel | undefined;
    disabledError: CandidateContextProps['outreachInformationWhyNotEnabled']['disabledError'];
  };
};

import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { CandidateOutreachModalContextStyleStates } from './types';

const styles = {
  overlayModal: () =>
    css({
      zIndex: 9,
      borderRadius: 14,
      boxShadow: '0px 5px 5px 8px rgba(47, 62, 76, 0.15), 0px 14px 14px 3px rgba(67, 83, 98, 0.15)',
    }),
  overlayConfirmModal: () =>
    css({
      zIndex: 10,
    }),
  contentConfirmModal: ({ theme }) =>
    css({
      '&&': {
        padding: 0,
        background: theme.colors.neutral.variant[5],
        borderRadius: 14,
      },
    }),
  modal: ({ theme }) =>
    css({
      '&&': {
        background: theme.colors.neutral.variant[0],
      },
    }),
  connectEmailAccountModal: () =>
    css({
      maxWidth: '600px',
    }),
} satisfies Styles<CandidateOutreachModalContextStyleStates>;

export const useStyles = withStyles(styles);

import { routes } from 'config';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

export const usePathId = () => {
  const pathMatch = useMatch<'id', string>(routes.search({ campaignId: ':id' }));
  const sharedPathMatch = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.sharedCandidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );
  const candidatePageMatch = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.candidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );

  return useMemo(() => {
    const sharedId = sharedPathMatch?.params.campaignId;
    const candidatePageId = candidatePageMatch?.params.campaignId;
    const searchV3Id = pathMatch?.params.id;
    const id = sharedId || candidatePageId || searchV3Id;

    const parsedId = parseInt(id ?? '');
    return Number.isNaN(parsedId) ? undefined : parsedId;
  }, [pathMatch, sharedPathMatch, candidatePageMatch]);
};

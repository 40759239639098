import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignsClient.types';

export const getShouldEnableTeamview = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const teamview = urlParams.get('teamview');

  return teamview === 'true' || teamview === null;
};

export const getShowNavbar = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const showNavbar = urlParams.get('showNavbar');

  return showNavbar === 'true';
};

export const getCampaignStatus = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status') as CampaignOverviewStatus;

  return status || CampaignOverviewStatus.active;
};

export const getShowCampaignOverviewButton = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const showCampaignOverviewButton = urlParams.get('showCampaignOverviewButton');

  return showCampaignOverviewButton === 'true' || showCampaignOverviewButton === null;
};

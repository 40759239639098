import { InformationQuoteProps } from './types';
import { useStyles } from './styles';
import { Row, Typography } from 'components/ui/atoms';
import { InformationTag } from 'components/ui/molecules';

const InformationQuote = ({ icon = 'Info', message }: InformationQuoteProps) => {
  const classes = useStyles();

  if (!message) return;

  return (
    <Row css={classes.root}>
      <InformationTag icon={icon} />
      <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.secondary.sky[60]} inline>
        {message}
      </Typography>
    </Row>
  );
};

export default InformationQuote;

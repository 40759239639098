export const LOG_LEVEL = {
  WARN: 'warn',
  INFO: 'info',
  ERROR: 'error',
} as const;

export const LOG_CUSTOM_ATTRIBUTE = {
  USER_ID: 'rr_user_id',
  USER_EMAIL: 'rr_user_email',
  TENANT_ID: 'rr_tenant_id',
  TENANT_NAME: 'rr_tenant_name',
} as const;

export const LOG_INFO = {
  MANUAL_LOG: 'manual_log',
  DIFF_APP_VERSION: 'diff_app_version',
} as const;

export type LoggerLevels = typeof LOG_LEVEL[keyof typeof LOG_LEVEL];

export type LogCustomAttribute = typeof LOG_CUSTOM_ATTRIBUTE[keyof typeof LOG_CUSTOM_ATTRIBUTE];

export type LoggerInfoKeys = typeof LOG_INFO[keyof typeof LOG_INFO];

export type LoggerProps = {
  debug?: boolean;
};

import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      background: theme.colors.secondary.emerald[10],
      minWidth: 346,
      borderRadius: 8,
      padding: '12px 16px',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

import { CandidateOutreachMessage, CandidateOutreachPersonalizationTokenKeys, CandidateOutreachProps } from './types';
import { useStyles } from './styles';
import { Column, CONTENT_EDITABLE_CLASS_NAME, Expand, Padding, Typography } from 'components/ui/atoms';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CandidateSelectOutreachTemplate,
  CandidateSelectOutreachTemplateOnSaveHandler,
  CandidateSelectOutreachTemplateOnSelectHandler,
  ContextMenuOption,
  DropdownButton,
  FieldLabelWrapper,
  InformationQuote,
  PhoneInput,
  TextArea,
  TextInput,
} from 'components/ui/molecules';
import { useDebounce } from 'react-use';
import { Outreach } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import { Send } from 'components/ui/atoms/icons';
import { isAxiosError } from 'helpers/clientHelpers';
import { useLocalStorage } from 'hooks/shared';

import { StopExecution } from 'shared/exceptions';
import { logger } from 'config/logger';
import TimeAgo from 'javascript-time-ago';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { outreachTemplateClient } from 'clients/OutreachTemplateclient/OutreachTemplateClient';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

const useSetDefaultValues = () => {
  const { currentUser } = useContext(UserContext);
  const { tenant, email, phone_number } = currentUser || {};

  return useMemo(
    () => ({
      email: email,
      phone: phone_number || undefined,
      website: tenant?.privacy_policy_link || undefined,
    }),
    [tenant?.code, email, phone_number],
  );
};

export const CandidateOutreachWerknl = ({
  candidateName,
  headerDescription,
  onSend,
  type,
  tokens,
  lastContact,
}: CandidateOutreachProps) => {
  const classes = useStyles();
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const phoneNumberWrapperRef = useRef<HTMLDivElement>(null);
  const emailWrapperRef = useRef<HTMLDivElement>(null);
  const websiteWrapperRef = useRef<HTMLDivElement>(null);
  const { dictionary, language } = useContext(LocalizationContext);
  const { createSnackbar, closeSnackbar } = useContext(SnackbarContext);
  const [selectedTemplate, setSelectedTemplate] = useState<Outreach | null>(null);
  const defaultValues = useSetDefaultValues();
  const { value: content = '', setValue: setContent } = useLocalStorage<string>(`candidate-outreach--content-${type}`);
  const { value: phoneNumber = '', setValue: setPhoneNumber } = useLocalStorage<string>(
    `candidate-outreach--phoneNumber-${type}`,
    defaultValues.phone,
  );
  const { value: email = '', setValue: setEmail } = useLocalStorage<string>(
    `candidate-outreach--email-${type}`,
    defaultValues.email,
  );
  const { value: website = '', setValue: setWebSite } = useLocalStorage<string>(
    `candidate-outreach--website-${type}`,
    defaultValues.website,
  );
  const [selectedTemplateHasChanges, setSelectedTemplateHasChanges] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [lastInputFocused, setLastInputFocused] = useState<{
    anchorNode: Node;
    anchorOffset: number;
    anchorFinalOffset: number | null;
  } | null>(null);

  const personalizationTokenKeys: Array<{ key: CandidateOutreachPersonalizationTokenKeys; en: string; nl: string }> = [
    { key: 'firstName', en: 'firstName', nl: 'voornaam' },
    { key: 'lastName', en: 'lastName', nl: 'achternaam' },
    { key: 'fullName', en: 'fullName', nl: 'volledigeNaam' },
    { key: 'lastPosition', en: 'lastPosition', nl: 'laatsteErvaring' },
    { key: 'lastCompany', en: 'lastCompany', nl: 'laatsteBedrijf' },
    { key: 'recruiterFullName', en: 'recruiterFullName', nl: 'recruiterVolledigeNaam' },
    { key: 'NameJobboard', en: 'NameJobboard', nl: 'NaamJobboard' },
    { key: 'NameVacancy', en: 'NameVacancy', nl: 'NameVacature' },
    { key: 'Location', en: 'Location', nl: 'Locatie' },
  ];

  const personalizationTokens = personalizationTokenKeys.map(({ key, en, nl }) => ({
    key,
    label: `{${language === 'en' ? en : nl}}`,
  }));

  const sentMessage = useMemo(
    () => ({ success: dictionary.emailSuccessfullySent, failed: dictionary.emailFailedSend }),
    [dictionary],
  );

  const timeAgo = useMemo(() => new TimeAgo(language === 'en' ? 'en-US' : 'nl-NL'), [language]);

  const isButtonDisabled = useMemo(
    () => !content || (!phoneNumber && !email),
    [phoneNumber, email, content, dictionary],
  );

  useEffect(() => {
    if (isSending) {
      const snackbarId = createSnackbar('Sending', { variant: 'loading', persist: true });

      return () => closeSnackbar(snackbarId);
    }
  }, [isSending]);

  useEffect(() => {
    const inputRef = inputWrapperRef.current;

    if (inputRef) {
      const selectionChangeListener = () => {
        const selection = window.getSelection();

        if (selection && selection.anchorNode && inputRef.contains(selection.anchorNode)) {
          setLastInputFocused({
            anchorNode: selection.anchorNode,
            anchorOffset: selection.anchorOffset,
            anchorFinalOffset: selection.anchorNode === selection.focusNode ? selection.focusOffset : null,
          });
        }
      };

      document.addEventListener('selectionchange', selectionChangeListener);

      return () => {
        document.removeEventListener('selectionchange', selectionChangeListener);
      };
    }
  }, []);

  useDebounce(
    () => setSelectedTemplateHasChanges(selectedTemplate !== null && selectedTemplate?.message !== content),
    16,
    [phoneNumber, content, website, selectedTemplate],
  );

  const handleSaveTemplate: CandidateSelectOutreachTemplateOnSaveHandler = async ({ templateName, createNew }) => {
    if (selectedTemplate && !createNew) {
      const { data } = await outreachTemplateClient.updateOutreachTemplate({
        id: selectedTemplate.id,
        type,
        name: templateName,
        message: content,
      });

      setSelectedTemplate(data);

      return data;
    }
    const { data } = await outreachTemplateClient.createOutreachTemplate({
      type,
      name: templateName,
      message: content,
    });

    setSelectedTemplate(data);

    return data;
  };

  const handleSelectTemplate: CandidateSelectOutreachTemplateOnSelectHandler = (opt) => {
    setSelectedTemplate(opt.id === -1 ? null : opt);
    setContent(opt.message ?? '');
  };

  const handleSelectToken = (opt: ContextMenuOption) => {
    if (lastInputFocused && typeof lastInputFocused.anchorNode.textContent === 'string' && opt.label) {
      const [inputRef, setter] =
        (contentWrapperRef.current?.contains(lastInputFocused.anchorNode) && [
          contentWrapperRef.current.querySelector(`.${CONTENT_EDITABLE_CLASS_NAME}`) ??
            contentWrapperRef.current.querySelector('textarea'),
          setContent,
        ]) ||
        [];

      if (inputRef?.tagName === 'TEXTAREA' && setter) {
        const inputValue = (inputRef as HTMLTextAreaElement).value;
        const anchorOffset = (inputRef as HTMLTextAreaElement).selectionStart;
        const anchorFinalOffset = (inputRef as HTMLTextAreaElement).selectionEnd;

        setter(
          inputValue.substring(0, anchorOffset) + opt.label + inputValue.substring(anchorFinalOffset || anchorOffset),
        );
      }
    }
  };

  const sendMessage = async ({ phoneNumber, content, website, email }: CandidateOutreachMessage = {}) => {
    setIsSending(true);

    try {
      await onSend({
        ...(phoneNumber && { phoneNumber }),
        ...(content && { content }),
        ...(website && { website }),
        ...(email && { email }),
      });

      createSnackbar(sentMessage.success);
    } catch (e) {
      if (e instanceof StopExecution) return;

      if (isAxiosError(e)) {
        logger.error(e);
        createSnackbar(e.message, { variant: 'danger' });

        return;
      }

      logger.error(e);
      createSnackbar(sentMessage.failed, { variant: 'danger' });
    } finally {
      setIsSending(false);
    }
  };

  const handleSend = async () => {
    const replaceWithTokens = (text: string) => {
      return personalizationTokenKeys.reduce((t, { key, en, nl }) => {
        return t.replace(new RegExp(`(\{${en}\})|(\{${nl}\})`, 'g'), tokens[key]);
      }, text);
    };

    sendMessage({
      phoneNumber: replaceWithTokens(phoneNumber),
      content: replaceWithTokens(content),
      website: replaceWithTokens(website),
      email: replaceWithTokens(email),
    });
  };

  return (
    <Column css={classes.root} gap={24}>
      <Column>
        <Column gap={4}>
          <Typography variant="header.h2" color={(c) => c.monochrome[100]}>
            {dictionary.newOutreach}
          </Typography>
          <Typography variant="header.h4">
            <Column gap={0}>
              <span>
                {dictionary.werknlMessageTo(candidateName)} <Expand width={8} />
              </span>
              {headerDescription && <span>{headerDescription}</span>}
            </Column>
          </Typography>
        </Column>

        {lastContact && (
          <InformationQuote
            icon="MessageCircle"
            message={dictionary.lastContactedBy({ when: timeAgo.format(lastContact.when).toString() })}
          />
        )}
      </Column>

      <Column css={classes.contentArea} gap={24}>
        <Column gap={8}>
          <CandidateSelectOutreachTemplate
            type={type}
            onSave={handleSaveTemplate}
            onSelect={handleSelectTemplate}
            selectedTemplateHasChanges={selectedTemplateHasChanges}
            contained
          />
        </Column>

        <Column ref={inputWrapperRef} gap={8}>
          <FieldLabelWrapper ref={contentWrapperRef} label={dictionary.content}>
            <TextArea css={classes.contentInput} text={content} onChange={setContent} sanitizeInput />
          </FieldLabelWrapper>

          <FieldLabelWrapper ref={emailWrapperRef} label={dictionary.werknlInputEmail}>
            <TextInput text={email} onChange={setEmail} placeholder="user@example.com" />
          </FieldLabelWrapper>

          <FieldLabelWrapper ref={phoneNumberWrapperRef} label={dictionary.werknlInputPhone}>
            <PhoneInput text={phoneNumber} onChange={setPhoneNumber} formatPhone={false} placeholder="062 3456789" />
          </FieldLabelWrapper>

          <FieldLabelWrapper ref={websiteWrapperRef} label={dictionary.website}>
            <TextInput text={website} onChange={setWebSite} placeholder="www.example.com" />
          </FieldLabelWrapper>
        </Column>

        <DropdownButton
          menuOptions={personalizationTokens}
          onSelectOption={handleSelectToken}
          variant="subtle"
          icon="Plus"
          label={dictionary.templateTokens}
        />
      </Column>

      <Padding css={classes.sendButton} bottom={24} contained>
        <Column css={classes.contentArea} gap={16}>
          <InformationQuote message={dictionary.werknlOutreachModalInfo} />

          <Button
            icon={<Send />}
            variant="highlight"
            label={dictionary.sendEmail}
            onClick={() => handleSend()}
            loading={isSending}
            disabled={isButtonDisabled}
            contained
          />
        </Column>
      </Padding>
    </Column>
  );
};

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CandidateActivityLog } from 'clients/CampaignsClient/CampaignsClient.types';
import { PaginatedResponse } from 'clients/types';
import { default as Candidate } from 'model/Candidate/Candidate';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { extractLastRefresh } from './extractLastRefresh';
import generateLabelsCacheKey from './generateLabelsCacheKey';
import { CandidateActivityHistory, HistoryStatus, SearchV3CandidateActivityLog } from './types';

export const useCandidateActivityLog = (candidate: Candidate | undefined, language: string) => {
  const { data, isFetching } = useQuery<PaginatedResponse<CandidateActivityLog>>(
    [
      QueryKey.candidateActivityLog,
      {
        candidateEsPersonId: candidate?.es_person_id,
        status: candidate?.status,
        labelsKey: generateLabelsCacheKey(candidate?.reasons || []),
      },
    ],
    async () => {
      if (!candidate) throw Error('Candidate not defined');

      const response = await campaignsClient.getCandidateActivityLog({
        candidate_id: candidate.es_person_id,
      });

      return response.data;
    },
    { enabled: !!candidate, cacheTime: 0 },
  );

  const activityLog = useMemo<CandidateActivityHistory[]>(() => {
    const results = data?.results || [];

    const resultsMapped = results
      .map((activityLog) => {
        const {
          id,
          action,
          created_at,
          campaign: { name },
          candidate_notification,
        } = activityLog;
        const type = getActionAsCandidateHistoryStatus(action);

        if ([HistoryStatus.Invisible, HistoryStatus.SentToAts, HistoryStatus.Unlocked].includes(type)) return undefined;

        const contactSendMethod = candidate_notification?.send_method;
        const by = activityLog.user.first_name;
        const subject = candidate_notification?.subject;
        const message = candidate_notification?.message;
        const signoff = candidate_notification?.sign_off;

        const hasMessage = subject || message || signoff;

        return {
          id,
          type,
          by,
          createdAt: new Date(created_at),
          campaignName: name,
          timeAgo: extractLastRefresh(created_at, language),
          contactSendMethod,
          byId: activityLog.user.id,
          content: hasMessage ? { message, signoff, subject } : undefined,
        } satisfies CandidateActivityHistory;
      })
      .filter(Boolean);

    return resultsMapped as CandidateActivityHistory[];
  }, [data]);

  return useMemo<SearchV3CandidateActivityLog>(
    () => ({ data: activityLog, isLoading: isFetching }),
    [activityLog, isFetching],
  );
};

export const getActionAsCandidateHistoryStatus = (action: CandidateActivityLog['action']): HistoryStatus => {
  switch (action) {
    case 'CAMPAIGN_CANDIDATE_CONTACTED':
      return HistoryStatus.Contacted;
    case 'CAMPAIGN_CANDIDATE_HIDDEN':
      return HistoryStatus.Hidden;
    case 'CAMPAIGN_CANDIDATE_INVISIBLE':
      return HistoryStatus.Invisible;
    case 'CAMPAIGN_CANDIDATE_LABEL_CHANGED':
      return HistoryStatus.LabelAdded;
    case 'CAMPAIGN_CANDIDATE_MATCHED':
      return HistoryStatus.Matched;
    case 'CAMPAIGN_CANDIDATE_SENT_TO_ATS':
      return HistoryStatus.SentToAts;
    case 'CAMPAIGN_CANDIDATE_SHORTLISTED':
      return HistoryStatus.Shortlisted;
    case 'CAMPAIGN_CANDIDATE_UNLOCKED':
      return HistoryStatus.Unlocked;
  }
};

export const getActivityLogAuthorIfNecessary = (activityLog: CandidateActivityLog): string | undefined => {
  return activityLog.user.first_name;
};

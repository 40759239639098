import { SingleSelectOption } from 'components/ui/molecules';
import { useEffect, useMemo, useState } from 'react';
import { useBrowserExtension } from 'shared/hooks';

export const useContainer = () => {
  const [indeedProjectsSelectedOption, setIndeedProjectsSelectedOption] = useState<SingleSelectOption | null>(null);
  const [indeedProjectsText, setIndeedProjectsText] = useState('');
  const { indeedProjects } = useBrowserExtension();

  const indeedProjectsOptions = useMemo<SingleSelectOption[]>(() => {
    return indeedProjects.map(({ key, name }) => ({ key, label: name }));
  }, [indeedProjects]);

  const indeedProjectsHandleFocus = () => undefined;

  const indeedProjectsHandleBlur = () => {
    if (indeedProjectsText.length > 0 && indeedProjectsSelectedOption) {
      setIndeedProjectsText(indeedProjectsSelectedOption.label);
    }

    if (indeedProjectsText.length === 0) {
      setIndeedProjectsSelectedOption(null);
    }
  };

  const indeedProjectsHandleChange = (opt: SingleSelectOption) => {
    setIndeedProjectsSelectedOption(opt);
  };

  const indeedProjectsHandleChangeText: React.FormEventHandler = (e) => {
    const target = e.target as HTMLInputElement;

    setIndeedProjectsText(target.value);
  };

  useEffect(() => {
    setIndeedProjectsText(indeedProjectsSelectedOption?.label || '');
  }, [indeedProjectsSelectedOption?.label]);

  useEffect(() => {
    const defaultProject = indeedProjects.find(({ name }) => name.toLowerCase() === 'default') || indeedProjects[0];

    if (!indeedProjectsSelectedOption && defaultProject) {
      setIndeedProjectsSelectedOption({
        key: defaultProject.key,
        label: defaultProject.name,
      });
    }
  }, [JSON.stringify(indeedProjects.sort())]);

  return useMemo(
    () => ({
      indeedProjects: {
        selected: indeedProjectsSelectedOption,
        text: indeedProjectsText,
        options: indeedProjectsOptions,
        handleBlur: indeedProjectsHandleBlur,
        handleFocus: indeedProjectsHandleFocus,
        handleChange: indeedProjectsHandleChange,
        handleChangeText: indeedProjectsHandleChangeText,
      },
    }),
    [indeedProjectsSelectedOption, indeedProjectsText, indeedProjectsOptions],
  );
};

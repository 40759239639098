import { CreateProjectResponse } from './indeed/createProject';
import type { IndeedJob } from './indeed/getJobsList';
import type { IndeedProject } from './indeed/getProjectsList';
import type { SendMessageResponse } from './indeed/sendMessage';

export type BrowserExtensionStatus = 'Logged In' | 'Unknown' | 'Logged Out';

export type SendLinkedinMessageParams = { customMessage?: string; inviteeProfileUrn: string };

export type SendLinkedinProMessageParams = {
  subject: string;
  body: string;
  signature: string;
  candidate_id: string;
};

export type SendIndeedMessageParams = {
  accountKeys: string[];
  senderName: string;
  senderCompany: string;
  message: string;
  jobTitle: string;
  description: string;
  projectKey: string;
};

export enum BrowserExtensionInfoStatus {
  ACTIVE = 'Active',
}

export type BrowserExtensionInfo = {
  manifest?: object;
  version?: string;
  status?: BrowserExtensionInfoStatus | string;
};

export type UseBrowserExtension = {
  linkedinStatus: BrowserExtensionStatus;
  linkedinProStatus: BrowserExtensionStatus;
  indeedStatus: BrowserExtensionStatus;
  info: BrowserExtensionInfo;
  linkedinCreditBalance: number | undefined;
  indeedCreditBalance: number | undefined;
  indeedProjects: IndeedProject[];
  indeedJobs: IndeedJob[];

  sendLinkedinMessage: (data: SendLinkedinMessageParams) => Promise<void>;
  sendIndeedMessage: (data: SendIndeedMessageParams) => Promise<SendMessageResponse>;
  sendLinkedinProMessage: (data: SendLinkedinProMessageParams) => Promise<void>;
  createIndeedProject: (name: string) => Promise<CreateProjectResponse>;
  hasLinkedinFreeInviteRemaining: () => Promise<boolean>;
};

export type LinkedinCreditsResponse = {
  inMailCreditInfo: {
    creditBalance: number;
    nextRefreshDate: number;
  };
};

export type IndeedResponse = {
  subscriptionInfo: SubscriptionInfo;
  baseIndeedUrl: string;
  baseMyIndeedUrl: string;
  baseAdsUrl: string;
  baseSecureUrl: string;
  baseBillingUrl: string;
  basePieUrl: string;
  baseEmployerUrl: string;
  baseIndeedEmployerHelpUrl: string;
  baseAnalyticsUrl: string;
  baseRozUrl: string;
  baseFloUrl: string;
  baseHiringProjectsUrl: string;
  baseSalesforceUrl: string;
  baseGatewayUrl: string;
  baseRezcowUrl: string;
  sharedComponentsRemoteEntryBaseUrl: string;
  currentUrl: string;
  currentRelativeUrl: string;
  locale: string;
  language: string;
  country: string;
  defaultSearchRadius: DefaultSearchRadius;
  csrfParam: string;
  csrfToken: string;
  loggedIn: boolean;
  confirmed: boolean;
  hasSetPassword: boolean;
  emailAddress: string;
  advertiser: string;
  moderated: boolean;
  billingIssue: string;
  privileged: boolean;
  showLaunchBanner: boolean;
  featuredEmployer: boolean;
  subscriptionsEnabled: boolean;
  canSwitchAccount: boolean;
  nonMonetizedMarket: boolean;
  hasAccessToConversations: boolean;
  alternateCountries: { [key: string]: string };
  alternateLanguages: AlternateLanguages;
  showLimitedProductPreview: boolean;
  currentUserAccountKey: string;
  showAnonymousSearchOptInAlert: boolean;
  switchAdvertiserInfo: SwitchAdvertiserInfo;
  disabledAccount: boolean;
  supportsFreeContacts: boolean;
  hasAdvertiser: boolean;
  outreachInterviewsOneGraphApiKey: string;
  datadogRumConfig: DatadogRumConfig;
  stagingLevel: string;
  advertiserNumber: string;
  advertiserKey: string;
  ctk: string;
  canShareProjects: boolean;
  masquerade: boolean;
  otruser: boolean;
};

export type AlternateLanguages = {};

export type DatadogRumConfig = {
  applicationId: string;
  clientToken: string;
  proxyHost: string;
};

export type DefaultSearchRadius = {
  distance: number;
  distanceUnit: string;
};

export type SubscriptionInfo = {
  admin: boolean;
  subscriptionAssigned: boolean;
  hasUnlimitedContacts: boolean;
  bulkContact: boolean;
  contactsRemaining: number;
  hasUnassignedSubscription: boolean;
  hasUnassignedSeat: boolean;
  assignedSubscriptions: AssignedSubscription[];
  hasPack: boolean;
  hasSubscriptionWritePermissions: boolean;
  hasInventoryWritePermissions: boolean;
  premiumBadge: boolean;
  phoneContact: boolean;
  lastActiveFilter: boolean;
};

export type AssignedSubscription = {
  status: string;
  market: string;
  type: string;
  expirationDate: string;
};

export type SwitchAdvertiserInfo = {
  advertiserName: string;
  advertiserKey: string;
  advertiserOwnerEmail: string;
  subscriptionMarket: string;
  accountHasMoreThanOneSubscription: boolean;
};

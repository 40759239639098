import { createContext, Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CandidateOutreachModalContextProps, CandidateOutreachModalContextProviderProps } from './types';
import { useStyles } from './styles';
import {
  CandidateOutreachConfirmMessageModal,
  CandidateOutreachConnectYourPhoneModal,
  DISMISS_CONNECT_YOUR_PHONE_MODAL,
  Modal,
} from 'components/ui/molecules';
import {
  CandidateOutreachProps,
  default as CandidateOutreach,
  CandidateOutreachWerknl,
} from 'components/ui/organisms/CandidateOutreach';
import CandidateOutreachCall from 'components/ui/organisms/CandidateOutreachCall';
import { CANDIDATE_UNLOCK_REASON } from 'types/candidate';
import { useContainer } from './useContainer';
import { useLocalStorage } from 'hooks/shared';
import { AccountStatus } from '../CandidateContext/CandidateEmailSettingsContext.types';
import { ConnectEmailModal } from 'pages/EmailSettingsPage/components/ConnectEmailModal';

const initialState: CandidateOutreachModalContextProps = {
  consumerId: null,
  setConsumerId: () => {
    throw 'Provide the CandidateOutreachModalContextProvider';
  },
  outreachModalConfig: null,
  setOutreachModalConfig: () => {
    throw 'Provide the CandidateOutreachModalContextProvider';
  },
  setOutreachModalConfigLabels: () => {
    throw 'Provide the CandidateOutreachModalContextProvider';
  },
  outreachModalOverlayRef: undefined,
};

export const CandidateOutreachModalContext = createContext<CandidateOutreachModalContextProps>(initialState);

export const CandidateOutreachModalContextProvider = memo(
  ({ children }: CandidateOutreachModalContextProviderProps) => {
    const isModalVisibleRef = useRef(false);
    const [outreachModalOverlayRef, setOutreachModalOverlayRef] = useState<HTMLDivElement>();
    const [consumerId, setConsumerId] = useState<string | null>(null);
    const [connectYourPhoneModalVisible, setConnectYourPhoneModalVisible] = useState(false);
    const [reconnectEmailAccountModalVisible, setReconnectEmailAccountModalVisible] = useState(false);
    const [confirmMessageModalVisible, setConfirmMessageModalVisible] = useState(false);
    const [confirmMessageModalCallback, setConfirmMessageModalCallback] = useState<(() => Promise<any> | void) | null>(
      null,
    );
    const {
      states: { outreachModalConfig },
      functions: { setOutreachModalConfig, handleOpenWhatsAppWeb },
    } = useContainer();
    const { value: shouldDismissConnectYourPhoneModal } = useLocalStorage(DISMISS_CONNECT_YOUR_PHONE_MODAL);

    useEffect(() => {
      const shouldDismiss = shouldDismissConnectYourPhoneModal === true;

      if (outreachModalConfig?.type === 'whatsapp' && !shouldDismiss) {
        setConnectYourPhoneModalVisible(true);
      }
    }, [outreachModalConfig?.type]);

    useEffect(() => {
      if (outreachModalConfig) {
        isModalVisibleRef.current = true;

        return () => {
          isModalVisibleRef.current = false;
        };
      }
    }, [!!outreachModalConfig]);

    const setOutreachModalConfigLabels = useCallback((labels: string[]) => {
      setOutreachModalConfig((previousConfig) => {
        if (!previousConfig) return previousConfig;

        return { ...previousConfig, labels };
      });
    }, []);

    const handleCloseModal = () => setOutreachModalConfig(null);

    const handleSend: CandidateOutreachProps['onSend'] = async (data) => {
      // eslint-disable-next-line no-useless-catch
      try {
        if (outreachModalConfig?.type === 'whatsapp') {
          const callback = async () => {
            await outreachModalConfig.onSend(data);
            handleCloseModal();
          };

          await outreachModalConfig.onUnlock({ reason: CANDIDATE_UNLOCK_REASON.CONTACT_INFO });
          handleOpenWhatsAppWeb(data.content);

          setConfirmMessageModalVisible(true);
          setConfirmMessageModalCallback(() => callback);
          return;
        }

        if (outreachModalConfig?.onSend) {
          await outreachModalConfig.onSend(data);
        }

        handleCloseModal();
      } catch (e) {
        throw e;
      }
    };

    const handleCancelConnectYourPhoneModal = () => {
      setConnectYourPhoneModalVisible(false);
      setOutreachModalConfig(null);
    };

    const handleConfirmConnectYourPhoneModal = () => setConnectYourPhoneModalVisible(false);

    const handleCancelConfirmMessageModal = () => setConfirmMessageModalVisible(false);

    const handleConfirmConfirmMessageModal = async () => {
      if (confirmMessageModalCallback) {
        await confirmMessageModalCallback();
        setConfirmMessageModalVisible(false);
        setConfirmMessageModalCallback(null);
      }
    };

    const handleSaveLabels = useCallback(
      async (labels: string[]) => {
        const waitBeforeRemoveFromList = new Promise<void>((res) => {
          const interval = setInterval(() => {
            if (!isModalVisibleRef.current) {
              clearInterval(interval);
              res();
            }
          }, 500);
        });

        outreachModalConfig?.onSaveLabels(labels, { waitBeforeRemoveFromList });
      },
      [!!outreachModalConfig?.onSaveLabels],
    );

    const classes = useStyles();
    const valueMemoized = useMemo(() => {
      return {
        consumerId,
        setConsumerId,
        outreachModalConfig,
        setOutreachModalConfig,
        setOutreachModalConfigLabels,
        outreachModalOverlayRef,
      };
    }, [outreachModalConfig, outreachModalOverlayRef]);

    const CandidateOutreachComponent = useMemo(() => {
      if (outreachModalConfig?.type === 'werknl') return CandidateOutreachWerknl;

      return CandidateOutreach;
    }, [outreachModalConfig?.type]);

    return (
      <Fragment>
        <CandidateOutreachModalContext.Provider value={valueMemoized}>
          {children}
        </CandidateOutreachModalContext.Provider>

        <Modal
          css={classes.contentConfirmModal}
          overlayCss={classes.overlayConfirmModal}
          onRequestClose={handleCancelConnectYourPhoneModal}
          visible={connectYourPhoneModalVisible}>
          <CandidateOutreachConnectYourPhoneModal
            onCancel={handleCancelConnectYourPhoneModal}
            onConfirm={handleConfirmConnectYourPhoneModal}
          />
        </Modal>

        <Modal
          css={classes.contentConfirmModal}
          overlayCss={classes.overlayConfirmModal}
          visible={confirmMessageModalVisible}
          hideCloseButton>
          <CandidateOutreachConfirmMessageModal
            onCancel={handleCancelConfirmMessageModal}
            onConfirm={handleConfirmConfirmMessageModal}
          />
        </Modal>

        <Modal visible={reconnectEmailAccountModalVisible} css={classes.connectEmailAccountModal} hideCloseButton>
          <ConnectEmailModal emailStatus={AccountStatus.Invalid} setVisibility={setReconnectEmailAccountModalVisible} />
        </Modal>

        <Modal
          overlayCss={classes.overlayModal}
          css={classes.modal}
          onRequestClose={handleCloseModal}
          visible={!reconnectEmailAccountModalVisible && !!outreachModalConfig}
          sideModal
          overlayRef={setOutreachModalOverlayRef}>
          {outreachModalConfig &&
            (outreachModalConfig.type === 'call' ? (
              <CandidateOutreachCall
                candidateName={outreachModalConfig.candidateName}
                campaignId={outreachModalConfig.campaignId}
                esPersonId={outreachModalConfig.esPersonId}
                headerDescription={outreachModalConfig.headerDescription}
                openAnotherOutreachMethod={(method) =>
                  setOutreachModalConfig((config) => (config ? { ...config, type: method } : null))
                }
                onContactSuccess={outreachModalConfig.onSendCall}
                labels={outreachModalConfig.labels}
                onSaveLabels={handleSaveLabels}
                notes={outreachModalConfig.notes}
                onSaveNotes={outreachModalConfig.onSaveNotes}
                shouldEnableEmail={outreachModalConfig.outreachMethods.shouldEnableEmail}
                shouldEnableIndeed={outreachModalConfig.outreachMethods.shouldEnableIndeed}
                shouldEnableLinkedin={outreachModalConfig.outreachMethods.shouldEnableLinkedin}
                shouldEnableWhatsapp={outreachModalConfig.outreachMethods.shouldEnableWhatsapp}
                handleCloseModal={handleCloseModal}
                lastContact={outreachModalConfig.lastContact}
                onCallMobileByPushNotification={outreachModalConfig.onCallMobileByPushNotification}
              />
            ) : (
              <CandidateOutreachComponent
                key={outreachModalConfig.type}
                candidateName={outreachModalConfig.candidateName}
                headerDescription={outreachModalConfig.headerDescription}
                candidateActivityLog={outreachModalConfig.candidateActivityLog}
                onSend={handleSend}
                type={outreachModalConfig.type}
                tokens={outreachModalConfig.tokens}
                lastContact={outreachModalConfig.lastContact}
                onCloseModal={handleCloseModal}
                setShouldOpenReconnectEmailAccountModal={setReconnectEmailAccountModalVisible}
              />
            ))}
        </Modal>
      </Fragment>
    );
  },
);

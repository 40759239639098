import { useQuery } from '@tanstack/react-query';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { Candidate } from 'model';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { CampaignTab } from './SelectedCampaignContext.types';

export const useCandidateMatches = ({ candidates, tab }: { candidates: Candidate[]; tab: CampaignTab }) => {
  const { data, isFetching } = useQuery(
    [QueryKey.selectedCampaignContextUseCandidateMatches, { tab }],
    async () => {
      const ids = candidates.map((c) => ({ key: c.key, id: c.es_person_id, contact_info: c.originalContactInfo }));
      const response = await searchClient.getMatchCandidates(ids);

      return response.data as Record<string, string[] | undefined>;
    },
    { enabled: candidates.length > 0, keepPreviousData: true, cacheTime: 0 },
  );

  return useMemo(() => ({ matches: data || {}, isLoading: isFetching }), [data, isFetching]);
};

import { CandidateSelectOutreachTemplateProps } from './types';
import { useStyles } from './styles';
import { Column, Padding, Row, Typography } from 'components/ui/atoms';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Plus, Save, X } from 'components/ui/atoms/icons';
import { useOutreachTemplateByTypeQuery } from 'hooks/autocomplete';
import { useDebounce } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { default as SingleSelect, SingleSelectOption } from 'components/ui/molecules/SingleSelect';
import { default as Button } from 'components/ui/molecules/Button';
import { default as FieldLabelWrapper } from 'components/ui/molecules/FieldLabelWrapper';
import { default as TextInput } from 'components/ui/molecules/TextInput';
import { isAxiosError } from 'helpers/clientHelpers';
import { logger } from 'config/logger';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';

const CandidateSelectOutreachTemplate = ({
  type,
  onSave,
  onSelect,
  contained = false,
  selectedTemplateHasChanges = false,
}: CandidateSelectOutreachTemplateProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const { createSnackbar } = useContext(SnackbarContext);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState<SingleSelectOption | undefined>(undefined);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [isTemplateSaveLoading, setIsTemplateSaveLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const isNewOrEditedTemplate = isNewTemplate || selectedTemplateHasChanges;

  const { data } = useOutreachTemplateByTypeQuery({ type });

  useDebounce(() => setIsFocused(isFieldFocused || isSelectFocused), 16, [isFieldFocused, isSelectFocused]);

  useEffect(() => {
    if (!isFocused && !isNewTemplate) {
      setSearch(selectedOption ? selectedOption.label : '');
    }
  }, [isFocused, isNewTemplate]);

  const handleSelectChange = (opt: SingleSelectOption) => {
    const { key, label } = opt;

    const selectedOpt = data?.find(({ id }) => opt.key === `${id}`);

    if (key === 'blank-option') {
      setSearch('');
      setSelectedOption(undefined);
      onSelect({ id: -1, message: '', name: '', sign_off: '', subject: '' });
      return;
    }

    if (selectedOpt) {
      setSearch(label);
      setSelectedOption(opt);
      onSelect(selectedOpt);
    } else {
      createSnackbar('The selected option does not exist', { variant: 'danger' });
    }
  };

  const handleSaveTemplate = async () => {
    setIsTemplateSaveLoading(true);

    try {
      const { id, name } = await onSave({ templateName: search, createNew: isNewTemplate });
      setSelectedOption({ key: `${id}`, label: name });
      queryClient.invalidateQueries([`useOutreachTemplateByTypeQuery-${type}`]);
    } catch (e) {
      logger.error(e);
      createSnackbar(isAxiosError(e) ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });
    }

    setIsTemplateSaveLoading(false);
    setIsNewTemplate(false);
  };

  const handleStartTemplateCreation = () => {
    setIsNewTemplate(true);
    setSearch('');
  };

  const handleCancelTemplateCreation = () => {
    setIsNewTemplate(false);
    setSearch(selectedOption ? selectedOption.label : '');
  };

  const handleTextChange: React.FormEventHandler = (e) => {
    const target = e.target as HTMLInputElement;

    setSearch(target.value);
  };

  const filteredOptions = useMemo<SingleSelectOption[]>(() => {
    const formattedData = (data?.map(({ id, name }) => ({ key: `${id}`, label: name })) ?? []).filter(({ label }) =>
      label.toLowerCase().includes(search.toLowerCase()),
    );

    return [{ key: 'blank-option', label: dictionary.blankTemplate }, ...formattedData];
  }, [dictionary, search, data]);

  return (
    <Column
      css={classes.root}
      gap={0}
      inline
      onFocus={() => setIsFieldFocused(true)}
      onBlur={() => setIsFieldFocused(false)}
      contained={contained}>
      <FieldLabelWrapper label={dictionary.selectATemplateToUse(type === 'linkedin_inmail' ? 'linkedin' : type)}>
        <Row gap={8}>
          {isNewTemplate ? (
            <TextInput placeholder={dictionary.saveTemplate} text={search} onChange={setSearch} contained={contained} />
          ) : (
            <SingleSelect
              onFocus={() => setIsSelectFocused(true)}
              onBlur={() => setIsSelectFocused(false)}
              placeholder={dictionary.chooseAnOption}
              onChange={handleSelectChange}
              options={filteredOptions}
              text={search}
              onTextChange={handleTextChange}
              noOptionsDefaultRender={
                <Padding padding={[6, 16]}>
                  <Typography variant="body.short">{dictionary.noDataAvailable}</Typography>
                </Padding>
              }
              contained={contained}
              autocomplete
            />
          )}

          <Button
            loading={isTemplateSaveLoading}
            icon={isNewOrEditedTemplate ? <Save /> : <Plus />}
            tooltipLabel={isNewOrEditedTemplate ? dictionary.saveTemplate : dictionary.createNewTemplate}
            onClick={() => (isNewOrEditedTemplate ? handleSaveTemplate() : handleStartTemplateCreation())}
            disabled={isNewOrEditedTemplate && search.length === 0}
          />
          {isNewTemplate && (
            <Button
              icon={<X />}
              variant="subtle"
              tooltipLabel={dictionary.cancel}
              onClick={handleCancelTemplateCreation}
            />
          )}
        </Row>
      </FieldLabelWrapper>
    </Column>
  );
};

export default CandidateSelectOutreachTemplate;

import { useState } from 'react';
import { formatPhoneNumberFromCandidate } from 'styles/helpers';
import { CandidateOutreachModalContextConfig } from './types';

export const useContainer = () => {
  const [outreachModalConfig, setOutreachModalConfig] = useState<CandidateOutreachModalContextConfig | null>(null);

  const handleOpenWhatsAppWeb = (data = '') => {
    if (outreachModalConfig?.notObfuscatedPhone) {
      const formattedPhoneNumber = formatPhoneNumberFromCandidate(outreachModalConfig.notObfuscatedPhone);

      const url = `https://wa.me/${formattedPhoneNumber}?text=${encodeURIComponent(data)}`;

      window.open(url, '_blank')?.focus();
    }
  };

  return {
    states: {
      outreachModalConfig,
    },
    functions: {
      handleOpenWhatsAppWeb,
      setOutreachModalConfig,
    },
  };
};

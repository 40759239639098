import { AxiosError } from 'axios';
import { Tenant } from 'clients/Admin';
import { BaseCallback, EmptyResponseCallback, PaginatedFilters, PaginatedResponse } from '../types';

/////////////////////////////
/// Generic types
/////////////////////////////

export type Error = {
  detail: string;
};

export type BaseCallbackError = AxiosError<Error>;

/////////////////////////////
/// Object types
/////////////////////////////

export enum UserRoles {
  Recruiter = 'RECRUITER',
  Admin = 'ADMIN',
}

export enum WerknlLayout {
  New = 'NEW',
  Legacy = 'LEGACY',
}

export type UserResponse = {
  id: number;
  is_active: boolean;
  email: string;
  tenant_id: number;
  is_email_confirmed: boolean;
  should_change_password: boolean;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  is_staff?: boolean;
  tenant_code?: string;
  created_at?: string;
  last_login?: string;
  has_tutorial?: boolean;
  role?: UserRoles;
  ats_credential_id: number | null;
  team_id: number | null;
  tenant: Pick<Tenant, 'id' | 'code' | 'name' | 'payment_method' | 'privacy_policy_link'>;
  expo_push_token?: string | null;
  werknl_layout?: WerknlLayout;
};

export type CreateUserDto = Partial<Pick<UserResponse, 'phone_number' | 'ats_credential_id' | 'team_id'>> &
  Pick<
    UserResponse,
    | 'first_name'
    | 'last_name'
    | 'should_change_password'
    | 'email'
    | 'is_active'
    | 'tenant_id'
    | 'tenant_code'
    | 'has_tutorial'
    | 'role'
    | 'werknl_layout'
  > & {
    password?: string;
    send_activation_email: boolean;
  };

export type UpdateUserDto = Pick<UserResponse, 'id'> & Omit<Partial<CreateUserDto>, 'send_activation_email'>;

export type UpdateCurrentUserDto = Omit<UpdateUserDto, 'id'>;

export type UserCredit = {
  total: number;
  used: number;
  left: number;
  percentage_used: number;
  display_text: string;
};

export type ResetPasswordParams = {
  password: string;
  reset_code: string;
  user_id: number;
  tenant_id: number | null;
};

export type SendPasswordResetCodeParams = {
  email: string;
};

export type ValidatePasswordResetCodeParams = {
  user_id: number;
  reset_code: string;
  tenant_id: number;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type LoginTenantParams = {
  login_secret: string;
};

export type AuthenticationTokenType = 'Bearer';

export type Authentication = {
  token_type: AuthenticationTokenType;
  access_token: string;
  expires_in: number;
  user_id: number;
  tenant_id: number;
  should_change_password: boolean;
  password_reset_code?: string;
};

export type ChangeCurrentUserPasswordParams = {
  password: string;
  new_password: string;
};

export type GetFeatureTogglesParams = {
  tenant_id?: number;
};

export type FeatureToggle = {
  id?: number | null;
  name: string;
  enabled: boolean;
  value: string;
  tenant_id?: Tenant['id'];
  created_at?: string;
  updated_at?: string;
};

/////////////////////////////
/// Handlers
/////////////////////////////

export type IndexUserHandler = (
  params: PaginatedFilters<UserResponse>,
) => BaseCallback<UserResponse[] | PaginatedResponse<UserResponse>>;

export type CreateUserHandler = (params: CreateUserDto) => BaseCallback<UserResponse>;

export type GetUserHandler = (id: number) => BaseCallback<UserResponse>;

export type GetCurrentUserHandler = () => BaseCallback<UserResponse>;

export type GetTenantCurrentUserHandler = () => BaseCallback<Tenant>;

export type UpdateUserHandler = (params: UpdateUserDto) => BaseCallback<UserResponse>;

export type UpdateUserWithTokenHandler = (params: UpdateUserDto, token: string) => BaseCallback<UserResponse>;

export type DeleteUserHandler = (id: number) => EmptyResponseCallback;

export type GetCreditsHandler = () => BaseCallback<UserCredit>;

export type UpdateCurrentUserHandler = (params: UpdateCurrentUserDto) => BaseCallback<UserResponse>;

export type ResetPasswordHandler = (params: ResetPasswordParams) => EmptyResponseCallback;

export type SendPasswordResetCodeHandler = (params: SendPasswordResetCodeParams) => EmptyResponseCallback;

export type ValidatePasswordResetCodeHandler = (params: ValidatePasswordResetCodeParams) => EmptyResponseCallback;

export type LoginHandler = (props: LoginParams) => BaseCallback<Authentication>;

export type LoginTenantHandler = (props: LoginTenantParams) => BaseCallback<Authentication>;

export type ChangeCurrentUserPasswordHandler = (params: ChangeCurrentUserPasswordParams) => EmptyResponseCallback;

export type GetFeatureTogglesHandler = (params?: GetFeatureTogglesParams) => BaseCallback<FeatureToggle[]>;

export type CreateFeatureTogglesHandler = (
  params: Partial<FeatureToggle> & Required<Pick<FeatureToggle, 'enabled' | 'tenant_id'>>,
) => BaseCallback<FeatureToggle>;

export type UpdateFeatureTogglesHandler = (
  params: Partial<FeatureToggle> & Required<Pick<FeatureToggle, 'id' | 'enabled'>>,
) => BaseCallback<FeatureToggle>;

export type DeleteFeatureTogglesHandler = (id: number) => EmptyResponseCallback;

import { Column, Expand, Row, Typography } from 'components/ui/atoms';
import { ArrowRight } from 'components/ui/atoms/icons';
import { Button } from 'components/ui/molecules';
import { useLocalStorage } from 'hooks/shared';
import { ChangeEvent, useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './styles';
import { CandidateOutreachConnectYourPhoneModalProps } from './types';
import { Checkbox } from 'shared/components/CheckboxGroup';

export const DISMISS_CONNECT_YOUR_PHONE_MODAL = 'dismiss-connect-your-phone-modal';

const CandidateOutreachConnectYourPhoneModal = ({
  onCancel,
  onConfirm,
}: CandidateOutreachConnectYourPhoneModalProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();
  const { setValue: setDismiss } = useLocalStorage(DISMISS_CONNECT_YOUR_PHONE_MODAL);

  const handleToogleDismissModal = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setDismiss(checked);
  };

  return (
    <Column gap={32} css={classes.root}>
      <Column gap={8}>
        <Typography variant="header.h2" color={(c) => c.monochrome[100]}>
          {dictionary.connectYourPhoneTitleModal}
        </Typography>
        <Typography variant="body.long">{dictionary.connectYourPhoneDescriptionModal}</Typography>
        <Row onClick={() => window.open('https://www.whatsapp.com/download')?.focus()} inline alignSelfStart>
          <Typography variant={(v) => v.supporting.label} colorv2={(color) => color.primary.fuchsia[60]} pointer>
            {dictionary.downloadTheApp}
            <Expand width={6} />
            <ArrowRight size={16} />
          </Typography>
        </Row>
      </Column>

      <Row spaceBetween>
        <Checkbox label={dictionary.dontShowThisAgain} onChange={handleToogleDismissModal} />

        <Row gap={9}>
          <Button label={dictionary.back} variant="subtle" onClick={onCancel} />
          <Button label={dictionary.imConnected} variant="highlight" onClick={onConfirm} />
        </Row>
      </Row>
    </Column>
  );
};

export default CandidateOutreachConnectYourPhoneModal;

import { useContext, useEffect, useId, useMemo, useState } from 'react';
import {
  CandidateOutreachModalConsumerContextProps,
  CandidateOutreachModalConsumerContextProviderProps,
} from './types';
import { NotifyCandidateMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateOutreachModalContext } from 'shared/contexts/CandidateOutreachModalContext/CandidateOutreachModalContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { isAxiosError } from 'helpers/clientHelpers';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { createContext, useContextSelector } from 'use-context-selector';
import { CANDIDATE_UNLOCK_REASON } from 'types/candidate';
import { logger } from 'config/logger';

const initialState: CandidateOutreachModalConsumerContextProps = {
  activeOutreachModal: null,
  setActiveOutreachModal: () => {
    throw 'Provide the CandidateOutreachModalConsumerContextProvider';
  },
};

export const CandidateOutreachModalConsumerContext =
  createContext<CandidateOutreachModalConsumerContextProps>(initialState);

export const CandidateOutreachModalConsumerContextProvider = ({
  candidateContext,
  children,
}: CandidateOutreachModalConsumerContextProviderProps) => {
  const currentConsumerId = useId();
  const notes = useContextSelector(candidateContext, (state) => state.notes);
  const labels = useContextSelector(candidateContext, (state) => state.labels);
  const activityHistory = useContextSelector(candidateContext, (state) => state.searchv3.candidate.history);
  const onUnlock = useContextSelector(candidateContext, (state) => state.onUnlock);
  const onContact = useContextSelector(candidateContext, (state) => state.onContact);
  const onSaveNotes = useContextSelector(candidateContext, (state) => state.onSaveNotes);
  const onSaveLabels = useContextSelector(candidateContext, (state) => state.onSaveLabels);
  const candidateName = useContextSelector(candidateContext, (state) => state.candidateName);
  const outreachMethods = useContextSelector(candidateContext, (state) => state.outreachMethods);
  const notObfuscatedPhone = useContextSelector(candidateContext, (state) => state.notObfuscatedPhone);
  const notObfuscatedEmail = useContextSelector(candidateContext, (state) => state.notObfuscatedEmail);
  const personalizationTokens = useContextSelector(candidateContext, (state) => state.personalizationTokens);
  const lastContact = useContextSelector(candidateContext, (state) => state.lastContact);
  const campaignId = useContextSelector(candidateContext, (state) => state.campaignId as number);
  const esPersonId = useContextSelector(candidateContext, (state) => state.candidateEsPersonId);
  const onCallMobileByPushNotification = useContextSelector(
    candidateContext,
    (state) => state.onCallMobileByPushNotification,
  );

  const { consumerId, setConsumerId, outreachModalConfig, setOutreachModalConfig } =
    useContext(CandidateOutreachModalContext);
  const { createSnackbar, closeSnackbar } = useContext(SnackbarContext);
  const { dictionary } = useContext(LocalizationContext);
  const [activeOutreachModal, setActiveOutreachModal] = useState<NotifyCandidateMethod | null>(null);

  useEffect(() => {
    if (currentConsumerId === consumerId && outreachModalConfig?.type !== activeOutreachModal) {
      setActiveOutreachModal(outreachModalConfig?.type || null);
    }
  }, [currentConsumerId, consumerId, outreachModalConfig?.type]);

  useEffect(() => {
    if (activeOutreachModal) {
      const unlockAndOpenModal = async () => {
        const snackbarLoadingId = createSnackbar(dictionary.workingOnIt, { variant: 'loading', persist: true });

        try {
          setConsumerId(currentConsumerId);
          const shouldUnlock = await onUnlock({ reason: CANDIDATE_UNLOCK_REASON.CONTACT_INFO });
          if (!shouldUnlock) return;

          setOutreachModalConfig({
            onUnlock,
            candidateName,
            campaignId,
            esPersonId,
            headerDescription:
              activeOutreachModal === 'email'
                ? notObfuscatedEmail
                : activeOutreachModal === 'call'
                  ? notObfuscatedPhone
                  : undefined,
            candidateActivityLog: activityHistory,
            notObfuscatedPhone,
            outreachMethods,
            tokens: personalizationTokens,
            labels,
            notes: notes.length > 0 ? notes[0].content : '',
            onSaveLabels,
            onSaveNotes,
            onSend: ({ extras, subject, content, signature, email, phoneNumber, website }) =>
              onContact(
                { subject, body: content, signOff: signature, email, phoneNumber, website },
                activeOutreachModal,
                extras,
              ),
            onSendCall: () => onContact({}, 'call'),
            type: activeOutreachModal,
            lastContact,
            onCallMobileByPushNotification,
          });
        } catch (e) {
          setConsumerId(null);
          setOutreachModalConfig(null);

          logger.error(e);
          createSnackbar(isAxiosError(e) ? e.message : JSON.stringify(e), { variant: 'danger' });
        } finally {
          closeSnackbar(snackbarLoadingId);
        }
      };

      unlockAndOpenModal();
    } else {
      setConsumerId(null);
    }
  }, [activeOutreachModal]);

  const valueMemoized = useMemo(() => {
    return {
      activeOutreachModal,
      setActiveOutreachModal,
    };
  }, [activeOutreachModal]);

  return (
    <CandidateOutreachModalConsumerContext.Provider value={valueMemoized}>
      {children}
    </CandidateOutreachModalConsumerContext.Provider>
  );
};

import { default as TextInput } from 'components/ui/molecules/TextInput';
import { useStyles } from './PhoneInput.styles';
import { PhoneInputProps } from './PhoneInput.types';
import { formatPhoneDutch } from 'shared/components/TextField/formatters';
import { Typography } from '@recruitrobin/robin-theme/web-components';
import { useRef } from 'react';

export const PhoneInput = ({ onChange, text, placeholder, formatPhone = true }: PhoneInputProps) => {
  const textInputRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleChange = (text: string) => {
    if (formatPhone) {
      return onChange(formatPhoneDutch(text));
    }

    onChange(text);
  };

  return (
    <div css={classes.root}>
      <TextInput
        ref={textInputRef}
        onChange={handleChange}
        text={text}
        maxLength={16}
        placeholder={placeholder}
        contained
      />
      <div css={classes.textCounter} onClick={() => textInputRef.current?.focus()}>
        <Typography variant={(v) => v.body.short} color={(c) => c.neutral.variant[60]}>
          {text.length}/16
        </Typography>
      </div>
    </div>
  );
};

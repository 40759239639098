import { FieldLabelWrapperProps } from './types';
import { useStyles } from './styles';
import { generateDtiAttribute } from 'helpers/helpers';
import { Column, Row } from 'components/ui/atoms';
import { forwardRef } from 'react';
import { Typography } from '@recruitrobin/robin-theme/web-components';
import { TooltipIcon } from 'shared/components/Tooltip';

const FieldLabelWrapper = forwardRef(
  (
    { children, label, htmlFor, tooltipLabel, disabled = false, contained = false, className }: FieldLabelWrapperProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const classes = useStyles();

    return (
      <Column ref={ref} css={classes.root} className={className} gap={6} contained={contained} inline>
        <Row gap={9} alignCenter spaceBetween>
          <label htmlFor={htmlFor} {...generateDtiAttribute('label')}>
            <Typography variant={(v) => v.supporting.label} color={(c) => c.neutral.variant[disabled ? 30 : 60]}>
              {label}
            </Typography>
          </label>
          {tooltipLabel && <TooltipIcon title={tooltipLabel} disabled={disabled} />}
        </Row>
        {children}
      </Column>
    );
  },
);

export default FieldLabelWrapper;

export enum QueryKey {
  campaignManagerContextUseCampaigns = 'campaignManagerContextUseCampaigns',
  selectedCampaignContextUseCandidates = 'selectedCampaignContextUseCandidates',
  selectedCampaignContextUseStats = 'selectedCampaignContextUseStats',
  selectedCampaignContextUseFacets = 'selectedCampaignContextUseFacets',
  sharedCandidateContextUseCandidate = 'sharedCandidateContextUseCandidate',
  selectedCampaignContextUseCandidateMatches = 'selectedCampaignContextUseCandidateMatches',
  candidateActivityLog = 'candidateActivityLog',
  frontend2beCandidate = 'frontend2beCandidate',
  connectToAtsUseAts = 'connectToAtsUseAts',
  campaignManagerContextUseCampaign = 'campaignManagerContextUseCampaign',
  selectedCandidateLoadNotes = 'selectedCandidateLoadNotes',
  useTenantDistinctedLabelsQuery = 'useTenantDistinctedLabelsQuery',
  useRequestCv = 'useRequestCv',
  sharedPageMatches = 'sharedPageMatches',
}

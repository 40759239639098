import localforage from 'localforage';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { memoStorage } from './memoStorage';
import { ConfigContext } from 'shared/contexts/ConfigContext';

export const useLocalStorage = <T extends string | number | object | boolean | undefined>(
  key: string,
  defaultValue?: T,
) => {
  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);
  const [value, _setValue] = useState<T>(memoStorage.getValue<T>(key));
  const valueRef = useRef(value);

  useDispatchAllKeysOnChange(key, _setValue);

  useEffect(() => {
    valueRef.current = value;
  }, [key, value]);

  useEffect(() => {
    getLocalStorageValue<T>(key, defaultValue).then((v) => _setValue(v as T));
  }, [key, isIframe]);

  const dispatchAllKeys = (value: T) => {
    rrLocalStorageManager.actions.forEach((action) => {
      if (action.key === key) {
        action.setState(value);
      }
    });
  };

  const setValue = (v: T | ((value: T) => T | undefined)) => {
    const memoValue = valueRef.current || memoStorage.getValue<T>(key);
    const value = typeof v === 'function' ? v(memoValue) || memoValue : v;

    dispatchAllKeys(value);

    memoStorage.setValue(key, value);
    const valueJSON = JSON.stringify(value);

    if (isIframe) {
      localforage.setItem(key, valueJSON).then(() => _setValue(value));
      return;
    }

    localStorage.setItem(key, valueJSON);

    _setValue(value);
  };

  return useMemo(() => {
    return {
      value,
      setValue,
      valueRef,
    };
  }, [key, value, isIframe]);
};

const rrLocalStorageManager = {
  actions: [] as Array<{
    id: string;
    key: string;
    setState: React.Dispatch<React.SetStateAction<any>>;
  }>,
};

if (!window.rrLocalStorageManager) {
  window.rrLocalStorageManager = rrLocalStorageManager;
}

/**
 * This will trigger all setState functions of all useLocalStorage hooks associated with the provided key
 */
const useDispatchAllKeysOnChange = (key: string, setState: React.Dispatch<any>) => {
  const idRef = useRef<string>();

  useEffect(() => {
    const id = (idRef.current = uniqueId());
    const actions = rrLocalStorageManager.actions;

    actions.push({ id, key, setState });

    return () => {
      actions.splice(
        actions.findIndex((a) => a.id === id),
        1,
      );
    };
  }, [key]);
};

const getLocalStorageValue = async <T extends string | number | object | boolean | undefined>(
  key: string,
  defaultValue: T | undefined,
) => {
  const localStorageJsonValue = localStorage.getItem(key) ?? (await localforage.getItem<string>(key)) ?? '""';

  if (localStorageJsonValue === 'undefined') return undefined;

  const localStorageValue = JSON.parse(localStorageJsonValue) as T;

  return localStorageValue === '' && typeof defaultValue !== 'undefined' ? defaultValue : localStorageValue;
};

import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignCandidateSaveNotesResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useLoadNotes = (params: WrappedHookParams) => {
  return useCandidateAction<void, LoadNotesCallbackParams>(
    async ({ candidate }) => {
      const { data: notes } = await campaignsClient.getCampaignCandidateNotes({
        es_person_id: candidate.es_person_id,
      });

      return { notes };
    },
    {
      onSuccess: () => {
        return async ({ candidateOrchestrator: co, pageContext, data: { notes } }) => {
          if (pageContext === PageContext.Campaign) {
            co.campaign.update.notes(notes);
          } else if (pageContext === PageContext.Shared) {
            co.share.update.notes(notes);
          }

          await new Promise((res) => setTimeout(res, 300));
        };
      },
    },
  )(params);
};

type LoadNotesCallbackParams = {
  notes: CampaignCandidateSaveNotesResponse[];
};

import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  content: ({ theme }) =>
    css({
      width: 601,
      height: 289,
      position: 'relative',
      borderRadius: 12,
      padding: '32px 40px 40px 40px',
      backgroundColor: theme.colors.neutral.variant[0],
    }),
  closeButton: () =>
    css({
      position: 'absolute',
      right: 11,
      top: 10,
    }),
  header: () =>
    css({
      gap: 8,
    }),
  buttonStore: ({ theme }) =>
    css({
      width: 234,
      height: 126,
      borderRadius: 3,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.neutral.variant[30],
      backgroundColor: theme.colors.neutral.variant[5],
      cursor: 'pointer',
    }),
  iconStore: () =>
    css({
      display: 'flex',
      width: 48,
      height: 48,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

/* eslint-disable react/no-unescaped-entities */

import { Fragment } from 'react';

import { Dictionary } from '../types';

export const dictionary: Dictionary = {
  certification: 'Certification',
  since: 'sinds',
  notAvailableAnymore: 'Niet langer beschikbaar',
  workingHours: 'Werkuren',
  commuteDistance: 'Reisafstand',
  relocation: 'Relocation',
  salary: 'Salary',
  preferredJobs: 'Voorkeurstaken',
  total: 'Totaal',
  unableToRefresh: 'Kan niet vernieuwen',
  current: 'Huidige',
  past: 'Vorige',
  noDataAvailable: 'Geen informatie beschikbaar',
  cvAvailable: 'CV Beschikbaar',
  hours: 'Uur',

  login: 'Log In',
  rememberMe: 'Onthoud Mij',
  logout: 'Uitloggen',
  registerAccount: 'Maak een account',
  newCompany: 'Nieuw Bedrijf',
  required: 'Verplicht',
  showPassword: 'Laat wachtwoord zien',
  hidePassword: 'Verberg wachtwoord',
  forgotPassword: 'Wachtwoord vergeten?',
  forgotYourPassword: 'Wachtwoord vergeten?',
  forgotPasswordDescription:
    'Er wordt een link voor het opnieuw instellen van uw wachtwoord naar uw e-mailadres verzonden om uw wachtwoord opnieuw in te stellen. Als u binnen een paar minuten geen e-mail ontvangt, probeert u het opnieuw.',
  resetPassword: 'Reset Wachtwoord',
  resetPasswordMailSentDescription:
    'Een link voor het opnieuw instellen van uw wachtwoord verzonden naar uw e-mailadres. Controleer alstublieft uw e-mails.',
  mailSent: 'Mail verstuurd',
  pleaseEnterYourNewPassword: 'Voer uw nieuwe wachtwoord in.',
  preferences: 'Voorkeuren',
  email: 'Email',
  emailNotifications: 'E-mail notificaties',
  sendEmail: 'Verstuur Email',
  username: 'Gebruikersnaam',
  userName: 'Gebruikersnaam',
  password: 'Wachtwoord',
  passwordRepeat: 'Wachtwoord (herhaald)',
  overview: 'Overzicht',
  new: 'Nieuw',
  viewAll: 'Bekijk alles',
  statistics: 'Statistieken',
  comingSoon: 'Komt snel',
  administration: 'Administratie',
  connections: 'Koppellingen',
  availableCredits: (left, total, isPayPerMatch = false) => (
    <Fragment>
      {isPayPerMatch ? 'Matches' : 'Nog'} <b>{left}</b>/{total} {isPayPerMatch ? 'beschikbaar' : 'tegoed'}
    </Fragment>
  ),
  cv: 'CV',
  profile: 'Profiel',
  emailAddress: 'Email adres',
  emailAddressForTeams: 'Team e-mailadres',
  phoneNumber: 'Telefoonnummer',
  nickname: 'Bijnaam',
  companyName: 'Bedrijfsnaam',
  companiesWorkedAt: 'Werkervaring bij bedrijven',
  companiesWorkedAtPlaceholder: 'Bijvoorbeeld Rabobank',
  companySize: 'Bedrijfsgrootte',
  companyType: 'Type bedrijf',
  selectCountry: 'Land',
  selectATS: 'Selecteer jouw ATS',
  whatATSAreYouUsing: 'Welk ATS gebruik je?',
  howDidYouHearAboutUs: 'Hoe heb je over ons gehoord?',
  pleaseExplain: 'Geef toelichting...',
  save: 'Opslaan',
  next: 'Volgende',
  previous: 'Vorige',
  submit: 'Aanmaken',
  back: 'Terug',
  cancel: 'Annuleren',
  browserExtension: 'Browser extensie',
  robinExtension: 'Robin extensie',
  changePassword: 'Wijzig wachtwoord',
  currentPassword: 'Huidig wachtwoord',
  newPassword: 'Nieuw wachtwoord',
  repeatNewPassword: 'Nieuw wachtwoord (herhalen)',
  chooseFile: 'Selecteer bestand',
  noFileChosen: 'Geen bestand geselecteerd',
  roles: 'Rollen',
  users: 'Gebruikers',
  user: 'Gebruiker',
  ats: 'ATS',
  atsCompleteName: 'Applicant tracking system',
  addedToAts: 'toegevoegd tot ATS',
  jobBoards: 'Job Boards',
  useRolesToGroupPermissions: 'Gebruik rollen om machtigingen te groeperen.',
  manageUsersAndPermissions: 'Beheer gebruikers en rechten.',
  recruitmentAgency: 'Wervingsbureau',
  corporate: 'Corporate',
  freelancer: 'Freelancer',
  selectInHouseRecruiters: 'Aantal inhouse recruiters',
  cantBeLessThanZeroRecruiters: 'Je kan niet minder dan 0 recruiters hebben',
  thisFieldIsRequired: 'Dit is een verplicht veld.',
  validationRequiredEmail: 'Vul hier een geldig e-mailadres in.',
  useYourWorkEmailAddress: 'Vul een werk e-mailadres in.',
  validationPasswordConfirm: 'Vul hier dezelfde waarde in.',
  editCriteriaForMoreResults: 'Wijzig criteria voor meer resultaten',
  newResultsInHours: (hours) => <Fragment>Nieuwe resultaten over {hours} uur</Fragment>,
  newResultsOnMonday: 'Nieuwe resultaten op maandag',
  thereAreNewResults: (newResults) => `${newResults} nieuwe`,
  untitledSearch: 'Naam zoekopdracht',
  newSearch: 'Nieuwe zoekopdracht',
  searches: 'Zoekopdrachten',
  candidates: 'Kandidaten',
  teamview: 'Van team',
  teamView: 'Van Team',
  status: 'Status',
  lastAdded: 'Laatst toegevoegd',
  coWorker: 'Collega',
  search: 'Zoeken',
  sortBy: 'Sorteer op',
  sortedBy: 'Gesorteerd op',
  mySearches: 'Mijn zoekopdrachten',
  teamSearches: 'Zoekopdrachten team',
  allSearches: 'Alle zoekopdrachten',
  searchOverview: 'Overzicht zoekopdrachten',
  campaignNotificationByEmail: 'Email notificaties zoekopdrachten',
  campaignNotificationDisabled: 'Uitgeschakeld',
  campaignNotificationOnce: 'Een keer per dag (om 08:00)',
  campaignNotificationTwice: 'Twee keer per dag (om 08:00 en 16:00)',
  campaignNotificationThrice: 'Drie keer per dag (om 08:00, 12:00 en 16:00)',
  sendToNotificationTitle: 'Waar zullen we e-mails over nieuwe/gewijzigde kandidaten naartoe sturen?',
  sendToNotificationPersonal: 'Alleen persoonlijke e-mails',
  sendToNotificationTeam: 'Alleen team e-mails',
  sendToNotificationBoth: 'Zowel team als persoonlijke e-mails',
  askBeforeSpendingMonsterInventoryCredit: 'Toestemming vragen voordat je een Monster credit uitgeeft?',
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // askBeforeSpendingWerkzoekenCredit: 'Toestemming vragen voordat je een Werkzoeken credit uitgeeft?',
  yesAskEveryTime: 'Ja, altijd vragen',
  noNeverAsk: 'Nee, vraag nooit meer',
  adminPanel: 'Admin panel',
  intakePlanned: 'Matched',
  downloadAllApprovedCandidates: 'Download alle goedgekeurde kandidaten',
  downloadAllAMatched: 'Download alle gematchte',
  maxOf300CandidatesWillBeDownloaded: 'Er worden maximaal 300 kandidaten gedownload',
  searchForAts: 'Zoek een ATS-vacature',

  yes: 'Ja',
  no: 'Nee',
  areYouSure: 'Weet je het zeker?',
  confirmDeleteCampaign: 'Weet je zeker dat je deze campagne wilt verwijderen?',
  by: 'door',
  you: 'jou',
  here: 'hier',

  match: 'Match',
  shortlistCandidateToOtherSearch: 'Shortlist voor andere zoekopdracht',
  shortlistCandidateNameToOtherSearch: (name) => `Shortlist kandidaat ${name} voor andere zoekopdracht`,
  shortlistCandidateToOtherSearchDescription:
    'Shortlist kandidaat voor andere zoekopdracht & laat in de resultaten van huidige zoekopdracht',

  matched: 'Matched',
  hidden: 'Verborgen',
  shortlist: 'Shortlist',
  shortlisted: 'Shortlisted',
  unsaved: 'Unsaved',
  saved: 'Saved',
  connectToAts: 'Koppel met ATS',
  connectToAtsVacancy: 'Koppel vacature uit het ATS',
  unlocked: 'Ontgrendeld',
  downloadProfiles: 'Download Profielen',
  history: 'Kandidaat geschiedenis',
  activity: 'Activiteit',
  profileHistoryPlaceholder:
    'Geschiedenis informatie is hier zichtbaar als je actie onderneemt voor deze kandidaat zoals goedkeuren of afkeuren',
  details: 'Details',
  educationLevel: 'Opleidings Niveau (hoog/laag)',
  employer: 'Werkgever (a/z)',
  travelDistance: 'Reisafstand',
  availability: 'Beschikbaarheid',
  contactInfo: 'Te contacten via',
  Monsterboard: 'Monsterboard',
  Nationalevacaturebank: 'Nationale Vacature Bank',
  Werknl: 'Werk.nl',
  version: 'Versie',
  seeResults: 'Bekijk resultaten',
  notAbleDeleteSearchAts: 'Je kan geen aan je ATS gekoppelde zoekopdracht verwijderen',

  iAlreadyKnow: 'Ken ik al',
  overqualified: 'Te Zwaar',
  underqualified: 'Te Licht',
  wrongIndustry: 'Verkeerde Industrie',
  wrongEducation: 'Verkeerde Opleiding',
  wrongExperience: 'Verkeerde Ervaring',
  tooFarAway: 'Woont te ver weg',
  otherReasonNamely: 'Anders, namelijk...',
  other: 'Anders',
  hide: 'Verberg',
  check: 'Bekijken',

  contact: 'Contact',
  contacted: 'Gecontacteerd',
  positiveResponse: 'Positieve reactie',
  negativeResponse: 'Negatieve reactie',
  noResponse: 'Geen antwoord',
  searching: 'Zoeken',
  sentToAts: 'Verzonden naar ats',
  interviewScheduled: 'Interview gepland',
  interviewPlaneed: 'Interview gepland',
  interviewCompleted: 'Interview afgerond',
  interviewCancelled: 'Interview afgezegd',
  leftVoicemail: 'Voicemail ingesproken',
  openToWorkNotInterested: 'Beschikbaar, niet geïnteresseerd',
  notOpenToWork: 'Niet beschikbaar',
  noResponsiveToReachOut: 'Reageert niet',

  experience: 'Ervaring',
  education: 'Opleiding',
  courses: 'Cursussen',
  certifications: 'Certificeringen',
  skill: 'Vaardigheden',
  skills: 'Vaardigheden',
  language: 'Taal',
  languages: 'Talen',
  industry: 'Industrie',
  company: 'Bedrijf',
  date: 'Datum',

  whyRejected: 'Waarom Afgekeurd?',
  whatIsTheStatus: 'Wat is de Status?',
  updateStatus: 'Status bijwerken',

  explanation: 'Toelichting',
  nameSearch: 'Naam voor de zoekopdracht',
  vacancy: 'Vacature',
  vacancyPlaceholder: 'Bijv. https://careers.recruitrobin.com/account-manager',
  jobTitle: 'Functietitel',
  jobTitles: 'Functietitels',
  addSuggestions: (number: number) => `Voeg ${number} suggesties toe`,
  jobTitlePlaceholder: 'Bijvoorbeeld Monteur',
  jobTitleWarning: 'Met slechts één functie zal waarschijnlijk genereren zeer weinig resultaten.',
  workExperience: 'Werkervaring in jaren',
  minWorkExperienceInYears: 'Min. Werkervaring in jaren',
  maxWorkExperienceInYears: 'Max. Werkervaring in jaren',
  minWorkExperienceInFunction: 'Min. Werkervaring in functie',
  screeningCriteria: 'Screening criteria',
  screeningCriteriaPlaceholder: '',
  country: 'Land',
  postalCode: 'Postcode',
  rangeInKm: 'Afstand in KM',
  startSearch: 'Start Zoekopdracht',
  editSearch: 'Bewerk Zoekopdracht',
  eg: 'Bijv.',

  veryWell: 'Erg goed!',
  iWillStartLookingForCandidates: (campaignName) => (
    <Fragment>
      Ik ga op zoek naar kandidaten voor je jobaanbieding <span>{campaignName}</span>
    </Fragment>
  ),
  whatToExpect: 'Wat te verwachten',
  whatToExpect1: 'Binnen 4 tot 24 uur een overzicht van potentiële kandidaten',
  whatToExpect2: 'Ik zal je tijdens de komende fases via e-mail op de hoogte houden.',
  editThisCampaign: 'Bewerk deze campagne',
  viewCampaigns: 'Bekijk campagnes',
  searchForCampaign: 'Zoek naar Campagne',
  ratioOfApprovedCandidates: (approved: number, total: number) => (
    <Fragment>
      <strong>{approved}</strong> van <strong>{total}</strong> matched
    </Fragment>
  ),
  atsExplanation: 'Kies het ATS waarmee je wil koppelen',

  more: 'Meer',
  move: 'Versplatsen',
  moveTo: 'Versplatsen Nar',

  contactInfoSetting: 'Toon alleen profielen met contactinformatie',
  activeJobSeekersSetting: 'Toon alleen profielen van kandidaten die actief op zoek zijn',

  //Job Boards
  newJobBoard: 'Nieuw Job board',
  editJobBoard: 'Bewerk Job Board',
  invalidCredentials: 'Ongeldige Inloggegevens',
  jobBoardCredentialVerificationTitle: 'We verifiëren nu jouw login gegevens.',
  jobBoardCredentialVerificationDescription: 'Even geduld nog. Het kan 1 minuut duren.',
  jobBoardInvalidCredentials:
    'Balen! We hebben jouw login gegevens niet kunnen verifiëren. Controleer je gebruikersnaam en wachtwoord, en probeer het daarna nogmaals.',
  jobBoardDuplicatedCredentials: 'We hebben deze login gegevens al! Je hoeft dus niks meer te doen.',
  jobBoardConnectionActive: 'De connectie is actief.',
  jobBoardConnectionInActive: 'De connectie is inactief.',
  confirmDeleteJobBoard: 'Weet je zeker dat je dit job board wilt verwijderen?',
  confirmDisableJobBoard: 'Weet je zeker dat je dit job board voor alle gebruikers wilt uitschakelen?',
  jobBoardExplanation: 'Kies het job board dat je wilt koppelen',
  jobBoardUsernameExplanation: 'Voer de gebruikersnaam in waarmee je ook inlogt op het job board.',
  jobBoardPasswordExplanation: 'Voer het wachtwoord in waarmee je ook inlogt op het job board.',
  jobBoardSecret: 'Geheime vraag',
  jobBoardSecretExplanation: 'Voer ter controle de geheime vraag in die je op het job board hebt ingevoerd.',
  licenseActive: 'Jouw login gegevens zijn correct.',
  licenseInActive: 'Jouw login gegevens zijn niet correct.',
  license: 'Licentie',
  addLicense: 'Voeg Licentie Toe',

  //ATS
  addNew: 'Nieuw Toevoegen',
  atsVacancy: 'ATS Vacature',
  viewATSConnections: 'Wijzig ATS connectie',
  unableToConnectToATS:
    'Het is niet mogelijk om je ATS te koppelen. Controleer je gegevens of probeer het later opnieuw.',
  myConnections: 'Mijn Koppelingen',
  teamConnections: 'Koppelingen Team',
  usersWithoutConnections: 'Niet Gekoppelde Gebruikers',
  atsKey: 'ATS Key',
  atsUrl: 'ATS URL',
  atsName: 'Naam ATS',
  clientSecret: 'Geheime vraag',
  requestATSConnection:
    'Dit ATS staat nog niet in ons systeem. Vraag een koppeling aan, zodat we het ATS kunnen toevoegen.',
  atsRequestedMessage: (
    <Fragment>
      De koppeling is aangevraagd. We gaan ermee aan de slag! Intussen kun je gewoon verdermet het anmaken van
      zoekopdrachten en het beoordelen van kandidaten.
      <br />
      <br />
      Zodra we weten of de koppeling aan je ATS mogelijk is, hoor je van ons.
    </Fragment>
  ),
  checkOverview: 'Bekijk overzicht',
  confirmDeleteATS:
    'Het verwijderen van je koppeling zal ervoor zorgen dat kandidaten niet meer automatisch naar je ATS gepusht worden.',
  confirmSwitchATS: (ats) => `Je bent al gekoppeld met ATS ${ats}. Weet je zeker dat je wilt wisselen van ATS?`,

  atsUsernameExplanation: 'Vul de gebruikersnaam in die je bij het ATS gebruikt om in te loggen',
  atsPasswordExplanation: 'Vul het wachtwoord in die je bij het ATS gebruikt om in te loggen',
  atsKeyExplanation: "Vul de 'ATS Key' in die je in de instellingen van het ATS vindt",
  atsSecret: 'Client secret',
  atsSecretExplanation: "vul de 'client secret' in die het ATS aangeeft voor de integratie.",
  activate: 'Activeer',

  campaignCompletedTitle: 'Goed bezig!',
  campaignCompletedMessage: (
    <Fragment>
      Je hebt alle kandidaten beoordeeld.
      <br />
      <br />
      Zoek opnieuw of wijzig de zoekopdracht, zodat we op zoek kunnen naar meer profielen.
    </Fragment>
  ),
  campaignGroup1CompletedMessage: (
    <Fragment>
      Je hebt alle kandidaten beoordeeld. Ik heb nog 25 alternatieve kandidaten beschikbaar die minder goed aansluiten
      bij de zoekopdracht en mogelijk toch interessant zijn. Wil je die bekijken?
      <br />
      <br />
      Je kunt de zoekopdracht ook wijzigen, zodat we op zoek kunnen naar meer profielen.
    </Fragment>
  ),
  searchingMoreProfiles: 'Zoeken meer profielen.',
  searchAgain: 'Zoek opnieuw',
  reviewAlternativeCandidates: 'Bekijk alternatieve kandidaten',

  mustBeLessThanMax: 'Moet minder zijn dan maximale werkervaring',
  mustBeMoreThanMin: 'Moet meer zijn dan minimale werkervaring',

  // common
  any: 'Geen voorkeur',
  of: 'van',
  add: 'Toevoegen',
  edit: 'Aanpassen',
  delete: 'Verwijderen',
  deactivate: 'Deactiveer',
  saveChanges: 'Opslaan',
  close: 'Sluiten',
  remove: 'Verwijder',
  mustHave: 'Vereist',
  exclude: 'Uitsluiten',
  saving: 'Opslaan...',
  done: 'Gelukt!',
  loading: 'Aan het laden',
  thisCouldTakeAFewMinutes: 'Dit kan enkele minuten duren',
  changesSaved: 'Wijzigingen opgeslagen',
  invalidUsername: 'Ongeldige gebruikersnaam',
  invalidPassword: 'Invalid wachtwoord',
  clickHere: 'klik hier',
  selectAll: 'Selecteer iedereen',
  clearAll: 'Selecteer niemand',
  scrollTo: 'Scroll naar',
  otherwiseNamely: 'Anders, namelijk ...',
  somethingWentWrong: 'Er is iets mis gegaan',
  pleaseTryAgainOrContactUs: 'Probeer het opnieuw. Als het probleem aanhoudt, neem contact met ons op.',
  notFound: 'Niet Gevonden',
  loggingYouInAutomatically: 'Je wordt automatisch ingelogd',
  couldNotLogYouInAutomatically: 'Kan niet automatisch inloggen',
  attemptNofX: (current: number, max: number) => `Poging ${current} van ${max}.`,
  minimumCharacterValue: (value) => `Gebruik minimaal ${value} tekens`,
  maximumCharacterValue: (value) => `Je kan maximaal ${value} tekens gebruiken`,
  companyNameCannotContainSpecialCharacters: 'De bedrijfsnaam mag geen spaties of speciale tekens bevatten.',
  selectAnOption: 'Kies een optie',
  and: 'en',
  or: 'of',
  expandLess: 'Minder uitbreiden',
  expandMore: 'Meer uitbreiden',
  downloadProfile: 'Profiel downloaden',
  tutorial: 'Zelfstudie',
  emailSuccessfullySent: 'Je email is succesvol verstuurd',
  emailFailedSend: 'Je email is niet verstuurd',
  linkedinSuccessfullySent: 'Je LinkedIn verzoek is succesvol verzonden!',
  linkedinFailedSend: 'Je LinkedIn verzoek is niet verzonden!',
  linkedinInMailSuccessfullySent: 'Je LinkedIn InMail verzoek is succesvol verzonden!',
  liknedinInMailFailedSend: 'Je LinkedIn  InMailverzoek is niet verzonden!',
  indeedSuccessfullySent: 'Je Indeed bericht verzoek is succesvol verzonden!',
  indeedFailedSend: 'Je Indeed bericht verzoek is niet verzonden!',
  sendDisabledIndeedTip: 'Je moet je Indeed account koppelen om dit bericht te versturen',
  sendDisabledLinkedinTip: 'Je moet je LinkedIn account koppelen om dit bericht te versturen',

  //Languages
  english: 'Engels',
  dutch: 'Nederlands',

  //Users
  actions: 'Acties',
  exportToExcel: 'Exporteren naar Excel',
  createNewUser: 'Nieuwe gebruiker aanmaken',
  manageUsers: 'Beheer gebruikers',
  userUnlock: 'Ontgrendelen',
  userActivationSettings: 'Activerings instellingen',
  isActive: 'Actief',
  userSince: 'Aanmaakdatum',
  lastLoggedIn: 'Laatste inlogtijd',
  emailConfirmed: 'E-mailbevestiging',

  userDeactivateConfirmation:
    'Weet u zeker dat u deze gebruiker wilt uitschakelen? Deze actie kan niet ongedaan gemaakt worden',
  userActivateConfirmation: 'Weet je zeker dat je deze gebruiker wilt activeren?',
  userWasUnlockedSuccessfully: 'Ontegrendeid de gebruiker',
  userWasDeletedSuccessfully: 'Succesvol verwijderd',
  userCreatedSuccessfully: 'Met succes opgeslagen',
  userProfileUpdatedSuccessfully: 'Met succes opgeslagen',
  userWasActivatedSucessfully: 'Gebruiker is succesvol geactiveerd',

  editUser: 'Wijzig gebruiker',
  invalidEmailAddress: 'Vul hier een geldig e-mailadres in.',
  invalidPhoneNumber: 'Voer uw telefoonnummer in',
  atLeast6Characters: 'Minimaal 6 tekens vereist.',

  userPasswordSettings: 'Wachtwoord instellingen',
  setNewPassword: 'Stel wachtwoord in.',
  setRandomPasswordForUser: 'Stel willekeurig wachtwoord in.',
  shouldChangePasswordOnNextLogin: 'Moet wachtwoord wijzigen bij volgende aanmelding.',
  sendActivationEmail: 'Verstuur activeringsmail.',
  setUserIsActive: 'Actief',
  setLockOut: 'Vergrendeling ingeschakeld',
  remindPassword: 'Als u uw wachtwoord bent vergeten,',
  incorrectPassword: 'Onjuist wachtwoord',
  passwordsDoNotMatch: 'Wachtwoord komt niet overeen',

  //While the user is logged out
  emailActivation: 'E-mail Activering',
  emailActivationDescription:
    'Er wordt een link naar uw e-mailadres verzonden om uw e-mailadres te activeren. Als u binnen een paar minuten geen e-mail ontvangt, probeert u het opnieuw.',
  emailActivationSentDescription:
    'Een activeringslink voor e-mail verzonden naar uw e-mailadres. Controleer alstublieft uw e-mails.',

  invalidResetLink: 'Ongeldige resetlink',
  invalidResetLinkDescription: 'De door u gebruikte resetlink is niet geldig',
  passwordResetComplete: 'Wachtwoord reset compleet',
  goToLogin: 'Ga naar de inlog pagina',
  goToLoginDescription: 'Je kan nu inloggen met je nieuwe wachtwoord',

  companyNameIsTaken: 'Deze bedrijfsnaam is al in gebruik. Probeer het opnieuw met een andere naam.',

  //Realtime Search filters
  activeFilters: (num) => `actieve filter${num === 1 ? '' : 's'}`,
  filters: 'Filters',
  totalExperience: 'Totale werkervaring',
  location: 'Locatie',
  locations: 'Locaties',
  locationsPlaceholder: 'Bijvoorbeeld Utrecht',
  skillsKeywords: 'Vaardigheden / Steekwoorden',
  skillsKeywordsPlaceholder: 'Bijvoorbeeld Excel',
  mustHaveEmail: 'Email Vereist',
  mustHavePhone: 'Telefoonnummer Vereist',
  sources: 'Bronnen',
  schoolName: 'Opleiding gevolgd bij',
  schoolNamePlaceholder: 'Bijvoorbeeld Universiteit Utrecht',
  educationDegree: 'Opleidingsniveaus',
  educationDegreePlaceholder: 'Bijvoorbeeld HBO',
  educationFields: 'Opleidingsrichting',
  educationFieldsPlaceholder: 'Bijvoorbeeld Human Resource Management',
  sizeOfBatches: 'Aantal kandidaten per batch',
  locationDistanceInKm: 'Afstand (km)',
  industries: 'Industrieën',
  aboutMe: 'Over mij',
  licenses: 'Licenties',
  searchResults: 'Zoekresultaten',
  updatedAt: 'Updated At',
  graduationYear: 'Meest recente afstudeerdatum',
  employmentType: 'Dienstverband',

  //Updated At Filter
  anyTime: 'Geen Voorkeur',
  last24Hours: 'Afgelopen 24 uur',
  last3Days: 'Afgelopen 3 dagen',
  lastWeek: 'Afgelopen Week',
  lastMonth: 'Afgelopen Maand',
  last3Months: 'Afgelopen 3 Maanden',
  last6Months: 'Afgelopen 6 Maanden',
  lastYear: 'Afgelopen Jaar',

  //Employment Type Filter
  employmentTypeFullTime: 'Full time',
  employmentTypePartTime: 'Part time',
  employmentTypeInternship: 'Stage',
  employmentTypeFreelance: 'Freelance',
  employmentTypeShortTerm: 'Tijdelijk',
  employmentTypeSeasonal: 'Seizoensgebonden',

  //Job Title Type Filter
  jobTitleType: 'Ervaring opgedaan tijdens',
  jobTitleTypeAll: 'Volledige werkgeschiedenis',
  jobTitleTypeCurrent: 'Alleen meest recente ervaring',
  jobTitleTypeRecent: 'Twee meest recente ervaringen',
  jobTitleTypePast: 'Alleen eerdere ervaringen',

  //Realtime Search Results
  candidatePreferences: 'Kandidaat voorkeuren',
  interests: 'Interesses',
  source: 'Bron',
  lastUpdated: 'Laatst geupdate',
  lastCrawled: 'Laatst ververst',
  lastMatched: 'Laatst matched',
  lastContacted: 'Laatst gecontacteerd',
  lastHidden: 'Laatst verborgen',
  lastShortlisted: 'Laatst shortlisted',
  couldNotRefreshProfile:
    'We kunnen dit profiel op dit moment niet opnieuw verversen. Het profiel is mogelijk verwijderd bij de bron. Je kan het later opnieuw proberen.',
  profileNotAvailable:
    'We kunnen dit profiel op dit moment niet opnieuw verversen. Het profiel is mogelijk verwijderd bij de bron.',
  phone: 'Telefoonnummer',
  academicExperience: 'Academische Ervaring',
  addSomeFiltersMessage: 'Voeg enkele filters toe om uw zoektocht te starten!',
  noResultsMessage:
    'Ik heb geen kandidaten kunnen vinden met deze filters. Probeer de filters aan te passen en gebruik de chat als je hulp nodig hebt!',
  openToWork: 'Beschikbaarheid',
  credentialsDisabledCallToAction: (platform: string) =>
    `Actie vereist: de logingegevens van ${platform} zijn onjuist. Update de logingegevens en voorkom dat je kandidaten mist.`,
  autoSavedFilters: 'Filters automatisch opgeslagen',

  helpCenter: 'Helpcentrum',
  help: 'Help',

  notifications: 'Meldingen',
  markNotificationAsRead: 'Melding markeren als gelezen',
  markAllAsRead: 'Markeer alles als gelezen',
  seeSearchInList: 'Zie zoeken in lijst',
  year: 'jaar',
  years: 'jaar',
  month: 'maand',
  months: 'maanden',
  inTotal: 'In totaal',
  inFunction: 'In deze functie',
  viewFullProfile: 'Bekijk volledig profiel',
  pleaseWait: 'Even geduld',
  downloadCV: 'Download CV',
  downloadCVs: 'Download CVs',
  pageNumOfNum: (current: number | string, total: number | string) => `Pagina ${current} van ${total}`,
  issued: 'Uitgerijkt',
  resume: 'Introductie',
  licensesAndCertifications: 'Licenties en certificeringen',
  jobs: 'Vacatures',
  minWorkHours: 'Min. uren per week',
  maxWorkHours: 'Max. uren per week',
  minSalary: 'Min. salaris',
  maxSalary: 'Max. salaris',
  currency: 'Valuta',
  unlockContactInformation: (price: number) => `Ontgrendel contactinformatie (${price} credit${price >= 2 ? 's' : ''})`,
  saveAndUnlockContactInformation: (price: number) =>
    `Bespaar en ontgrendel contactinformatie (${price} credit${price >= 2 ? 's' : ''})`,
  unlockCV: (price: number) => `Ontgrendel CV (${price} credit${price >= 2 ? 's' : ''})`,
  currentOfTotal: (current: number | string, total: number | string) => `${current} van ${total}`,
  showMore: 'meer weergeven',
  showLess: 'minder weergeven',
  showXMore: (quantity) => `Laat meer ${quantity} zien`,
  showXLess: (quantity) => `Toon ${quantity} minder`,
  showAll: (quantity) => `Alles weergeven (${quantity})`,
  requestContactInformation: 'Vraag contactinformatie op',
  youCanRequestContactInformation:
    'Je kan contactinformatie opvragen. We rekenen één credit als we contactinformatie vinden.',
  thisProfileWasUpdatedTimeAgo: (timeAgo) => `Dit profiel is ${timeAgo} geleden door de kandidaat bijgewerkt`,
  thisProfileWasRefreshedTimeAgo: (timeAgo) => `De kandidaat data is ${timeAgo} geleden door ons ververst`,
  thisProfileWasApprovedTimeAgo: (timeAgo) => `Dit profiel is ${timeAgo} geleden goed gekeurd`,
  loading_ats_synchronization_label: 'Synchroniseert met ATS',
  loading_contact_info_label: 'Zoekt contactinformatie',
  reTriggerATSCandidateSync: 'Stuur opnieuw naar ATS',
  errorSectionTitle: 'Fout',
  errorSectionMissingSkillOrFunction: 'Voeg ten minste één vaardigheid, functietitel of locatie toe',
  hideFromApproved: 'Verberg in goedgekeurd',
  userFormGenericError: 'Er ging iets fout bij het opslaan van de gebruiker',
  userFormEmailAlreadyTakenError: 'Dit e-mailadres (@email) is al in gebruik',
  duplicate: 'Kopiëren',
  copyOf: 'Kopie van',
  clickHereToUpdateSearch: 'Klik hier om je zoekopdracht te verversen met de nieuwe filters',
  orSimplyPress: ({ isMacOs = false }) => (
    <Fragment>
      Of klik op&nbsp;<code>{isMacOs ? 'Cmd' : 'Ctrl'} + Enter</code>
    </Fragment>
  ),

  // Teams
  team: 'Team',
  teams: 'Teams',
  teamName: 'Team naam',
  teamUsers: 'Gebruikers in team',
  manageTeams: 'Beheer teams',
  createNewTeam: 'Maak nieuw team aan',
  createdBy: 'Aangemaakt door',
  createdAt: 'Aangemaakt op',
  editTeam: 'Wijzig team',
  teamDeletionConfirmation:
    'Weet je zeker dat je dit team wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.',
  teamWasDeletedSuccessfully: 'Team succesvol verwijderd',
  teamCreatedSuccessfully: 'Team succesvol aangemaakt',
  teamUpdatedSuccessfully: 'Team succesvol opgeslagen',
  editTenantEmailSettingsBtn: 'Bedrijfsnotificatie e-mails',
  editTenantEmailSettings: 'Wijzig bedrijfsnotificatie e-mailinstellingen',
  editTenantEmailSettingsExplanation:
    'Deze configuratie werkt alleen als je geen team hebt. U kunt een meldingsconfiguratie instellen voor een team afzonderlijk. Alles wat binnen een specifiek team is ingesteld, overschrijft de hier gedefinieerde instellingen.',
  tenantEmailSettingsCreatedSuccessfully: 'Bedrijfsnotificatie e-mailinstellingen succesvol aangemaakt',
  tenantEmailSettingsUpdatedSuccessfully: 'Bedrijfsnotificatie e-mailinstellingen succesvol aangepast',
  joinTeamSuccessfully: 'Je bent nu onderdeel van het team',
  exitTeamSuccessfully: 'Je bent geen onderdeel meer van dit team',
  join: 'Aanmelden',
  leave: 'Verlaten',
  detailsTeam: 'Team details',

  updateContactInformation: 'Update contactinformatie',
  contactInfoAvailableFrom: (platform: string) => `Contactinformatie beschikbaar van ${platform}`,
  requestHere: 'Vraag hier',
  refreshHappensAutomatically: 'Verversen gebeurt nu automatisch',
  notificationJobbboardNewVersion: (platform: string) =>
    `Er is een nieuwe integratie voor ${platform}! Neem contact met ons op om de nieuwste ${platform} kandidaten te blijven zien.`,

  requestJobboardInformationConfirmationTitle: (platform: string) =>
    `Vraag contactinformatie van kandidaat op bij ${platform}`,
  requestJobboardInformationConfirmationDescription: (platform: string) =>
    `Deze actie kost een ${platform} credit, weet je zeker dat je deze contactinformatie wil opvragen?`,
  requestJobboardInformationConfirmationCancelText: 'Annuleer informatie aanvraag',
  requestJobboardInformationConfirmationConfirmText: 'Vraag contactinformatie op',

  dontPerformThisCheckNextTime: 'Vraag dit niet opnieuw',

  candidateNotFoundTitle: 'Helaas is deze kandidaat niet langer beschikbaar',
  candidateNotFoundSubtitle: 'Het profiel is verwijderd in de originele bron',

  /// Platforms
  indeed: 'Indeed',
  linkedIn: 'LinkedIn',
  linkedInInMail: 'LinkedIn InMail',
  whatsApp: 'WhatsApp',

  /// Contact Modal
  chooseContactTitleModal: 'Kies een contactmethode',
  chooseContactDescriptionModal: (name) => `Selecteer hoe je ${name} wil benaderen`,
  writeEmailTo: (name) => `Verstuur je email naar ${name}`,
  writeMessageTo: (name) => `Verstuur je bericht naar ${name}`,
  sendWithWhatsAppWeb: 'Stuur met WhatsApp Web',
  sendWithIndeed: 'Stuur met Indeed',
  sendLinkedInRequest: 'Stuur LinkedIn Connectieverzoek',
  sendInMail: 'Stuur InMail',
  sentWhatsAppTo: (name: string) => `Whatsapp bericht verstuurd naar ${name}`,
  sentEmailTo: (name: string) => `Email verstuurd naar ${name}`,
  emailSent: 'Email verzonden',
  indeedSent: 'Indeed verzonden',
  whatsappSent: 'WhatsApp verzonden',
  linkedinConnectionSent: 'LinkedIn connectieverzoek verzonden',
  linkedinInmailSent: 'LinkedIn InMail verzonden',
  sentIndeedTo: (name: string) => `Indeed bericht verstuurd naar ${name}`,
  sentLinkedInTo: (name: string) => `Linkedin connectieverzoek verstuurd naar ${name}`,
  sentLinkedInMailTo: (name: string) => `LinkedIn InMail verstuurd naar ${name}`,
  sentWerknlMailTo: (name: string) => `Werknl bericht verstuurd naar ${name}`,
  sentBy: 'Verstuurd door:',

  /// Connect Ohone Modal
  connectYourPhoneTitleModal: 'Koppel met WhatsApp',
  connectYourPhoneDescriptionModal:
    'Om WhatsApp berichten te versturen adviseren we je de desktop App te gebruiken. Download en installeer de App op je PC en verbind je mobiele telefoon. Vervolgens kun je aan de slag!',
  showMeHow: 'Bekijk hoe',
  dontShowThisAgain: 'Laat dit niet opnieuw zien',
  imConnected: 'Staat Gekoppeld',
  downloadTheApp: 'Download de app',

  /// Browser Extension Modal
  browserExtensionRequired: 'Browser extensie vereist',
  browserExtensionRequiredDescriptionModal:
    'Onze browser extensie is nodig om Indeed of LinkedIn berichten te versturen naar kandidaten. We zien dat je deze extensie nog niet hebt geïnstalleerd. Klik op de knop om deze te installeren.',
  installTheExtension: 'Installeer de extensie',

  /// Confirm Message Modal
  confirmMessageTitleModal: 'Heb je jouw bericht verstuurd?',
  confirmMessageDescriptionModal:
    'Laat ons weten of je deze kandidaat een bericht hebt gestuurd, zodat wij hun status op de goede manier kunnen updaten',
  messageNotSent: 'Niet verstuurd',
  messageSent: 'Bericht Verstuurd',

  /// Confirm Call Modal
  confirmCallTitleModal: (name) => `Nam ${name} de telefoon op?`,
  confirmCallDescriptionModal:
    'Laat ons weten of je deze kandidaat gesproken hebt, zodat wij hun status op de goede manier kunnen updaten',
  couldntConnect: 'Niet Gecontacteerd',
  connected: 'Gecontacteerd',
  callSaved: 'Opgeslagen',

  /// Fields tips
  campaignNotificationByEmailTip: 'Ontvang email notificaties van kandidaten gevonden voor jouw zoekopdrachten',
  nameSearchTip: 'Vul een naam in die makkelijk te herkennen is, jouw team zal dit ook gebruiken!',
  jobTitleTip: 'Voeg een relevante functietitel in, wij doen daarna 10 suggesties op basis van deze titel',
  jobTitleTypeTip: 'Wanneer de bovengenoemde functies in de ervaring van de kandidaat hebben plaatsgevonden',
  locationDistanceInKmTip: 'Zoekstraal rond de geselecteerde locaties (geen reisafstand)',
  contactInfoTip:
    'Wanneer beide opties zijn aangevinkt, worden alleen kandidaten getoond die beide in het profiel hebben staan',
  totalExperienceTip: 'Hoeveel werkervaring de kandidaat in totaal op het profiel heeft staan',
  languagesTip: 'Welke talen de kandidaat moet beheersen voor het uitvoeren van de functie',
  lastUpdatedTip: 'Wanneer de kandidaat voor het laatst het profiel heeft bijgewerkt op de jobboards',
  graduationYearTip: 'Meest recente einddatum van genoten opleiding/cursus door de kandidaat',
  schoolNameTip: 'Bij welke opleidingsinstituten de kandidaat een opleiding heeft gevolgd',
  educationDegreeTip:
    'Opleidingsniveau dat de kandidaat ten minste moet hebben. Niet per definitie de hoogst of enige genoten opleiding.',

  /// Admin
  backToSearchCampaigns: 'Terug naar zoekopdrachten',
  createNewCompany: 'Maak een nieuw bedrijf aan',
  editCompany: 'Wijzig bedrijf',
  editUsers: 'Wijzig gebruikers',
  impersonateUser: 'Inloggen als deze gebruiker',
  creditLimit: 'Credit limiet',
  updateUsersSuccessfully: 'Gebruiker is succesvol opgeslagen',
  id: 'Id',
  companyCode: 'Code',
  edition: 'Editie',
  active: 'Actief',
  inactive: 'Inactief',
  credits: 'Credits',
  created: 'Aangemaakt',
  updated: 'Gewijzigd',
  demo: 'Demo',
  standard: 'Standaard',
  tenantHasBeenUpdatedSuccessfully: 'Bedrijf is succesvol opgeslagen',
  tenantHasBeenCreatedSuccessfully: 'Bedrijf is succesvol aangemaakt',
  tenantName: 'Bedrijfsnaam',
  removeSpaces: 'Verwijder spaties',
  subscriptionEdition: 'Abonnementseditie',
  editTenantUsers: 'Wijzig gebruikers van bedrijf',
  editTenantUsersSubtitle: 'De eerste gebruiker in deze lijst is de admin en is verplicht.',
  name: 'Naam',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  fullName: 'Volledige naam',
  lastPosition: 'Laatste ervaring',
  lastCompany: 'Laatste bedrijf',
  recruiterFullName: 'Recruiter volledige naam',
  NameJobboard: 'Naam Jobboard',
  NameVacancy: 'Naam Vacature',
  Location: 'Locatie',
  addAnotherUser: 'Voeg een gebruiker toe',
  impersonate: 'Inloggen als',
  searchCompany: 'Zoek bedrijf',
  entriesPerPage: 'Resultaten per pagina',

  matchPhase: 'Matchfase',
  none: 'Geen',

  website: 'Website',

  typeYourMessage: 'Schrijf hier je bericht...',
  typeYourNote: 'Schrijf hier je notitie...',
  subject: 'Onderwerp',
  signOff: 'Schrijf een groet,',
  featureToggles: 'Features beheren',
  addFeatureToggle: 'Voeg feature toe',
  title: 'Titel',
  value: 'Waarde',
  enabled: 'Actief',
  emptySubject: 'Je hebt geen onderwerp ingevuld',
  emptyMessage: 'Je hebt geen bericht ingevoerd',
  emptySignOff: 'Je hebt geen groet ingevoerd',
  yearAbbr: 'yr',
  yearsAbbr: 'yrs',
  simplified: 'Vereenvoudigd',
  addLabels: 'Voeg labels toe',
  labelAdded: 'Etiket toegevoegd',
  noteAdded: 'Notitie toegevoegd',
  fresh: 'Fresh',
  candidateHasXNotes: (labelsCount) => `Candidate has ${labelsCount} notities`,

  // Candidates Insights
  newCandidate: 'Nieuwe kandidaat',
  updatedInformation: 'Gewijzigde informatie',
  educationAddedRecently: 'Opleiding toegevoegd',
  educationDatesChanged: 'Opleidingsperiode gewijzigd',
  educationEditedRecently: 'Opleiding gewijzigd',
  experienceAddedRecently: 'Ervaring recent toegevoegd',
  jobRoleChanged: 'Functietitel gewijzigd',
  jobDescriptionChanged: 'Functieomschrijving gewijzigd',
  jobDatesChanged: 'Periode van functie gewijzigd',
  skillAddedRecently: 'Skill / vaardigheid recent toegevoegd',
  preferredEmploymentTypeChanged: 'Voorkeur dienstverband gewijzigd',
  preferredWorkHoursChanged: 'Voorkeur uren gewijzigd',
  workAvailabilityChanged: 'Beschikbaarheid recent gewijzigd',
  locationChangedRecently: 'Locatie recent gewijzigd',
  phoneAddedRecently: 'Telefoonnummer recent toegevoegd',
  phoneNumberChanged: 'Telefoonnummer gewijzigd',
  emailAddedRecently: 'E-mailadres recent toegevoegd',
  emailChanged: 'E-mailadres gewijzigd',

  latestActions: 'Laatste acties:',
  titlePlaceholder: 'Naam zoekopdracht',
  refineTheSearchBelow: 'Verfijn zoekopdracht',

  indeedNoCredits: 'Je Indeed account heeft geen credits om een Indeed email te versturen',
  createNewTemplate: 'Maak nieuw template',
  nameYourTemplate: 'Geef jouw template een naam',
  blankTemplate: 'Blanco template',

  saveTemplate: 'Template opslaan',
  selectTemplateToUse: (type) => `Selecteer een ${type} template om te gebruiken`,
  templateSavedSuccessfully: 'Template succesvol opgeslagen',
  templateTokens: 'Personalisatie tokens',
  lastActioned: 'Laatste actie',
  oldestActioned: 'Oudste actie',
  haveSeenItAll: 'Hoera, je hebt alles bekeken!',
  sharedByFromSearch: ({ userName, searchName }) => (
    <>
      Gedeeld door {userName} uit {searchName}
    </>
  ),
  copyLinkCandidateProfile: 'Kopieer een link naar het kandidatenprofiel',
  linkCopySuccess: 'Link gekopieerd!',
  linkCopyFail: 'Kopiëren van link mislukt',
  youNotHaveAccessProfile: 'Je hebt geen toegang tot het profiel van deze kandidaat',
  tryResendAts: 'Probeer de kandidaat opnieuw naar ATS te sturen',

  emailAndPhoneAvailableUponApproval: (email, phone) => {
    if (email && phone) {
      return `${dictionary.email} en ${dictionary.phone} beschikbaar na goedkeuren`;
    }

    if (phone) {
      return `${dictionary.phone} beschikbaar na goedkeuren`;
    }

    return `${dictionary.email} beschikbaar na goedkeuren`;
  },

  cvAvailableUponApproval: 'CV beschikbaar na goedkeuren',

  changeOwner: 'Wijzig eigenaar',
  currentOwner: 'Huidige eigenaar',
  makeUserSearchOwner: (userName) => `${userName} eigenaar maken (van deze zoekopdracht)?`,
  makeUserSearchOwnerDescription: (
    userName,
  ) => `Je bent niet meer de eigenaar van deze zoekopdracht omdat ${userName} nu eigenaar wordt.
  `,
  goAhead: 'Ga verder!',
  currentSearch: 'Huidige zoekopdracht',
  labelsChanged: 'Labels gewijzigd',
  notes: 'Notities',
  startTypingYourNotesHere: 'Schrijf je notities hier',
  moreInfoSent: 'Meer info gestuurd',
  notResponding: 'Reageert niet',
  notInterested: 'Geen interesse',
  positiveOutcomes: 'Positief resultaat',
  neutralOutcomes: 'Neutraal resultaat',
  negativeOutcomes: 'Negatief resultaat',
  customLabels: 'Eigen labels',
  createCustomLabel: 'Maak eigen label aan',
  removed: 'Verwijderd',
  paymentMethod: 'Betaalmethode',
  triggerToSendCandidateToAts: 'Trigger om kandidaat naar ATS te sturen',
  chooseAnOption: 'Selecteer een optie',
  selectATemplateToUse: (type) => {
    if (type === 'email') return 'Selecteer een e-mail template om te gebruiken';
    if (type === 'indeed') return 'Selecteer een Indeed template om te gebruiken';
    if (type === 'linkedin') return 'Selecteer een LinkedIn template om te gebruiken';
    if (type === 'whatsapp') return 'Selecteer een WhatsApp template om te gebruiken';
    if (type === 'werknl') return 'Selecteer een Werknl template om te gebruiken';
    return 'Selecteer een telefoon template om te gebruiken';
  },
  nameTheTemplate: 'Geef de template een naam',
  newOutreach: 'Nieuw bericht',
  content: 'Inhoud',
  signature: 'Afsluiting',
  signatureImageHelper: 'We ondersteunen jpg en png',
  calling: 'Aan het bellen met',
  candidateNotes: 'Kandidaatnotities',
  successfull: 'Succesvol',
  unsuccessfull: 'Niet succesvol',
  findMoreGreatCandidates: 'Vind meer goede kandidaten',
  tryAnotherOutreachMethod: 'Probeer een andere contactmethode',
  outreachPhoneSuccessfullHeaderText: 'Goed gewerkt, wat was het resultaat van het gesprek?',
  outreachPhoneUnsuccessfullHeaderText: 'Jammer dat het gesprek niet succesvol was. Wat was de reden?',
  filterByLabel: 'Filter op label',
  outreachResponseNoticeEmail: `Verzonden e-mails verschijnen niet in jouw verstuurde mails omdat wij deze namens jou versturen. Reacties verschijnen wel in je inbox`,
  outreachResponseNoticeWhatsApp: 'WhatsApp reacties verschijnen direct in je persoonlijke WhatsApp.',
  outreachResponseNoticeInMail: 'InMail reacties verschijnen direct in je LinkedIn account',
  outreachResponseNoticeLinkedIn: 'LinkedIn reacties verschijnen direct in je LinkedIn account',
  outreachResponseNoticeIndeed: 'Indeed reacties verschijnen direct in je Indeed account',
  jobboardRestrictionMessage: (days, platform) =>
    `Door restricties van ${platform} is het pas mogelijk om deze kandidaat over ${days} ${
      days === 1 ? 'dag' : 'dagen'
    } weer een nieuw bericht te sturen`,
  unavailableOnThisContactMethod: 'Niet beschikbaar',
  browserExtensionNotInstalled: 'Installeer onze browser extensie',
  needsLoginOnJobboard: 'Login op platform',
  needsConnectMobileApp: 'Download en log in op de mobiele Robin-app',
  notesModificationHistory: 'Notities veranderen de geschiedenis',
  lastEditedBy: 'Laatst bewerkt door',
  seeFullHistory: 'Zie volledige geschiedenis',
  emailCandidate: 'E-mail kandidaat',
  callCandidate: 'Bel kandidaat',
  workingOnIt: 'Aan het laden…',
  call: 'Call',
  callMobileByPushNotification: 'Bellen via mobiele app',
  searchNameOrOwnerOfTheSearch: 'Naam zoekopdracht of eigenaar (voornaam, achternaam of e-mail)',
  candidateNoLongerAvailable: 'Deze kandidaat is niet meer beschikbaar',
  canceledByUser: 'Geannuleerd door gebruiker',
  callingIsAutomaticDescription:
    'Bellen gaat automatisch als je een VoIP systeem hebt of je telefoon gekoppeld staat aan jouw computer',
  makeActive: 'Activeer',
  makeInactive: 'Deactiveer',
  filterBy: 'Filter op',
  lastContactedBy: ({ when }) => `Deze kandidaat is ${when} benaderd`,
  noTemplateAvailable: 'Geen sjabloon beschikbaar',
  createNewSearch: 'Maak nieuwe zoekopdracht',
  creatingNewSearch: 'Zoekopdracht wordt aangemaakt...',

  consumerKey: 'Consumer key',
  consumerSecret: 'Consumer secret',

  atsConnectedSuccessfully: (ats) => `${ats} succesvol gekoppeld`,
  weAreConnectingToYourATS: 'We koppelen nu met je ATS...',
  noCreditsAvailable: 'Geen tegoeden beschikbaar',
  selectAProject: 'Selecteer een project',
  emailOrPhone: 'Email of telefoonnummer',
  candidateSuccessfullyShortlistedToAnotherSearch: 'Kandidaat succesvol geshortlist voor andere zoekopdracht',

  noCreditsAvailableForMonsterboardTitle: 'Je Monsterboard credits zijn op.',
  noCreditsAvailableForMonsterboardDescription: () => (
    <>
      Daarom heb je geen toegang meer tot contactinformatie van Monsterboard kandidaten. Nieuwe credits kun je aanvragen
      op <strong>support@recruitrobin.com</strong>. Je ontvangt dan een voorstel van ons. Het voorstel is kosteloos en
      wordt toegezonden aan de 'admin' van jouw Robin account
    </>
  ),
  errorRetrievingDepartments: 'Fout bij het ophalen van afdelingen',
  noDepartmentsFound: 'Geen afdelingen gevonden, controleer uw RecruitNow dashboard',
  activateBeforeSearchDepartments: 'U moet deze gebruiker activeren voordat u een afdeling selecteert',
  candidateAlreadyInteracted: 'Deze kandidaat is eerder behandeld',
  newVersionAvailable: 'Nieuwe versie beschikbaar',
  newVersionAvailableDescription: () => (
    <>
      <u>Klik hier</u> om de nieuwe versie te laden
    </>
  ),
  continue: 'Doorgaan',
  languageSelection: 'Taal selectie',
  googleTranslateMessage: () => (
    <span>
      Het gebruik van de automatische vertaler in Google Chrome zorgt ervoor dat ons programma onbruikbaar wordt. Om een
      werkende app te garanderen kan je ons programma niet gebruiken tot je dit uitschakelt.{' '}
      <a href="https://help.recruitrobin.com/nl/articles/8286616-gekke-vertalingen" target="_blank" rel="noreferrer">
        Lees hier hoe je dat doet!
      </a>
    </span>
  ),
  maxAsteriskReached: 'sorry, je hebt de limiet van 5 functietitels met een * overschreden',
  maxAsteriskReachedModalDescription:
    'In het veld functietitels of vaardigheden/trefwoorden zijn maximaal 5 sterretjes (*) toegestaan. Bewerk je zoekopdracht om door te gaan.',
  maxAsteriskReachedModalClickHere: ({ href }) => (
    <span>
      <a href={href} target="_blank" rel="noreferrer">
        Klik hier{' '}
      </a>{' '}
      voor meer informatie.
    </span>
  ),
  hideCandidatesThat: 'Verberg kandidaten met status',
  forPeriodOf: 'In periode van',
  aiSuggestions: 'AI suggesties',
  searchInputPlaceholder: 'vb. Operator in Utrecht met MBO, Jan Bakker, 0612345678',
  findCandidates: 'Kandidaten vinden',
  lastSearches: 'Recente zoekopdrachten',
  noSearchesFound: 'Geen zoekopdrachten gevonden',
  didntFindJobTitleLocation: 'Geen functietitel of locatie gevonden. Probeer het opnieuw',
  robinYearWrapped: ({ year }) => `Robin ${year} wrapped`,
  robinWrapped: 'Robin wrapped',
  downloadYearWrapped: ({ year }) => `Download the ${year} wrapped`,
  wrappedYear: ({ year }) => `${year} Wrapped`,
  wrappedSummary: ({ tenantName }) => `Een uitgebreid overzicht van de activiteit "${tenantName}"`,
  matchedInYear: ({ year }) => `Matched in ${year}`,
  shortlistedInYear: ({ year }) => `Shortlisted in ${year}`,
  contactedInYear: ({ year }) => `Gecontacteerd in ${year}`,
  topJobboard: 'Top jobboard',
  mostActiveUser: 'Meest actieve gebruikers',
  totalAction: 'Totaal acties',
  lengthLimitReached: 'Lengte limiet bereikt',
  getConnectedInstantly: 'Koppel de app!',
  downloadOurMobileApp: 'Download onze app',
  downloadOurMobileAppDescription:
    ' en accepteer notificaties. Na deze 2 simpele stappen kan je direct beginnen met automatisch bellen',
  yourCallIsJustATapAway: 'Direct bellen!',
  checkYourPhoneForADirectCall: "Check je telefoon voor de 'Direct call' notificatie van Robin en start met bellen.",
  downloadRobinMobileApp: 'Download Robin app!',
  selectYourDevicePlatform: 'Selecteer het platform van je apparaat om onze app te downloaden.',
  tapToCallCandidate: (candidateName) => `Klik om “${candidateName}” te bellen!`,
  youCanCopyTheLinkPressingCtrlC: 'Je kunt de link kopiëren via Ctrl (Command) + C',
  info: 'Informatie',
  success: 'Succes',
  error: 'Fout',
  warning: 'Waarschuwing',
  welcomeBack: 'Welkom terug!',

  dashboard: 'Dashboard',
  summary: 'Samenvatting',
  summaryOfActions: 'Samenvatting van acties',
  addSummary: 'Voeg samenvatting toe',
  removeSummary: 'Remove the summary',
  allCampaigns: 'All campaigns',
  allUsers: 'All users',

  overviewOfActions: 'Overzicht van acties',
  onlyLastThreeMonths: 'Laatste 3 maanden',

  mostUsedJobboardsForMatched: 'Matches per bron',
  leaderboard: 'Leaderboard',
  summaryOfActivityForUsers: 'Samenvatting van activiteit door gebruikers / teams',
  allActions: 'Alle acties',
  campaignCreated: 'Campagne aangemaakt',
  campaignListEmptyStateMessage:
    'Gebruik de zoekbalk hierboven om een nieuwe zoekopdracht te starten en vind de perfecte kandidaten op meerdere vacaturesites',

  exportInPDF: 'Export in PDF',
  noMoreResults: 'Geen resultaten meer',
  usersSelected: 'gebruikers geselecteerd',
  campaignsSelected: 'campagnes geselecteerd',
  clickHereToLoginBackIn: 'Om te verbinden, klik op de knop en login op je',
  browserExtensionIsDisabledOrNotInstalled:
    'De browser extensie is uitgeschakeld of niet geïnstalleerd. Controleer de status of installeer opnieuw via de button!',
  clickHereToRefreshThisPage: 'Klik hier om de pagina te verversen',
  toDisconnectClickTheButton: 'Om de verbinding te verbreken, klik op de knop en log uit je',

  shouldInstallBrowserExtensionTitle: 'Robin extension',
  shouldInstallBrowserExtensionModal: (
    <span>
      Je hebt onze browser extensie niet geïnstalleerd. Onze extensie is noodzakelijk om via Robin contact te leggen met
      kandidaten van LinkedIn en Indeed en als bonus krijg je elk uur een notificatie van nieuw gevonden kandidaten!
      <br />
      Installeren is super simpel en doe je{' '}
      <a
        href="https://chromewebstore.google.com/detail/recruit-robin/edfebfbdfncaeokbcjehncoelddchlhi?hl=nl"
        target="_blank"
        rel="noreferrer">
        via hier
      </a>
      !
    </span>
  ),
  shouldCallWerknlOutreachTitle: 'Ketenmachtiging ontbreekt',
  shouldCallWerknlOutreachModal: (
    <span>
      Berichten versturen via Werk.nl is voor jouw organisatie nog niet actief. We hebben een machtiging nodig om het
      versturen van berichten te activeren. Vraag je manager om contact op te nemen via{' '}
      <a href="mailto:support@recruitrobin.com">support@recruitrobin.com</a> of{' '}
      <a
        href="https://intercom.help/recruit-robin/nl/articles/9320876-robin-machtigen-voor-werk-nl-eherkenning"
        target="_blank"
        rel="noreferrer">
        deze stappen
      </a>{' '}
      te doorlopen.
    </span>
  ),
  linkedinRunOutCreditsMessage:
    'Je hebt al je 5 gratis persoonlijke uitnodigingen voor deze maand gebruikt. LinkedIn wordt volgende maand gereset. Je kunt nog steeds connectieverzoeken sturen.',
  linkedinRunOutCreditsSendMessage: 'Stuur een LinkedIn-connectieverzoek',
  linkedinRunOutCreditsSendingMessage: ({ onClick }) => {
    return (
      <span>
        Sorry, je bericht is niet verzonden.
        <br />
        Je had geen credits meer om berichten
        <br />
        te versturen op LinkedIn. Je kunt nog
        <br />
        steeds connectieverzoeken sturen.
        <br />
        <b onClick={onClick} style={{ cursor: 'pointer' }}>
          <u>Click here</u>
        </b>{' '}
        om een connectieverzoek te sturen.
      </span>
    );
  },
  werknlMessageTo: (name) => `Werk.nl-bericht aan ${name}`,
  werknlInputEmail: 'E-mail (vereist als telefoon niet opgegeven is)',
  werknlInputPhone: 'Telefoon (vereist als e-mail niet opgegeven is)',
  werknlOutreachModalInfo: (
    <span>
      Verzonden werk.nl-berichten verschijnen niet in je outbox omdat wij berichten namens jou versturen.
      <br />
      Antwoorden worden naar je e-mail inbox gestuurd.
    </span>
  ),

  emailSettings: 'Gekoppelde e-mailadressen',
  connectEmailAccount: 'Verbind nu je e-mail',
  reconnectEmailAccount: 'Verbind opnieuw',
  disconnectEmailAccount: 'Ontkoppel e-mail',
  theConnectionWasASuccess: 'Koppeling succesvol!',
  theConnectionWasASuccessDescription: 'Jouw e-mail is gekoppeld aan Robin. Je kan nu e-mails versturen met Robin.',
  backToRobin: 'Terug',
  emailConnectedDescription: 'Jouw e-mail is gekoppeld aan Robin. Je kan nu e-mails versturen met Robin.',
  emailNotConnectedDescription: (
    <span>
      Verbind je bedrijfsmail aan Robin om de conversie op e-mails te vergoten!{' '}
      <a href="https://help.recruitrobin.com/nl/articles/9414745-e-mail" target="_blank" rel="noreferrer">
        Lees hier meer
      </a>
    </span>
  ),
  emailConnections: 'Gekoppelde e-mails',
  disconnectEmailAccountModalDescription:
    'Weet je zeker dat je, je e-mail wil ontkoppelen? Als je doorgaat kan je geen e-mails meer versturen via Robin.',
  connectEmailModalDescription:
    'Koppel je persoonlijke of bedrijfsmail aan Robin om e-mails naar kandidaten te sturen!',
  theConnectionFailed: 'Koppeling niet geslaagd!',
  theConnectionFailedDescription:
    'Zorg er alsjeblieft voor dat het e-mailadres waarmee je verbindt hetzelfde is als het e-mailadres dat je gebruikt om in te loggen op Robin.',
  connectEmailDescription: (
    <span>
      Om de conversie op verzonden e-mails te verhogen moedigen wij iedereen aan je bedrijfsmail aan Robin te koppelen!{' '}
      <a href="https://help.recruitrobin.com/nl/articles/9414745-e-mail" target="_blank" rel="noreferrer">
        Lees hier meer!
      </a>
    </span>
  ),
  remindToConnectEmail: (name) => (
    <span>
      Ha {name},
      <br />
      <br />
      We veranderen van e-mail server om je deze voordelen te geven;
      <br />
      <br />
      <div css={{ paddingLeft: '1em' }}>
        1. <strong>Meer reacties van kandidaten</strong>. Mails kunnen vanaf nu met jouw eigen e-mail server worden
        verzonden, in plaats van die van ons. E-mail belanden daardoor minder vaak in spam.
      </div>
      <br />
      <div css={{ paddingLeft: '1em' }}>
        2. <strong>E-mails vind je terug in jouw mailbox</strong>. Zo weet je zeker dat e-mails zijn verzonden en kun je
        alle communicatie ook in jouw eigen e-mailbox terugvinden.
      </div>
      <br />
      <strong>1 minuut</strong>
      <br />
      In één minuut en vijf klikken heb je jouw e-mail gekoppeld met Robin. Tot <strong>31 juli</strong> kun je onze
      mailserver van Robin nog gebruiken.
      <br />
      <br />
      <strong>Hulp nodig?</strong>
      <br />
      Je kan ons via chat het beste bereiken om de koppeling met jouw e-mail samen te maken.
    </span>
  ),
  remindToReconnectEmail: (name) => (
    <span>
      Hi {name},
      <br />
      <br />
      Door een technische fout moeten we jouw email account opnieuw koppelen.
      <br />
      Dit is nodig om via Robin emails te kunnen blijven versturen naar kandidaten.
      <br />
      <br />
      Klik op de 'Verbind opnieuw' knop hieronder en we nemen je mee in de benodigde stappen!
    </span>
  ),
  werknlProfiles: 'Werknl Bedrijfsprofielen',
  waitForTheCampaignToFullyLoad: 'Wacht tot de zoekopdracht geladen is',
  werknlIsCurrentlyDisabled: 'Werk.nl is gedeactiveerd',
  loadingCandidateCv: 'CV van kandidaat wordt geladen',
  noShortlistedCandidate: ({ onClick }) => {
    return (
      <span>
        Je hebt nog geen kandidaten 'shortlisted'. Begin door de beste kandidaten uit je zoekresultaten op{' '}
        <u onClick={onClick} css={{ cursor: 'pointer' }}>
          'shortlist'
        </u>{' '}
        te zetten.
      </span>
    );
  },
  noOtherStatusCandidate: (status) => `Je hebt nog geen kandidaten ${status}.`,
  werknlCandidateInfoPresentation: (
    <span>
      Welk{' '}
      <a href={'https://www.werk.nl/werkzoekenden/'} target="_blank" rel="noreferrer">
        Werk.nl
      </a>{' '}
      profiel heeft jouw voorkeur?
    </span>
  ),
  werknlCandidateInfoPresentationDescription:
    'We hebben de lay-out van het kandidaatprofiel gewijzigd om het beoordelen van kandidaten eenvoudiger te maken. Welke versie wil je blijven gebruiken?',
  werknlCandidateInfoPresentationNote:
    '*De keuze kan later alsnog worden gewijzigd in jouw persoonlijke profiel instellingen',
  old: 'Oud',
  confirmSelection: 'Keuze bevestigen',
  werknlLayout: 'Werk.nl lay-out',
  cvFormatIssues: 'Dit CV kan niet getoond worden vanwege problemen met het bestand.',
};

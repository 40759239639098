/* eslint-disable @typescript-eslint/no-unused-vars*/

import { CandidateOutreachCallProps } from './types';
import { useStyles } from './styles';
import { Column, MenuItem, Padding, Row, Typography } from 'components/ui/atoms';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Button, FieldLabelWrapper, InformationQuote, TextArea } from 'components/ui/molecules';
import {
  AtSign,
  CheckCircle,
  CornerUpLeft,
  Indeed,
  LinkedInFill,
  MessageCircle,
  Plus,
  WhatsApp,
  XCircle,
} from 'components/ui/atoms/icons';
import { camelCase } from 'change-case';
import { sharedUtils } from 'utils';
import { useBrowserExtension } from 'shared/hooks/useBrowserExtension';
import { NotifyCandidateMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import { useDebounce } from 'react-use';
import { isAxiosError } from 'helpers/clientHelpers';
import { useGTM } from 'hooks/gtm';
import { logger } from 'config/logger';
import NotificationQuote from 'components/ui/molecules/NotificationQuote';
import DownloadMobileAppModal from 'components/ui/molecules/DownloadMobileAppModal/DownloadMobileAppModal';
import TimeAgo from 'javascript-time-ago';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

const CandidateOutreachCall = ({
  candidateName,
  campaignId,
  esPersonId,
  headerDescription,
  openAnotherOutreachMethod,
  notes: _notes,
  onSaveNotes,
  labels: _labels,
  onSaveLabels,
  onContactSuccess,
  shouldEnableEmail,
  shouldEnableWhatsapp,
  shouldEnableLinkedin,
  shouldEnableIndeed,
  handleCloseModal,
  lastContact,
  onCallMobileByPushNotification,
}: CandidateOutreachCallProps) => {
  const { dictionary, language } = useContext(LocalizationContext);
  const { createSnackbar } = useContext(SnackbarContext);
  const { currentUser } = useContext(UserContext);
  const gtm = useGTM();
  const { linkedinProStatus } = useBrowserExtension();
  const classes = useStyles();
  const [notes, setNotes] = useState('');
  const [labels, setLabels] = useState<string[]>(_labels);
  const [page, setPage] = useState<'home' | 'success' | 'unsuccess' | 'try-another-method'>('home');
  const [isContactSaving, setIsContactSaving] = useState(false);
  const [isLabelsSaving, setIsLabelsSaving] = useState(false);
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);
  const isMobileAppConnected = useMemo(() => !!currentUser?.expo_push_token, [currentUser?.expo_push_token]);
  const defaultLabels = useMemo(
    () => [
      { label: 'contacted', group: dictionary.positiveOutcomes },
      { label: 'interviewScheduled', group: dictionary.positiveOutcomes },
      { label: 'leftVoicemail', group: dictionary.neutralOutcomes },
      { label: 'moreInfoSent', group: dictionary.neutralOutcomes },
      { label: 'notResponding', group: dictionary.negativeOutcomes },
      { label: 'notInterested', group: dictionary.negativeOutcomes },
      { label: 'notOpenToWork', group: dictionary.negativeOutcomes },
    ],
    [dictionary],
  );

  useDebounce(
    () => {
      if (JSON.stringify(labels.sort()) !== JSON.stringify(_labels.sort())) {
        const saveLabels = async () => {
          setIsLabelsSaving(true);
          try {
            await onSaveLabels(labels);
          } catch (e) {
            logger.error(e);
            createSnackbar(isAxiosError(e) ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });
          }
          setIsLabelsSaving(false);
        };

        saveLabels();
      }
    },
    500,
    [labels],
  );

  useDebounce(
    () => {
      if (notes !== _notes) {
        const saveNotes = async () => {
          try {
            await onSaveNotes(notes);
          } catch (e) {
            logger.error(e);
            createSnackbar(isAxiosError(e) ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });
          }
        };

        saveNotes();
      }
    },
    250,
    [notes],
  );

  useEffect(() => {
    if (isMobileAppConnected) {
      onCallMobileByPushNotification();
    }
  }, []);

  const groupedLabels = useMemo<[string, { label: string; key: string }[]][]>(() => {
    const allLabels = labels
      .filter((label) => !defaultLabels.some(({ label: l }) => l === label))
      .reduce<{ label: string; group: string }[]>(
        (acc, label) => [...acc, { label, group: dictionary.customLabels }],
        [...defaultLabels],
      )
      .map(({ label, group }) => ({
        group,
        key: label,
        label: sharedUtils.getTranslationsFromLabelKey(label, { dictionary }),
      }));

    const hashGroupedLabels = allLabels.reduce(
      (acc, { label, group, key }) => {
        if (
          (page === 'unsuccess' && group === dictionary.positiveOutcomes) ||
          (page === 'success' && group === dictionary.negativeOutcomes)
        ) {
          return acc;
        }

        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push({ key: key, label: label });
        return acc;
      },
      {} as Record<string, { label: string; key: string }[]>,
    );

    return Object.keys(hashGroupedLabels).map((group) => [group, hashGroupedLabels[group]]);
  }, [labels, dictionary, page]);

  const timeAgo = useMemo(() => {
    return new TimeAgo(language === 'en' ? 'en-US' : 'nl-NL');
  }, [language]);

  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  useEffect(() => {
    setLabels(_labels);
  }, [_labels]);

  const handleSelectLabel = (label: string) => {
    const ccLabel = camelCase(label);

    if (labels.find((l) => l === ccLabel)) {
      setLabels(labels.filter((l) => l !== ccLabel));
    } else {
      setLabels([...labels, ccLabel]);
    }
  };

  const handleContactSuccess = async () => {
    setIsContactSaving(true);
    try {
      await onContactSuccess();
      setPage('success');
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setIsContactSaving(false);
  };

  const handleContactUnsuccess = () => {
    setPage('unsuccess');
    gtm.callUnsuccessfull(campaignId, esPersonId);
  };

  const handleTryAnotherOutreachMethod = () => {
    setPage('try-another-method');
  };

  const handleOpenAnotherOutreachMethod = (method: Exclude<NotifyCandidateMethod, 'call'>) => {
    openAnotherOutreachMethod(method);
  };

  const handleOnClickCandidateNumber = () => {
    onCallMobileByPushNotification();
  };

  return (
    <Column gap={24} css={classes.root} expand>
      <Column>
        <Column gap={4}>
          <Typography variant="header.h2" color={(c) => c.monochrome[100]}>
            {dictionary.newOutreach}
          </Typography>
          <Typography variant="header.h4">
            <Column gap={24}>
              <Column gap={0} css={classes.headerDescription}>
                <span>
                  {page === 'home' && `${dictionary.calling} ${candidateName}`}
                  {page === 'success' && dictionary.outreachPhoneSuccessfullHeaderText}
                  {page === 'unsuccess' && dictionary.outreachPhoneUnsuccessfullHeaderText}
                  {page === 'try-another-method' && dictionary.tryAnotherOutreachMethod}
                </span>
                {headerDescription && (
                  <a href={`tel:${headerDescription}`}>
                    <span onClick={handleOnClickCandidateNumber}>{headerDescription}</span>
                  </a>
                )}
              </Column>

              {isMobileAppConnected ? (
                <NotificationQuote message={dictionary.checkYourPhoneForADirectCall} />
              ) : (
                <InformationQuote
                  message={
                    <>
                      <Row css={classes.clickable} inline onClick={() => setOpenDownloadAppModal(true)}>
                        {dictionary.downloadOurMobileApp}
                      </Row>
                      {dictionary.downloadOurMobileAppDescription}
                    </>
                  }
                />
              )}
            </Column>
          </Typography>
        </Column>

        {lastContact && (
          <InformationQuote
            icon="MessageCircle"
            message={dictionary.lastContactedBy({ when: timeAgo.format(lastContact.when).toString() })}
          />
        )}
      </Column>

      <Column expand overflowAuto>
        {page === 'home' && (
          <FieldLabelWrapper label={dictionary.candidateNotes}>
            <TextArea onChange={setNotes} text={notes} />
          </FieldLabelWrapper>
        )}

        {(page === 'success' || page === 'unsuccess') && (
          <>
            <Column gap={0}>
              {groupedLabels.map(([group, ls]) => (
                <Fragment key={`${group}-${JSON.stringify(ls)}`}>
                  <Padding padding={[12, 16, 4]}>
                    <Typography variant="supporting.labelTiny" color={(c) => c.monochrome[70]}>
                      {group}
                    </Typography>
                  </Padding>
                  {ls.map(({ label, key }) => (
                    <MenuItem
                      key={key}
                      prefixVariant="checkbox"
                      onClick={() => handleSelectLabel(key)}
                      selected={labels.includes(camelCase(key))}>
                      {label}
                    </MenuItem>
                  ))}
                </Fragment>
              ))}
            </Column>
          </>
        )}

        {page === 'try-another-method' && (
          <Column gap={0}>
            <MenuItem
              onClick={() => handleOpenAnotherOutreachMethod('email')}
              icon={<AtSign color={(c) => c.byzantine[60]} />}
              disabled={!shouldEnableEmail}>
              {dictionary.email}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenAnotherOutreachMethod('whatsapp')}
              icon={<WhatsApp color={(c) => c.platformColor.whatsapp} />}
              disabled={!shouldEnableWhatsapp}>
              {dictionary.whatsApp}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenAnotherOutreachMethod('linkedin')}
              icon={<LinkedInFill color={(c) => c.platformColor.linkedin} />}
              disabled={!shouldEnableLinkedin}>
              {dictionary.linkedIn}
            </MenuItem>
            {
              <MenuItem
                onClick={() => handleOpenAnotherOutreachMethod('indeed')}
                icon={<Indeed color={(c) => c.platformColor.indeed} />}
                disabled={!shouldEnableIndeed}>
                {dictionary.indeed}
              </MenuItem>
            }
            {linkedinProStatus === 'Logged In' && (
              <MenuItem
                onClick={() => handleOpenAnotherOutreachMethod('linkedin_inmail')}
                icon={<LinkedInFill color={(c) => c.platformColor.linkedin} />}
                disabled={!shouldEnableLinkedin}>
                {dictionary.linkedInInMail}
              </MenuItem>
            )}
          </Column>
        )}
      </Column>

      <Column>
        {page === 'home' && (
          <>
            <Typography variant="body.short">{dictionary.confirmCallDescriptionModal}</Typography>
            <Row gap={8}>
              <Button
                icon={<XCircle />}
                label={dictionary.unsuccessfull}
                variant="warning"
                onClick={handleContactUnsuccess}
                contained
              />
              <Button
                icon={<CheckCircle />}
                label={dictionary.successfull}
                variant="highlight"
                onClick={handleContactSuccess}
                loading={isContactSaving}
                contained
              />
            </Row>
          </>
        )}

        {page === 'success' && (
          <Button
            icon={<CornerUpLeft />}
            variant="highlight"
            label={dictionary.findMoreGreatCandidates}
            loading={isLabelsSaving}
            onClick={handleCloseModal}
            contained
          />
        )}

        {page === 'unsuccess' && (
          <Button
            icon={<MessageCircle />}
            variant="highlight"
            label={dictionary.tryAnotherOutreachMethod}
            onClick={handleTryAnotherOutreachMethod}
            loading={isLabelsSaving}
            contained
          />
        )}
      </Column>

      <DownloadMobileAppModal onClose={() => setOpenDownloadAppModal(false)} visible={openDownloadAppModal} />
    </Column>
  );
};

export default CandidateOutreachCall;

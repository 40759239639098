/* eslint-disable @typescript-eslint/no-unused-vars*/
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_STATUS } from 'services/candidates';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useHidden = (params: WrappedHookParams) => {
  return useCandidateAction<HiddenHookCallbackParams>(
    async ({ candidate, reasons }) => {
      await campaignsClient.createOrUpdateCampaignCandidate({
        campaignId: candidate.campaignId,
        esPersonId: candidate.es_person_id,
        status: CANDIDATE_STATUS.HIDDEN,
        is_unlocked: candidate.isUnlocked,
        ...(!!reasons && { reasons }),
      });
    },
    {
      onSuccess: ({ fcParams: { candidate }, gtm }) => {
        gtm.hiddenCandidate(candidate.campaignId, candidate.es_person_id);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (pageContext === PageContext.Campaign) {
            if (candidate.status === CANDIDATE_STATUS.NEW) {
              co.campaign.decreaseStatOf.currentJobBoard();
            }

            co.campaign.remove.fromCurrentList();
            co.campaign.reset.hiddenList();
            co.campaign.decreaseStatOf.currentList();
            co.campaign.increaseStatOf.hiddenList();
          } else if (pageContext === PageContext.Shared) {
            co.share.moveCandidateTo.hiddenList();
          }
        };
      },
    },
  )(params);
};

type HiddenHookCallbackParams = {
  reasons?: string[];
};

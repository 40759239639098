import { Fragment, useContext, useEffect } from 'react';
// @ts-ignore
import { Dialog } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useMedia } from 'react-use';

import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { Close } from 'shared/icons/Close/Close';

import { useQueryClient } from '@tanstack/react-query';
import { BaseError } from 'clients/types';
import { WerknlLayout } from 'clients/UserClient/types';
import { extractErrorMessageFromAxiosResponse } from 'helpers/clientHelpers';
import { RadioGroupWithUseForm } from 'shared/components/RadioGroup';
import { SelectWithUseForm } from 'shared/components/Select';
import { TextFieldWithUseForm } from 'shared/components/TextField';
import { breakpoints } from 'styles/breakpoints';
import { useStyles } from './EditUserProfile.styles';
import {
  AskBeforeSpendingMonsterInventoryCreditOptionType,
  CampaignNotificationOptionsType,
  EditUserProfileProps,
  FormFields,
  WerknlLayoutRadioOptionType,
} from './EditUserProfile.types';

export const EditUserProfile = ({ open, setOpen }: EditUserProfileProps) => {
  const queryClient = useQueryClient();
  const {
    updateCurrentUser,
    userEmailSettings,
    updateUserEmailSettingsProfile,
    userJobBoardSettings,
    updateUserJobBoardSettingsProfile,
    currentUser,
    hasMonsterboardV2License,

    /// TODO WERKZOEKEN: Remove this after release new jobboard
    // hasWerkzoekenLicense,
  } = useContext(UserContext);
  const { setSnackbarState } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const isMobile = useMedia(breakpoints.mobile);

  const campaignNotificationEmailOptions: CampaignNotificationOptionsType[] = [
    { title: dictionary.campaignNotificationDisabled, value: 0 },
    { title: dictionary.campaignNotificationOnce, value: 1 },
    { title: dictionary.campaignNotificationTwice, value: 2 },
    { title: dictionary.campaignNotificationThrice, value: 3 },
  ];

  const askBeforeSpendingMonsterInventoryCreditRadioList: AskBeforeSpendingMonsterInventoryCreditOptionType[] = [
    { label: dictionary.yesAskEveryTime, value: 'true' },
    { label: dictionary.noNeverAsk, value: 'false' },
  ];

  const optionWerknlLayout: WerknlLayoutRadioOptionType[] = [
    { label: dictionary.new, value: WerknlLayout.New },
    { label: dictionary.old, value: WerknlLayout.Legacy },
  ];

  const methods = useForm<FormFields>({
    defaultValues: {
      name: currentUser?.first_name,
      surname: currentUser?.last_name,
      emailAddress: currentUser?.email,
      phoneNumber: currentUser?.phone_number,
      campaignNotifications: userEmailSettings?.enabled ? userEmailSettings?.frequency : 0,
      askBeforeSpendingMonsterInventoryCredit: String(
        userJobBoardSettings?.never_ask_before_spending_monster_inventory_credit,
      ),

      /// TODO WERKZOEKEN: Remove this after release new jobboard
      // askBeforeSpendingWerkzoekenCredit: String(userJobBoardSettings?.never_ask_before_spending_werkzoeken_credit),
      werknlLayout: currentUser?.werknl_layout,
    },
    mode: 'all',
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      name: currentUser?.first_name,
      surname: currentUser?.last_name,
      emailAddress: currentUser?.email,
      phoneNumber: currentUser?.phone_number,
      campaignNotifications: userEmailSettings?.enabled ? userEmailSettings?.frequency : 0,
      askBeforeSpendingMonsterInventoryCredit: String(
        userJobBoardSettings?.never_ask_before_spending_monster_inventory_credit,
      ),

      /// TODO WERKZOEKEN: Remove this after release new jobboard
      // askBeforeSpendingWerkzoekenCredit: String(userJobBoardSettings?.never_ask_before_spending_werkzoeken_credit),
      werknlLayout: currentUser?.werknl_layout,
    });
  }, [reset, currentUser, userEmailSettings, userJobBoardSettings]);

  const onSubmit = async (values: FormFields) => {
    setSnackbarState({
      open: true,
      message: dictionary.saving,
      severity: 'info',
      autoHideDuration: 5000,
    });

    const userEmailSettingsParams = {
      enabled: values.campaignNotifications > 0,
      frequency: values.campaignNotifications,
    };

    const userJobBoardSettingsParams = {
      never_ask_before_spending_monster_inventory_credit:
        values.askBeforeSpendingMonsterInventoryCredit === 'true' ? true : false,

      /// TODO WERKZOEKEN: Remove this after release new jobboard
      // never_ask_before_spending_werkzoeken_credit: values.askBeforeSpendingWerkzoekenCredit === 'true' ? true : false,
    };

    try {
      await updateCurrentUser?.({
        first_name: values.name,
        last_name: values.surname,
        email: values.emailAddress,
        phone_number: values.phoneNumber || null,
        werknl_layout: values.werknlLayout,
      });

      await updateUserEmailSettingsProfile(userEmailSettingsParams, userEmailSettings?.id);

      /// TODO WERKZOEKEN: Remove this after release new jobboard
      // if (hasMonsterboardV2License || hasWerkzoekenLicense) {
      if (hasMonsterboardV2License) {
        await updateUserJobBoardSettingsProfile?.(userJobBoardSettingsParams, userJobBoardSettings?.id);
      }

      setOpen(false);
      setSnackbarState({
        open: true,
        message: dictionary.userProfileUpdatedSuccessfully,
        severity: 'success',
        autoHideDuration: 3000,
      });

      queryClient.invalidateQueries(['currentUser']);
    } catch (e) {
      setSnackbarState({
        open: true,
        message: extractErrorMessageFromAxiosResponse(e as BaseError),
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen={isMobile}>
        <div css={classes.root}>
          <div css={classes.close} onClick={() => setOpen(false)}>
            <Close />
          </div>
          <span css={classes.title}>
            <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.neutral.variant[80]}>
              {dictionary.profile}
            </Typography>
          </span>
          <FormProvider {...methods}>
            <form css={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <div css={classes.row}>
                <TextFieldWithUseForm
                  label={dictionary.firstName}
                  name="name"
                  rules={{
                    required: dictionary.required,
                    maxLength: 100,
                  }}
                  contained
                  data-tutorial-id="first-name"
                />
              </div>
              <div css={classes.row}>
                <TextFieldWithUseForm
                  label={dictionary.lastName}
                  name="surname"
                  rules={{
                    required: dictionary.required,
                    maxLength: 100,
                  }}
                  contained
                  data-tutorial-id="last-name"
                />
              </div>
              <div css={classes.row}>
                <TextFieldWithUseForm
                  label={dictionary.emailAddress}
                  name="emailAddress"
                  type="email"
                  placeholder="email@company.com"
                  contained
                  data-tutorial-id="email"
                />
              </div>
              <div css={classes.row}>
                <TextFieldWithUseForm
                  label={dictionary.phoneNumber}
                  name="phoneNumber"
                  type="phonenumber"
                  contained
                  data-tutorial-id="phone"
                />
              </div>
              <div css={classes.row}>
                <SelectWithUseForm
                  tooltipLabel={dictionary.campaignNotificationByEmailTip}
                  label={dictionary.campaignNotificationByEmail}
                  options={campaignNotificationEmailOptions}
                  name="campaignNotifications"
                  contained
                  data-tutorial-id="notifications"
                />
              </div>
              {hasMonsterboardV2License && (
                <div css={classes.row}>
                  <RadioGroupWithUseForm
                    label={dictionary.askBeforeSpendingMonsterInventoryCredit}
                    name="askBeforeSpendingInventoryCredit"
                    radioList={askBeforeSpendingMonsterInventoryCreditRadioList}
                    contained
                    data-tutorial-id="credit"
                  />
                </div>
              )}
              {/* /// TODO WERKZOEKEN: Remove this after release new jobboard */}
              {/* {hasWerkzoekenLicense && (
                <div css={classes.row}>
                  <RadioGroupWithUseForm
                    label={dictionary.askBeforeSpendingWerkzoekenCredit}
                    name="askBeforeSpendingWerkzoekenCredit"
                    radioList={askBeforeSpendingMonsterInventoryCreditRadioList}
                    contained
                    data-tutorial-id="credit"
                  />
                </div>
              )} */}
              <div css={classes.row}>
                <RadioGroupWithUseForm
                  label={dictionary.werknlLayout}
                  name="werknlLayout"
                  radioList={optionWerknlLayout}
                  contained
                  data-tutorial-id="werknlLayout"
                />
              </div>
              <div css={classes.ctas}>
                <Button
                  variant="subtle"
                  onClick={() => setOpen(false)}
                  label={dictionary.cancel}
                  data-tutorial-id="cancel"
                />
                <Button variant="highlight" label={dictionary.save} submit data-tutorial-id="save" />
              </div>
            </form>
          </FormProvider>
        </div>
      </Dialog>
    </Fragment>
  );
};

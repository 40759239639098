import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css({}),
  groupedLabelsTitle: () => css({}),
  clickable: () =>
    css({
      cursor: 'pointer',
      textDecoration: 'underline',
    }),
  headerDescription: ({ theme }) => css`
    color: ${theme.colors.neutral.variant[70]};
    a {
      color: ${theme.colors.neutral.variant[70]};
      text-decoration: none;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

import { useQuery } from '@tanstack/react-query';
import { outreachTemplateClient } from 'clients/OutreachTemplateclient/OutreachTemplateClient';
import { Outreach, OutreachTemplateType } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';

export type UseOutreachTemplateByTypeQueryParams = {
  type: OutreachTemplateType;
};

export type UseOutreachTemplateByTypeQueryResponse = Outreach[];

const MINUTE_IN_MILLISECONDS = 60 * 1000;

export const useOutreachTemplateByTypeQuery = ({ type }: UseOutreachTemplateByTypeQueryParams) => {
  return useQuery<UseOutreachTemplateByTypeQueryResponse>(
    [`useOutreachTemplateByTypeQuery-${type}`],
    async () => {
      const { data } = await outreachTemplateClient.getOutreachTemplateByType({ type });

      return data;
    },
    { keepPreviousData: true, cacheTime: 5 * MINUTE_IN_MILLISECONDS, placeholderData: [] },
  );
};

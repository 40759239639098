import {
  CampaignCandidateSaveNotesResponse,
  CandidateActivityLog,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { Candidate } from 'model';
import { useCallback, useContext, useMemo } from 'react';
import { CANDIDATE_ACTIVITY_LOG_STATUS } from 'services/candidates/types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { checkIfShouldCreateOrSaveANote } from '../actions/useSaveNotes';
import { CandidateLastContacted } from '../types';

export const useProps: UsePropsHook = (candidate) => {
  const currentUser = useUserInfoMemoized();

  const candidateEsPersonId = candidate?.es_person_id || '';
  const candidateName = candidate?.name || '';
  const notObfuscatedPhone = candidate?.not_obfuscated_phone;
  const notObfuscatedEmail = candidate?.not_obfuscated_email;
  const labels = candidate?.reasons || [];
  const notes = candidate?.notes ?? [];
  const hasNotes = candidate?.hasNotes ?? false;
  const activityLog = candidate?.activityLog ?? [];
  const phone = candidate?.contact_info.phone;
  const email = candidate?.contact_info.email;
  const lastRefresh = candidate?.lastRefresh ?? '';
  const isNoLongerAvailable = candidate ? !candidate.isAvailable : false;
  const isAlreadyInteracted = candidate ? candidate.alreadyInteracted : false;
  const campaignId = candidate?.campaignId;

  const activeJobboard = candidate?.activeJobBoard;

  const lastContact: CandidateLastContacted | undefined = useMemo(() => {
    const { created_at } =
      candidate?.quickActionHistory
        .sort((a, b) => (a.created_at?.getTime() ?? 0) - (b.created_at?.getTime() ?? 0))
        .findLast(({ variant }) => variant === CANDIDATE_ACTIVITY_LOG_STATUS.CAMPAIGN_CANDIDATE_CONTACTED) ?? {};
    if (!created_at) return undefined;

    return { when: new Date(created_at) };
  }, [candidate?.quickActionHistory]);

  const verifyIfShouldCreateNewNote = useCallback(() => {
    if (!candidate) return Promise.resolve(false);

    return checkIfShouldCreateOrSaveANote({
      candidate,
      userId: currentUser.userId,
      full_name: `${currentUser.first_name} ${currentUser.last_name}`,
    });
  }, [candidate, currentUser]);

  return useMemo(
    () => ({
      candidateEsPersonId,
      candidateName,
      notObfuscatedPhone,
      notObfuscatedEmail,
      labels,
      notes,
      activityLog,
      phone,
      email,
      activeJobboard,
      lastContact,
      lastRefresh,
      isNoLongerAvailable,
      hasNotes,
      isAlreadyInteracted,
      campaignId,
      verifyIfShouldCreateNewNote,
    }),
    [
      candidateEsPersonId,
      candidateName,
      notObfuscatedPhone,
      notObfuscatedEmail,
      JSON.stringify(labels.sort()),
      JSON.stringify(notes.sort()),
      JSON.stringify(activityLog.sort()),
      phone,
      email,
      activeJobboard,
      lastContact,
      lastRefresh instanceof Date ? lastRefresh.valueOf() : lastRefresh,
      isNoLongerAvailable,
      isAlreadyInteracted,
      hasNotes,
      campaignId,
      verifyIfShouldCreateNewNote,
    ],
  );
};

type UsePropsHook = (candidate: Candidate | undefined) => {
  candidateEsPersonId: string;
  candidateName: string;
  campaignId: number | undefined;
  notObfuscatedPhone: string | undefined;
  notObfuscatedEmail: string | undefined;
  labels: string[];
  notes: CampaignCandidateSaveNotesResponse[];
  activityLog: CandidateActivityLog[];
  phone: string | undefined;
  email: string | undefined;
  activeJobboard: CampaignJobboard | undefined;
  lastContact: CandidateLastContacted | undefined;
  lastRefresh: Date | string;
  isNoLongerAvailable: boolean;
  isAlreadyInteracted: boolean;
  hasNotes: boolean;
  verifyIfShouldCreateNewNote: () => Promise<boolean>;
};

const useUserInfoMemoized = () => {
  const { currentUser: { id: userId = -1, first_name = '', last_name = '' } = {} } = useContext(UserContext);

  return useMemo(() => ({ userId, first_name, last_name }), [userId, first_name, last_name]);
};

import { CampaignCandidateSaveNotesResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateActivityHistory, HistoryStatus } from 'shared/contexts/CandidateContext/searchv3/types';

const mergeAndSortCandidateHistory: (
  notes: CampaignCandidateSaveNotesResponse[],
  activitiesLog: CandidateActivityHistory[],
) => CandidateActivityHistory[] = (notes, activitiesLog) => {
  const mergedHistories = [
    ...activitiesLog,
    ...notes.map(
      (note) =>
        ({
          by: note.created_by.full_name,
          type: HistoryStatus.Note,
          id: note.id,
          createdAt: note.created_at,
          content: { message: note.content },
        }) satisfies CandidateActivityHistory,
    ),
  ];

  return mergedHistories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
};

export default mergeAndSortCandidateHistory;

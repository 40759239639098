import { useQueryClient } from '@tanstack/react-query';
import {
  CampaignCandidateResponse,
  CampaignCandidateSaveNotesResponse,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateResponse, Source } from 'clients/CampaignsClient/CandidateResponse';
import { FilterOrder } from 'clients/types';
import { Candidate } from 'model';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { CampaignTab } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { setCandidates } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.useCandidates';
import { setFacets } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.useFacets';
import { setStats } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.useStats';
import { QueryKey } from 'types/query';
import { useContextSelector } from 'use-context-selector';
import generateLabelsCacheKey from '../../searchv3/generateLabelsCacheKey';

type SelectedCampaignKeyState = { id: number; tab: CampaignTab; page: number; order: FilterOrder; labelsKey: string };

export const useCandidateOrchestratorForCampaign = () => {
  const queryClient = useQueryClient();
  const selectedCampaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);
  const selectedTab = useContextSelector(SelectedCampaignContext, (state) => state.selectedTab);
  const selectedPage = useContextSelector(SelectedCampaignContext, (state) => state.selectedPage);
  const candidatesOrder = useContextSelector(SelectedCampaignContext, (state) => state.filters.order);
  const labels = useContextSelector(SelectedCampaignContext, (state) => state.filters.labels);
  const labelsKey = generateLabelsCacheKey(labels);
  const setSelectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.setSelectedCandidate);
  const currentListRef = useRef<[QueryKey.selectedCampaignContextUseCandidates, SelectedCampaignKeyState]>([
    QueryKey.selectedCampaignContextUseCandidates,
    {
      id: selectedCampaign?.id as number,
      tab: selectedTab,
      page: selectedPage,
      order: candidatesOrder,
      labelsKey: labelsKey,
    },
  ]);

  useEffect(() => {
    currentListRef.current[1] = {
      id: selectedCampaign?.id as number,
      tab: selectedTab,
      page: selectedPage,
      order: candidatesOrder,
      labelsKey: labelsKey,
    };
  }, [selectedCampaign, selectedTab, selectedPage, candidatesOrder]);

  /**
   * Base
   */

  const setCurrentList = useCallback(
    (cb: (currentData: Candidate[]) => Candidate[]) => {
      setCandidates(currentListRef.current[1], (data) => {
        return cb(data);
      });
    },
    [queryClient],
  );

  const decreaseJobBoardCount = useCallback((candidate: Candidate) => {
    setFacets(candidate.campaignId, (facets) => ({
      ...facets,
      [candidate.activeJobBoard]: facets[candidate.activeJobBoard] - 1,
    }));
  }, []);

  const decreaseStatCount = useCallback(
    (statKey: CampaignTab) =>
      setStats(selectedCampaign?.id, (stats) => {
        return { ...stats, [statKey]: stats[statKey] - 1 };
      }),
    [],
  );

  const increaseStatCount = useCallback(
    (statKey: CampaignTab) =>
      setStats(selectedCampaign?.id, (stats) => {
        return { ...stats, [statKey]: stats[statKey] + 1 };
      }),
    [],
  );

  const resetList = useCallback(
    (tab: CampaignTab) => {
      const { id, page } = currentListRef.current[1];
      queryClient.resetQueries([QueryKey.selectedCampaignContextUseCandidates, { id, page, tab }], { exact: false });
    },
    [queryClient],
  );

  const removeFromCurrentList = useCallback(
    (candidate: Candidate) => {
      setCurrentList((data) => {
        const index = data.findIndex((c) => c.es_person_id === candidate.es_person_id);

        if (index === -1) {
          console.warn(`${candidate.name} [${candidate.es_person_id}] was not found in current list`, {
            candidate: { ...candidate },
            currentList: currentListRef.current,
          });

          return data;
        }

        const newSelectedCandidateIndex = Math.min(index + 1, data.length - 2);
        setSelectedCandidate(data[newSelectedCandidateIndex]);

        const arr = Array.from(data);

        arr.splice(index, 1);

        return arr;
      });
    },
    [setCurrentList],
  );

  const invalidateStats = useCallback(
    () => queryClient.invalidateQueries([QueryKey.selectedCampaignContextUseStats, currentListRef.current[1].id]),
    [queryClient],
  );

  const invalidateCurrentListInactiveQueries = useCallback(() => {
    const [key, { id, order, page, tab, labelsKey }] = currentListRef.current;
    queryClient.removeQueries([key, { id, order, page, tab }], {
      exact: false,
      predicate: (query) => {
        const keys = query.queryKey[1] as SelectedCampaignKeyState;

        return labelsKey !== keys.labelsKey;
      },
    });
  }, []);

  const updateCurrentCandidate = useCallback(
    (candidate: Candidate, cb: (candidate: Candidate) => Candidate) => {
      setCurrentList((data) => {
        const index = data.findIndex((c) => c.es_person_id === candidate.es_person_id);

        if (index === -1) {
          console.warn(`${candidate.name} [${candidate.es_person_id}] was not found in current list`, {
            candidate: { ...candidate },
            currentList: currentListRef.current,
          });

          return data;
        }

        const arr = Array.from(data);
        const new_candidate = Candidate.clone(cb(arr[index]));

        arr.splice(index, 1, new_candidate);

        return arr;
      });
    },
    [setCurrentList],
  );

  const get = useCallback(
    () => ({
      status: () => currentListRef.current[1].tab,
      listCount: () => queryClient.getQueryData<{ data: []; total: number }>(currentListRef.current)?.data.length,
      filterLabels: () => currentListRef.current[1].labelsKey.split('-'),
    }),
    [queryClient],
  );

  /**
   * Implementations
   */

  const reset = useMemo(
    () => ({
      shortlistList: () => resetList(CampaignTab.Shortlisted),
      contactList: () => resetList(CampaignTab.Contacted),
      matchList: () => resetList(CampaignTab.Matched),
      hiddenList: () => resetList(CampaignTab.Hidden),
      searchResultsList: () => resetList(CampaignTab.New),
    }),
    [resetList],
  );

  const resetCurrentQuery = useCallback(async () => {
    await queryClient.resetQueries(currentListRef.current);
  }, []);

  const decreaseStatOf = useCallback(
    (candidate: Candidate) => ({
      currentJobBoard: () => decreaseJobBoardCount(candidate),
      currentList: () => decreaseStatCount(currentListRef.current[1].tab),
      list: (status: CampaignTab) => decreaseStatCount(status),
    }),
    [],
  );

  const increaseStatOf = useMemo(
    () => ({
      shortlistList: () => increaseStatCount(CampaignTab.Shortlisted),
      contactList: () => increaseStatCount(CampaignTab.Contacted),
      matchList: () => increaseStatCount(CampaignTab.Matched),
      hiddenList: () => increaseStatCount(CampaignTab.Hidden),
    }),
    [increaseStatCount],
  );

  const updateLabels = useCallback(
    (candidate: Candidate, cb: (labels: string[]) => string[]): string[] => {
      const labels = [] as string[];

      updateCurrentCandidate(candidate, (c) => {
        labels.push(...cb(c.reasons));
        return c.mergeLocally({ reasons: labels });
      });

      return labels;
    },
    [updateCurrentCandidate],
  );

  const update = useCallback(
    (candidate: Candidate) => ({
      source: (candidateSource: Partial<Source>) => {
        updateCurrentCandidate(candidate, (c) => c.mergeSource(candidateSource));
      },
      locally: (updatedCandidate: Partial<CampaignCandidateResponse>) => {
        updateCurrentCandidate(candidate, (c) => c.mergeLocally(updatedCandidate));
      },
      notes: (notes: CampaignCandidateSaveNotesResponse[]) => {
        updateCurrentCandidate(candidate, (c) => c.mergeNotesResponse(notes));
      },
      info: (candidateInfo: CandidateResponse) => {
        updateCurrentCandidate(candidate, (c) => c.setCandidateInfo(candidateInfo));
      },
      labels: {
        set: (labels: string | string[]) => updateLabels(candidate, () => (Array.isArray(labels) ? labels : [labels])),
        add: (labels: string | string[]) =>
          updateLabels(candidate, (cl) => Array.from(new Set([...(Array.isArray(labels) ? labels : [labels]), ...cl]))),
        remove: (labels: string | string[]) => updateLabels(candidate, (cl) => cl.filter((l) => !labels.includes(l))),
      },
    }),
    [updateCurrentCandidate, updateLabels],
  );

  const markAs = useCallback(
    (candidate: Candidate) => ({
      noLongerAvailable: () => {
        updateCurrentCandidate(candidate, (c) => {
          c.isAvailable = false;
          return c;
        });
      },
      active: () => {
        updateCurrentCandidate(candidate, (c) => {
          c.isAvailable = true;
          return c;
        });
      },
      alreadyInteracted: () => {
        updateCurrentCandidate(candidate, (c) => {
          c.name = 'helloooooooo';
          c.alreadyInteracted = true;
          return c;
        });
      },
    }),
    [updateCurrentCandidate],
  );

  const remove = useCallback(
    (candidate: Candidate) => ({
      fromCurrentList: () => removeFromCurrentList(candidate),
      currentJobBoard: () => updateCurrentCandidate(candidate, (c) => c.removeCandidateMatch(c.activeJobBoard)),
    }),
    [updateCurrentCandidate],
  );

  const load = useCallback(
    (candidate: Candidate) => ({
      mainJobBoardInfo: () => updateCurrentCandidate(candidate, (c) => c.loadMainJobboard()),
    }),
    [updateCurrentCandidate],
  );

  return useCallback(
    (candidate: Candidate) => ({
      invalidateStats,
      invalidateCurrentListInactiveQueries,
      reset,
      resetCurrentQuery,
      decreaseStatOf: decreaseStatOf(candidate),
      increaseStatOf,
      update: update(candidate),
      markAs: markAs(candidate),
      remove: remove(candidate),
      load: load(candidate),
      get: get(),
    }),
    [
      updateCurrentCandidate,
      removeFromCurrentList,
      invalidateStats,
      invalidateCurrentListInactiveQueries,
      reset,
      increaseStatOf,
      resetCurrentQuery,
    ],
  );
};

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignCandidateResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { queryClient } from 'config/queryClient';
import { isEmpty } from 'lodash';
import { Candidate } from 'model';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { usePath } from './SharedCandidateContext.usePath';

export const useCandidate = () => {
  const { campaignId, candidateEsPersonId } = usePath();

  const { data, isFetching } = useQuery(
    [QueryKey.sharedCandidateContextUseCandidate, { campaignId, candidateEsPersonId }],
    async () => {
      if (typeof campaignId !== 'number' || typeof candidateEsPersonId !== 'string')
        throw Error('CampaignId and candidateEsPersonId must be provided');

      let candidate: Partial<CampaignCandidateResponse | undefined> = undefined;
      let error: unknown;

      try {
        const { data: candidateFromCampaign } = await campaignsClient.getCampaignCandidate({
          campaignId: `${campaignId}`,
          candidateId: candidateEsPersonId,
        });

        candidate = candidateFromCampaign;
      } catch (e) {
        error = e;
      }

      if (!candidate || isEmpty(candidate.candidate)) {
        try {
          const {
            data: [candidateFromProfiles],
          } = await searchClient.getCandidateById(candidateEsPersonId);

          if (!candidateFromProfiles) throw error;

          candidate = !candidate
            ? { es_person_id: candidateFromProfiles._id, candidate: candidateFromProfiles }
            : { ...candidate, candidate: candidateFromProfiles };
        } catch {
          throw error;
        }
      }

      return new Candidate(campaignId, candidate as CampaignCandidateResponse);
    },
    { retry: 1, enabled: typeof campaignId === 'number' && typeof candidateEsPersonId === 'string' },
  );

  return useMemo(() => ({ candidate: data, isLoading: isFetching }), [data, isFetching]);
};

export const setCandidate = (
  { campaignId, candidateEsPersonId }: { campaignId: number | undefined; candidateEsPersonId: string | undefined },
  callback: (data: Candidate) => Candidate,
) => {
  queryClient.setQueryData<Candidate>(
    [QueryKey.sharedCandidateContextUseCandidate, { campaignId, candidateEsPersonId }],
    (data) => {
      if (!data) throw Error('Candidate is undefined');

      return callback(data);
    },
  );
};

import localforage from 'localforage';

type MemoStorage = string | number | object | boolean | undefined;

if (!window.rrMemoStorage) {
  window.rrMemoStorage = { config: { loaded: false }, state: {} };
}

const getValue = <T extends MemoStorage>(key: string): T => {
  return window.rrMemoStorage.state[key];
};

const setValue = <T extends MemoStorage>(key: string, value: T) => {
  window.rrMemoStorage.state[key] = value;
};

const loadInitialData = async () => {
  await localforage.iterate((value, key) => {
    setValue(key, value as MemoStorage);
  });

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key !== null) {
      const value = localStorage.getItem(key);

      if (value !== null) {
        setValue(key, getLocalStorageValue(key, undefined));
      }
    }
  }

  window.rrMemoStorage.config.loaded = true;
};

const getLocalStorageValue = <T extends string | number | object | boolean>(
  key: string,
  defaultValue: T | undefined,
) => {
  const localStorageJsonValue = localStorage.getItem(key) ?? '""';

  try {
    const localStorageValue = JSON.parse(localStorageJsonValue) as T;

    return localStorageValue === '' && typeof defaultValue !== 'undefined' ? defaultValue : localStorageValue;
  } catch (e) {
    if (e instanceof SyntaxError) {
      if (localStorageJsonValue === 'undefined') return undefined;

      const valueAsNumber = parseFloat(localStorageJsonValue);

      if (isNaN(valueAsNumber)) {
        return localStorageJsonValue;
      }

      return valueAsNumber;
    }

    return undefined;
  }
};

export const memoStorage = {
  getValue,
  setValue,
  isLoaded: window.rrMemoStorage.config.loaded,
  loadInitialData,
};

import { useQuery } from '@tanstack/react-query';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { outreachTemplateClient } from 'clients/OutreachTemplateclient/OutreachTemplateClient';
import { Outreach, OutreachTemplateType } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import { CONTENT_EDITABLE_CLASS_NAME, Column, Expand, Padding, Typography } from 'components/ui/atoms';
import { Send } from 'components/ui/atoms/icons';
import {
  Button,
  CandidateSelectOutreachTemplate,
  CandidateSelectOutreachTemplateOnSaveHandler,
  CandidateSelectOutreachTemplateOnSelectHandler,
  ContextMenuOption,
  DropdownButton,
  FieldLabelWrapper,
  InformationQuote,
  InformationTag,
  SingleSelect,
  TextArea,
  TextInput,
} from 'components/ui/molecules';
import { logger } from 'config/logger';
import { isAxiosError } from 'helpers/clientHelpers';
import { useLocalStorage } from 'hooks/shared';
import TimeAgo from 'javascript-time-ago';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import Snackbar from 'shared/components/Snackbar';
import { HistoryContactMethod } from 'shared/contexts/CandidateContext/searchv3/types';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { FiltersContext } from 'shared/contexts/SearchContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { StopExecution } from 'shared/exceptions';
import { BadRequestException } from 'shared/exceptions/BadRequestException';
import { IndeedBadUserInputException } from 'shared/exceptions/IndeedException';
import { IndeedGeneralException } from 'shared/exceptions/IndeedGeneralException';
import { IndeedNoCreditsAvailableException } from 'shared/exceptions/IndeedNoCreditsAvailableException';
import { LinkedinFreeInviteLimitReachedException } from 'shared/exceptions/LinkedinException';
import { useBrowserExtension } from 'shared/hooks/useBrowserExtension';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from './styles';
import { CandidateOutreachMessage, CandidateOutreachPersonalizationTokenKeys, CandidateOutreachProps } from './types';
import { useContainer } from './useContainer';

const LINKEDIN_MAX_LENGTH = 200;

const CandidateOutreach = ({
  candidateName,
  headerDescription,
  type,
  onSend,
  tokens,
  candidateActivityLog,
  lastContact,
  setShouldOpenReconnectEmailAccountModal = () => {},
}: CandidateOutreachProps) => {
  const classes = useStyles();
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const subjectWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const signatureWrapperRef = useRef<HTMLDivElement>(null);
  const { indeedProjects } = useContainer();
  const { linkedinCreditBalance, indeedCreditBalance } = useBrowserExtension();
  const { dictionary, language } = useContext(LocalizationContext);
  const { createSnackbar, closeSnackbar } = useContext(SnackbarContext);
  const [selectedTemplate, setSelectedTemplate] = useState<Outreach | null>(null);
  const { value: subject = '', setValue: setSubject } = useLocalStorage<string>(`candidate-outreach--subject-${type}`);
  const { value: content = '', setValue: setContent } = useLocalStorage<string>(`candidate-outreach--content-${type}`);
  const { value: signature = '', setValue: setSignature } = useLocalStorage<string>(
    `candidate-outreach--signature-${type}`,
  );
  const [selectedTemplateHasChanges, setSelectedTemplateHasChanges] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [lastInputFocused, setLastInputFocused] = useState<{
    anchorNode: Node;
    anchorOffset: number;
    anchorFinalOffset: number | null;
  } | null>(null);
  const shouldEnableSubject = ['linkedin_inmail', 'email', 'indeed'].includes(type);
  const shouldEnableContent = ['whatsapp', 'linkedin', 'linkedin_inmail', 'email', 'indeed'].includes(type);
  const shouldEnableSignature = ['email'].includes(type);
  const jobTitles = useContextSelector(FiltersContext, (state) => state.filters.job_titles);
  const { currentUser, hasLinkedinFreeInviteRemaining } = useContext(UserContext);
  const isLinkedinRunOutOfCredits = type === 'linkedin' && !hasLinkedinFreeInviteRemaining;
  const { data: emailStatus } = useQuery(
    [`${currentUser?.email}-oauth-nylas-status`],
    notificationsClient.connectedEmailAccountStatus,
    {
      retry: false,
    },
  );

  const statusEmailIsValid = useMemo(() => {
    return emailStatus?.status === 200 && emailStatus?.data?.status === 'valid';
  }, [emailStatus]);

  useEffect(() => {
    const [firstJobTitle] = jobTitles;

    if (type === 'indeed' && firstJobTitle) {
      const [firstLetter, ...rest] = firstJobTitle.key.replace(/\"/g, '');
      setSubject(`${firstLetter.toUpperCase()}${rest.join('')}`);
    }
  }, []);

  const personalizationTokenKeys: Array<{ key: CandidateOutreachPersonalizationTokenKeys; en: string; nl: string }> = [
    { key: 'firstName', en: 'firstName', nl: 'voornaam' },
    { key: 'lastName', en: 'lastName', nl: 'achternaam' },
    { key: 'fullName', en: 'fullName', nl: 'volledigeNaam' },
    { key: 'lastPosition', en: 'lastPosition', nl: 'laatsteErvaring' },
    { key: 'lastCompany', en: 'lastCompany', nl: 'laatsteBedrijf' },
    { key: 'recruiterFullName', en: 'recruiterFullName', nl: 'recruiterVolledigeNaam' },
    { key: 'NameJobboard', en: 'NameJobboard', nl: 'NaamJobboard' },
    { key: 'NameVacancy', en: 'NameVacancy', nl: 'NameVacature' },
    { key: 'Location', en: 'Location', nl: 'Locatie' },
  ];

  const personalizationTokens = personalizationTokenKeys.map(({ key, en, nl }) => ({
    key,
    label: `{${language === 'en' ? en : nl}}`,
  }));

  const sentMessage = useMemo(() => {
    const success: Record<Exclude<OutreachTemplateType, 'whatsapp' | 'werknl'>, string> = {
      email: dictionary.emailSuccessfullySent,
      indeed: dictionary.indeedSuccessfullySent,
      linkedin: dictionary.linkedinSuccessfullySent,
      linkedin_inmail: dictionary.linkedinInMailSuccessfullySent,
    };

    const failed: Record<Exclude<OutreachTemplateType, 'whatsapp' | 'werknl'>, string> = {
      email: dictionary.emailFailedSend,
      indeed: dictionary.indeedFailedSend,
      linkedin: dictionary.linkedinFailedSend,
      linkedin_inmail: dictionary.liknedinInMailFailedSend,
    };

    return { success, failed };
  }, [dictionary]);

  const timeAgo = useMemo(() => {
    return new TimeAgo(language === 'en' ? 'en-US' : 'nl-NL');
  }, [language]);

  const headerText = useMemo(() => {
    if (type === 'email') return dictionary.email;
    if (type === 'indeed') return dictionary.indeed;
    if (type === 'linkedin') return dictionary.linkedIn;
    if (type === 'linkedin_inmail') return dictionary.linkedInInMail;
    return dictionary.whatsApp;
  }, [dictionary, type]);

  const buttonText = useMemo(() => {
    if (type === 'email') return dictionary.sendEmail;
    if (type === 'indeed') return dictionary.sendWithIndeed;
    if (type === 'linkedin' && isLinkedinRunOutOfCredits) return dictionary.linkedinRunOutCreditsSendMessage;
    if (type === 'linkedin') return dictionary.sendLinkedInRequest;
    if (type === 'linkedin_inmail') return dictionary.sendInMail;
    return dictionary.sendWithWhatsAppWeb;
  }, [dictionary, type, isLinkedinRunOutOfCredits]);

  const isButtonDisabled = useMemo(() => {
    if (type === 'email') return !subject || !content || !signature;
    if (type === 'indeed') return !subject || !content || !indeedProjects.selected;
    if (type === 'linkedin') return !content;
    if (type === 'linkedin_inmail') return !subject || !content;
    return !content;
  }, [indeedProjects.selected, subject, content, signature, dictionary, type]);

  const responseNoticeText = useMemo(() => {
    if (type === 'email') return !statusEmailIsValid && dictionary.outreachResponseNoticeEmail;
    if (type === 'indeed') return dictionary.outreachResponseNoticeIndeed;
    if (type === 'linkedin') return dictionary.outreachResponseNoticeLinkedIn;
    if (type === 'linkedin_inmail') return dictionary.outreachResponseNoticeInMail;
    return dictionary.outreachResponseNoticeWhatsApp;
  }, [dictionary, type, statusEmailIsValid]);

  const platformCredits = useMemo(() => {
    if (type === 'indeed') return indeedCreditBalance;
    if (type === 'linkedin_inmail') return linkedinCreditBalance;
    return undefined;
  }, [linkedinCreditBalance, indeedCreditBalance, type]);

  const linkedinMaxLengthReached = ['linkedin'].includes(type) && content.length > LINKEDIN_MAX_LENGTH;

  useEffect(() => {
    if (isSending) {
      const snackbarId = createSnackbar('Sending', { variant: 'loading', persist: true });

      return () => closeSnackbar(snackbarId);
    }
  }, [isSending]);

  useEffect(() => {
    const inputRef = inputWrapperRef.current;

    if (inputRef) {
      const selectionChangeListener = () => {
        const selection = window.getSelection();

        if (
          selection &&
          selection.anchorNode &&
          (selection.anchorNode.parentElement?.className.includes(CONTENT_EDITABLE_CLASS_NAME) ||
            selection.anchorNode.nodeName === 'DIV') &&
          inputRef.contains(selection.anchorNode)
        ) {
          setLastInputFocused({
            anchorNode: selection.anchorNode,
            anchorOffset: selection.anchorOffset,
            anchorFinalOffset: selection.anchorNode === selection.focusNode ? selection.focusOffset : null,
          });
        }
      };

      document.addEventListener('selectionchange', selectionChangeListener);

      return () => {
        document.removeEventListener('selectionchange', selectionChangeListener);
      };
    }
  }, []);

  useDebounce(
    () =>
      setSelectedTemplateHasChanges(
        selectedTemplate !== null &&
          (selectedTemplate?.subject !== subject ||
            selectedTemplate?.message !== content ||
            selectedTemplate.sign_off !== signature),
      ),
    16,
    [subject, content, signature, selectedTemplate],
  );

  const handleSaveTemplate: CandidateSelectOutreachTemplateOnSaveHandler = async ({ templateName, createNew }) => {
    if (selectedTemplate && !createNew) {
      const { data } = await outreachTemplateClient.updateOutreachTemplate({
        id: selectedTemplate.id,
        type,
        name: templateName,
        subject,
        message: content,
        sign_off: signature,
      });

      setSelectedTemplate(data);

      return data;
    }
    const { data } = await outreachTemplateClient.createOutreachTemplate({
      type,
      name: templateName,
      subject,
      message: content,
      sign_off: signature,
    });

    setSelectedTemplate(data);

    return data;
  };

  const handleSelectTemplate: CandidateSelectOutreachTemplateOnSelectHandler = (opt) => {
    setSelectedTemplate(opt.id === -1 ? null : opt);
    setSubject(opt.subject ?? '');
    setContent(opt.message ?? '');
    setSignature(opt.sign_off ?? '');
  };

  const handleSelectToken = (opt: ContextMenuOption) => {
    if (lastInputFocused && typeof lastInputFocused.anchorNode.textContent === 'string' && opt.label) {
      const [inputRef, setter] =
        (subjectWrapperRef.current?.contains(lastInputFocused.anchorNode) && [
          subjectWrapperRef.current.querySelector(`.${CONTENT_EDITABLE_CLASS_NAME}`),
          setSubject,
        ]) ||
        (contentWrapperRef.current?.contains(lastInputFocused.anchorNode) && [
          contentWrapperRef.current.querySelector(`.${CONTENT_EDITABLE_CLASS_NAME}`) ??
            contentWrapperRef.current.querySelector('textarea'),
          setContent,
        ]) ||
        (signatureWrapperRef.current?.contains(lastInputFocused.anchorNode) && [
          signatureWrapperRef.current.querySelector(`.${CONTENT_EDITABLE_CLASS_NAME}`),
          setSignature,
        ]) ||
        [];

      if (inputRef?.tagName === 'TEXTAREA' && setter) {
        const inputValue = (inputRef as HTMLTextAreaElement).value;
        const anchorOffset = (inputRef as HTMLTextAreaElement).selectionStart;
        const anchorFinalOffset = (inputRef as HTMLTextAreaElement).selectionEnd;

        setter(
          inputValue.substring(0, anchorOffset) + opt.label + inputValue.substring(anchorFinalOffset || anchorOffset),
        );
      } else if (inputRef && setter) {
        if (lastInputFocused.anchorNode.nodeName === '#text') {
          lastInputFocused.anchorNode.textContent =
            lastInputFocused.anchorNode.textContent.substring(0, lastInputFocused.anchorOffset) +
            opt.label +
            lastInputFocused.anchorNode.textContent.substring(
              lastInputFocused.anchorFinalOffset || lastInputFocused.anchorOffset,
            );
        } else if (lastInputFocused.anchorNode.nodeName === 'DIV') {
          lastInputFocused.anchorNode.textContent = opt.label;
        }

        setter(inputRef.innerHTML as string);
      }
    }
  };

  const todayDate = new Date();
  function showJobboardRestrictionMessage(contactedDate: Date, platform: string) {
    const diffInMilliseconds = contactedDate.getTime() - todayDate.getTime();
    const DAY_IN_MILLISECONDS = 1000 * 3600 * 24;
    const diffInDays = Math.round(diffInMilliseconds / DAY_IN_MILLISECONDS);

    setIsSending(false);
    createSnackbar(dictionary.jobboardRestrictionMessage(diffInDays, platform), { variant: 'danger' });
  }

  const sendMessage = async ({ subject, content, signature }: CandidateOutreachMessage = {}) => {
    setIsSending(true);

    try {
      await onSend({
        ...(subject && { subject }),
        ...(content && { content }),
        ...(signature && { signature }),
        extras: { indeedProjectId: indeedProjects.selected?.key },
      });

      if (type !== 'whatsapp' && type !== 'werknl') {
        createSnackbar(sentMessage.success[type]);
      }
    } catch (e) {
      if (e instanceof BadRequestException) {
        setShouldOpenReconnectEmailAccountModal(true);
        return;
      }

      if (e instanceof StopExecution || type === 'whatsapp' || type === 'werknl') return;

      if (e instanceof LinkedinFreeInviteLimitReachedException) {
        const snackbarId = createSnackbar(
          dictionary.linkedinRunOutCreditsSendingMessage({
            onClick: () => {
              closeSnackbar(snackbarId);
              sendMessage();
            },
          }),
          {
            variant: 'warning',
            persist: true,
          },
        );

        return;
      }

      if (
        e instanceof IndeedGeneralException ||
        e instanceof IndeedNoCreditsAvailableException ||
        e instanceof IndeedBadUserInputException ||
        isAxiosError(e)
      ) {
        logger.error(e);
        createSnackbar(e.message, { variant: 'danger' });

        return;
      }

      logger.error(e);
      createSnackbar(sentMessage.failed[type], { variant: 'danger' });
    } finally {
      setIsSending(false);
    }
  };

  const handleSend = async () => {
    const hasContactedByIndeedBefore = candidateActivityLog?.find(
      (activityLog) => activityLog.contactSendMethod === HistoryContactMethod.indeed,
    );

    if (type === 'indeed' && hasContactedByIndeedBefore) {
      const contactedIndeedDate = hasContactedByIndeedBefore.createdAt;
      const contactedIndeedDatePlusThirtyDays = new Date(
        contactedIndeedDate.setDate(contactedIndeedDate.getDate() + 30),
      );

      if (todayDate < contactedIndeedDatePlusThirtyDays) {
        showJobboardRestrictionMessage(contactedIndeedDatePlusThirtyDays, 'Indeed');
        return;
      }
    }

    const hasContactedByLinkedinBefore = candidateActivityLog?.find(
      (activityLog) =>
        activityLog.contactSendMethod === HistoryContactMethod.linkedin && activityLog.byId === currentUser?.id,
    );

    if (type === 'linkedin' && hasContactedByLinkedinBefore) {
      const contactedLinkedinDate = hasContactedByLinkedinBefore.createdAt;
      const contactedLinkedinDatePlusThreeWeeks = new Date(
        contactedLinkedinDate.setDate(contactedLinkedinDate.getDate() + 30),
      );

      if (todayDate < contactedLinkedinDatePlusThreeWeeks) {
        showJobboardRestrictionMessage(contactedLinkedinDatePlusThreeWeeks, 'Linkedin');
        return;
      }
    }

    const replaceWithTokens = (text: string) => {
      return personalizationTokenKeys.reduce((t, { key, en, nl }) => {
        return t.replace(new RegExp(`(\{${en}\})|(\{${nl}\})`, 'g'), tokens[key]);
      }, text);
    };

    sendMessage({
      subject: shouldEnableSubject ? replaceWithTokens(subject) : undefined,
      content: shouldEnableContent ? replaceWithTokens(content) : undefined,
      signature: shouldEnableSignature ? replaceWithTokens(signature) : undefined,
    });
  };

  return (
    <>
      <Column css={classes.root} gap={24}>
        <Column>
          <Column gap={4}>
            <Typography variant="header.h2" color={(c) => c.monochrome[100]}>
              {dictionary.newOutreach}
            </Typography>
            <Typography variant="header.h4">
              <Column gap={0}>
                <span>
                  {headerText} {candidateName} <Expand width={8} />
                  {typeof platformCredits === 'number' && (
                    <InformationTag size="small" type="subtle" label={`${platformCredits} ${dictionary.credits}`} />
                  )}
                </span>
                {headerDescription && <span>{headerDescription}</span>}
              </Column>
            </Typography>
          </Column>

          {lastContact && (
            <InformationQuote
              icon="MessageCircle"
              message={dictionary.lastContactedBy({ when: timeAgo.format(lastContact.when).toString() })}
            />
          )}

          {isLinkedinRunOutOfCredits && <Snackbar label={dictionary.linkedinRunOutCreditsMessage} variant="warning" />}
        </Column>

        <Column css={classes.contentArea} gap={24}>
          <Column gap={8}>
            <CandidateSelectOutreachTemplate
              type={type}
              onSave={handleSaveTemplate}
              onSelect={handleSelectTemplate}
              selectedTemplateHasChanges={selectedTemplateHasChanges}
              contained
            />

            {['indeed'].includes(type) && (
              <FieldLabelWrapper label={dictionary.selectAProject}>
                <SingleSelect
                  text={indeedProjects.text}
                  options={indeedProjects.options}
                  onBlur={indeedProjects.handleBlur}
                  onFocus={indeedProjects.handleFocus}
                  onChange={indeedProjects.handleChange}
                  onTextChange={indeedProjects.handleChangeText}
                  noOptionsDefaultRender={
                    <Padding padding={[6, 16]}>
                      <Typography variant="body.short">{dictionary.noDataAvailable}</Typography>
                    </Padding>
                  }
                  placeholder={dictionary.chooseAnOption}
                  contained
                  autocomplete
                />
              </FieldLabelWrapper>
            )}
          </Column>

          <Column ref={inputWrapperRef} gap={8}>
            {shouldEnableSubject && (
              <FieldLabelWrapper
                ref={subjectWrapperRef}
                label={type === 'indeed' ? dictionary.jobTitle : dictionary.subject}>
                <TextInput text={subject} onChange={setSubject} />
              </FieldLabelWrapper>
            )}

            {shouldEnableContent && (
              <FieldLabelWrapper
                ref={contentWrapperRef}
                label={dictionary.content}
                disabled={isLinkedinRunOutOfCredits}>
                <TextArea
                  css={classes.contentInput}
                  text={content}
                  onChange={setContent}
                  allowHtml={['email'].includes(type)}
                  maxLength={['linkedin'].includes(type) ? LINKEDIN_MAX_LENGTH : undefined}
                  errorLabel={linkedinMaxLengthReached ? dictionary.lengthLimitReached : undefined}
                  disabled={isLinkedinRunOutOfCredits}
                />
              </FieldLabelWrapper>
            )}

            {shouldEnableSignature && (
              <FieldLabelWrapper ref={signatureWrapperRef} label={dictionary.signature}>
                <TextArea
                  text={signature}
                  onChange={setSignature}
                  allowHtml={['email'].includes(type)}
                  hintLabel={dictionary.signatureImageHelper}
                />
              </FieldLabelWrapper>
            )}
          </Column>

          <DropdownButton
            menuOptions={personalizationTokens}
            onSelectOption={handleSelectToken}
            variant="subtle"
            icon="Plus"
            label={dictionary.templateTokens}
          />
        </Column>

        <Padding css={classes.sendButton} contained>
          <Column css={classes.contentArea} gap={16} contained>
            <InformationQuote message={responseNoticeText} />

            <Button
              icon={<Send />}
              variant="highlight"
              label={buttonText}
              onClick={() => handleSend()}
              loading={isSending}
              disabled={isButtonDisabled || linkedinMaxLengthReached}
              contained
            />
          </Column>
        </Padding>
      </Column>
    </>
  );
};

export default CandidateOutreach;

import { CandidateActivityLogStatus } from 'services/candidates';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';

export interface CandidateResponse {
  _id: string;
  _type: string;
  found: boolean;
  _index: string;
  _seq_no: number;
  _source: Source;
  _ignored?: string[];
  _version: number;
  _primary_term: number;
  last_matched_date?: string;
  actions?: CandidateLatestAction[];
  has_note?: boolean;
  last_action_made_by_user?: {
    first_name: string;
    last_name: string;
    id: number;
  };
}

export interface Source {
  id: number | string;
  meta: Meta;
  name?: string;
  source: CampaignJobboard;
  headline?: string;
  industry?: string;
  industries?: string[];
  location: Location;
  username?: string;
  isVisible?: boolean;
  person_id?: string;
  sourceMeta?: SourceMeta;
  crawlerMeta?: CrawlerMeta;
  experiences: Experiences;
  contact_info?: ContactInfo;
  linkedin_uid?: string;
  past_job_titles: string;
  current_job_title: string;
  recent_job_titles: string;
  openToNewOpportunities?: boolean;
  insights?: Insight[];
  description?: null | string;
  last_insight_date?: string;
  new_candidate_date?: string;
  gender?: string;
  interests?: any[];
  dateOfBirth?: string;
  preferences?: Preferences;
  cvFileId?: string;
  licenses?: License[];

  /**
   * This not come from backend, it is a property added on frontend to manipulate data in legacy areas
   */
  campaignCandidate?: {
    reasons: string[];
    status: string;
    was_contact_info_fetched?: boolean;
    was_added_to_ats?: boolean;
    es_person_id: string;
  };
}

export type ContactInfo = Partial<Record<CampaignJobboard, string[]>> & {
  email?: string[];
  phone?: Array<string | number>;
};

export interface CrawlerMeta {
  updatedAt: string;
}

export interface Experiences {
  jobs?: Job[];
  honors?: any[];
  skills?: Skill[];
  languages?: Language[];
  volunteer?: Volunteer[];
  educations?: Education[];
  relocations?: any[];
  certifications?: Certification[];
  courses?: Course[];
}

export interface Course {
  name: string;
}

export interface Certification {
  name: string;
  dates: CertificationDates;
}

export interface CertificationDates {
  end?: End;
  start?: End;
}

export interface End {
  year: number;
  month?: number;
}

export interface Education {
  dates: EducationDates;
  degree?: string;
  school?: Skill;
  field?: string;
  description?: string;
  obtainedDegree?: boolean;
}

export interface EducationDates {
  end?: End;
  start: End;
  end_recalculated?: number;
  start_recalculated: number;
}

export interface Skill {
  name: string;
}

export interface Job {
  role: string;
  dates: EducationDates;
  company?: Skill;
  description?: string;
  employment_hours?: number;
}

export interface Language {
  name: string;
  proficiency?: string;
  proficiencyWritten?: string;
}

export enum LicenseType {
  DRIVING_LICENSE_NAME = 'driving_license_name',
  DRIVING_LICENSE_CODE = 'driving_license_code',
}

export type License = {
  name: string;
  type?: LicenseType | string;
};

export interface Volunteer {
  dates: EducationDates;
  description: string;
  role: string;
  company: {
    name: string;
  };
}

export interface Location {
  raw?: string;
  city?: City;
  exists?: boolean;
  region?: Region;
  address?: string;
  place_id?: number;
  aggregate?: Array<null | string>;
  coordinates?: Coordinates;
  postal_code?: string;
}

export interface City {
  long_name: string;
  short_name: string;
}

export interface Coordinates {
  lat: number | string;
  lon: number | string;
}

export interface Region {
  level1: City;
  level2: City;
}

export interface Meta {
  experience: Experience;
}

export interface Experience {
  academic: Academic;
  keywords: any[];
  volunteer?: Academic;
  professional: Academic;
}

export interface Academic {
  gap: number;
  last?: number;
  first: number;
}

export enum EmploymentType {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  INTERNSHIP = 'internship',
  FREELANCE = 'freelance',
  SHORT_TERM = 'short_term',
  SEASONAL = 'seasonal',
}

export enum Availability {
  IMMEDIATE = 'immediate',
}

export interface Preferences {
  jobTitles?: string[];
  workHours?: WorkHours;
  employmentType?: EmploymentType | EmploymentType[];
  commuteDistance?: CommuteDistance;
  salaryRange?: SalaryRange;
  relocation?: boolean;
  availability?: Availability | Date;
  locations?: string[];
}

export interface SalaryRange {
  min: number;
  max: number;
  currency: string;
}

export interface CommuteDistance {
  km: number;
}

export interface WorkHours {
  max: number;
  min: number;
}

export interface SourceMeta {
  changedAt: string;
  updatedAt: string;
}

export interface Insight {
  _id: string;
  candidateId: string;
  createdAt: Date;
  platform: string;
  type: InsightType;
  oldValue: any;
  newValue: any;
}
export type InsightType =
  | 'educations.added'
  | 'educations_dates_start.changed'
  | 'educations_dates_end.changed'
  | 'educations_obtainedDegree.changed'
  | 'jobs.added'
  | 'jobs_role.changed'
  | 'jobs_description.changed'
  | 'jobs_dates_start.changed'
  | 'jobs_dates_end.changed'
  | 'skills.added'
  | 'preferences_employmentType.changed'
  | 'preferences_workHours.changed'
  | 'preferences_availability.changed'
  | 'location_raw.changed'
  | 'contact_info_phone.added'
  | 'contact_info_phone.changed'
  | 'contact_info_email.added'
  | 'contact_info_email.changed'
  | 'candidate.added';
export type CandidateLatestAction = {
  action: CandidateActivityLogStatus;
  created_at: Date;
};

import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignsClient.types';
import { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import {
  getCampaignStatus,
  getShouldEnableTeamview,
  getShowCampaignOverviewButton,
  getShowNavbar,
} from './CandidateContext.utils';
import { useBrowserExtensionSyncResize } from './ConfigContext.useBrowserExtensionSyncResize';
import { ConfigContextProps, ConfigContextProviderProps } from './types';

const initialData: ConfigContextProps = {
  isIframe: false,
  campaign: {
    teamview: false,
    status: CampaignOverviewStatus.active,
  },
  showNavbar: false,
  showCampaignOverviewButton: false,
};

export const ConfigContext = createContext<ConfigContextProps>(initialData);

export const ConfigContextProvider = ({ children }: ConfigContextProviderProps) => {
  const [isIframe, setIsIframe] = useState(initialData.isIframe);
  const [teamview, setTeamview] = useState(initialData.campaign.teamview);
  const [status, setStatus] = useState<CampaignOverviewStatus>(initialData.campaign.status);
  const [showNavbar, setShowNavbar] = useState(initialData.showNavbar);
  const [showCampaignOverviewButton, setShowCampaignOverviewButton] = useState(initialData.showCampaignOverviewButton);

  useBrowserExtensionSyncResize({ enabled: isIframe });

  useEffect(() => {
    const isIframe = window.top !== window.self;
    const campaignTeamView = getShouldEnableTeamview();
    const campaignStatus = getCampaignStatus();
    const shouldShowNavbar = getShowNavbar();
    const shouldShowCampaignOverviewButton = getShowCampaignOverviewButton();

    if (isIframe) {
      setIsIframe(true);
      setTeamview(campaignTeamView);
      setShowNavbar(shouldShowNavbar);
      setStatus(campaignStatus);
      setShowCampaignOverviewButton(shouldShowCampaignOverviewButton);
    }
  }, []);

  const value: ConfigContextProps = {
    isIframe,
    campaign: { teamview, status },
    showNavbar,
    showCampaignOverviewButton,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

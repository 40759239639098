import { cx } from '@emotion/css';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import {
  Chip as MuiChip,
  Divider as MuiDivider,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@material-ui/core';
import { useStyles } from './SelectChip.styles';
import { CheckV2, EditV2, Minus, MoreVertical, X } from 'shared/icons';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import KeypressClassToggler from 'helpers/keypressClassToggler';
import {
  GenerateMenuItemType,
  MenuCloseReason,
  SelectChipItemMenuVariants,
  SelectChipProps,
  SelectChipRef,
} from './types';
import Transition from '../Transition';
import { Sparkle } from 'components/ui/atoms/icons';

const generateMenuItem: GenerateMenuItemType = ({ handleCloseMenu, menuItemCss }) => {
  const variantClasses: { [key in SelectChipItemMenuVariants]: string } = {
    'must-have': 'menu-item--must-have',
    exclude: 'menu-item--exclude',
    subtle: 'menu-item--subtle',
    default: '',
  };

  return function generateMenuItem({ label, icon: Icon, value, variant, danger = false, selected = false }) {
    const menuItemRef = useRef<HTMLElement | null>(null);

    const handleClick = () => {
      handleCloseMenu(value);
    };

    useEffect(() => {
      const onClickManual = () => (menuItemRef.current as HTMLElement).click();

      const chipKeypressHandler = new KeypressClassToggler(menuItemRef.current as HTMLElement, {
        Space: { onKeyUp: onClickManual },
      }).init();

      return () => {
        chipKeypressHandler.dispose();
      };
    }, []);

    return (
      <MuiMenuItem
        innerRef={menuItemRef}
        onClick={handleClick}
        css={menuItemCss}
        className={cx(
          variant && variantClasses[variant],
          selected && 'menu-item--selected',
          danger && 'menu-item--danger',
        )}
        disableRipple>
        <Icon size={16} />
        <span>{label}</span>
      </MuiMenuItem>
    );
  };
};

const SelectChip = forwardRef<SelectChipRef, SelectChipProps>(
  (
    {
      label,
      onChange,
      onClick,
      customChip = false,
      variant = 'default',
      type = 'default',
      chipMenuOptions = ['must-have', 'exclude', 'remove'],
      ...props
    },
    ref,
  ) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [iconWrapperHover, setIconWrapperHover] = useState(false);
    const classes = useStyles({ iconWrapperHover: iconWrapperHover || menuOpen, variant, type, customChip });
    const chipRef = useRef<HTMLElement | null>(null);
    const iconWrapperRef = useRef<HTMLElement | null>(null);
    const [selectChipId, setSelectChipId] = useState('');
    const { dictionary } = useContext(LocalizationContext);
    const selectChipIdRef = useRef(selectChipId);
    const menuOpenRef = useRef(menuOpen);
    const onCloseRef = useRef<(chipId: string) => void>();
    const handleCloseRef = useRef(() => handleCloseMenu('click-away'));

    const isDefaultType = customChip ? true : type === 'default';
    const isMenuType = customChip ? false : type === 'menu';

    useEffect(() => {
      selectChipIdRef.current = selectChipId;
    }, [selectChipId]);

    useEffect(() => {
      if (typeof ref === 'function') {
        ref({
          close: handleCloseRef.current,
          assinOnCloseCallback: (onClose) => {
            onCloseRef.current = onClose;
          },
          selectChipId,
        });
      }
    }, [ref, selectChipId]);

    useEffect(() => {
      const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
      setSelectChipId(`select-chip-${id}`);
    }, []);

    const handleOpenMenu = () => {
      if (isDefaultType) {
        onChange && onChange('remove');

        setTagFocusByIndex();
      } else if (isMenuType) {
        setMenuOpen(true);

        onCloseRef.current?.(selectChipId);
      }
    };

    const handleCloseMenu = (reason: MenuCloseReason) => {
      isMenuType && setMenuOpen(false);

      setTagFocusByIndex();

      if (!['click-away', 'escape'].includes(reason) && onChange) {
        onChange(reason);
      }
    };

    useEffect(() => {
      menuOpenRef.current = menuOpen;

      handleCloseRef.current = () => {
        if (menuOpenRef.current) {
          handleCloseMenu('click-away');
        }
      };
    }, [handleCloseMenu, menuOpen]);

    useEffect(() => {
      const onDeleteManual = () => (customChip ? onClick?.() : (iconWrapperRef.current as HTMLElement).click());

      const chipKeypressHandler = new KeypressClassToggler(chipRef.current as HTMLElement, {
        Enter: { onKeyDown: onDeleteManual },
        Space: { onKeyDown: onDeleteManual },
      }).init();

      return () => {
        chipKeypressHandler.dispose();
      };
    }, [customChip, onClick]);

    useEffect(() => {
      if (menuOpen) {
        const chipKeypressHandler = new KeypressClassToggler(document.body, {
          Tab: { onKeyDown: () => handleCloseMenu('click-away') },
          Escape: { onKeyDown: () => handleCloseMenu('escape'), stopPropagation: true },
          ArrowLeft: { stopPropagation: true },
          ArrowRight: { stopPropagation: true },
        }).init();

        return () => {
          chipKeypressHandler.dispose();
        };
      }
    }, [menuOpen]);

    const handleMouseEnterIconWrapper = () => {
      setIconWrapperHover(true);
    };

    const handleMouseLeaveIconWrapper = () => {
      setIconWrapperHover(false);
    };

    const setTagFocusByIndex = () => {
      if (!chipRef.current) return;
      const inputParent = (chipRef.current.parentElement as HTMLElement).parentElement as HTMLElement;

      inputParent.focus();
    };

    const MenuItem = generateMenuItem({ handleCloseMenu, menuItemCss: classes.menuItem! });

    return (
      <span id={selectChipId} style={{ maxWidth: '100%' }}>
        <MuiChip
          {...props}
          component="div"
          innerRef={chipRef}
          css={classes.root}
          onDelete={handleOpenMenu}
          label={label}
          onFocus={handleMouseEnterIconWrapper}
          onBlur={handleMouseLeaveIconWrapper}
          icon={customChip ? <Sparkle size={16} /> : undefined}
          onClick={customChip ? onClick : undefined}
          deleteIcon={
            <span
              ref={iconWrapperRef}
              css={classes.iconWrapper}
              className="select-chip--icon-wrapper"
              onMouseEnter={handleMouseEnterIconWrapper}
              onMouseLeave={handleMouseLeaveIconWrapper}>
              {isDefaultType && <X size={15} />}
              {isMenuType && <MoreVertical size={15} />}
            </span>
          }
        />
        <MuiPopper
          css={classes.popper}
          className="select-chip--popper"
          open={menuOpen}
          anchorEl={iconWrapperRef.current}
          role={undefined}
          style={{ zIndex: 9 }}
          transition
          disablePortal>
          <Transition css={classes.popperTransition} pattern="fadeGrow" visible>
            <MuiPaper css={classes.menuWrapper}>
              <MuiMenuList autoFocus={menuOpen}>
                {chipMenuOptions.includes('edit') && <MenuItem label={dictionary.edit} icon={EditV2} value="edit" />}
                {chipMenuOptions.includes('must-have') && (
                  <MenuItem
                    label={dictionary.mustHave}
                    icon={CheckV2}
                    value="must-have"
                    variant={'must-have'}
                    selected={variant === 'must-have'}
                  />
                )}
                {chipMenuOptions.includes('exclude') && (
                  <MenuItem
                    label={dictionary.exclude}
                    icon={Minus}
                    value="exclude"
                    variant="exclude"
                    selected={variant === 'exclude'}
                  />
                )}
                {chipMenuOptions.length > 1 && chipMenuOptions.includes('remove') && <MuiDivider />}
                {chipMenuOptions.includes('remove') && (
                  <MenuItem label={dictionary.remove} icon={X} value="remove" danger />
                )}
              </MuiMenuList>
            </MuiPaper>
          </Transition>
        </MuiPopper>
      </span>
    );
  },
);

export default SelectChip;

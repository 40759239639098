import { Typography } from 'components/ui/atoms';
import { RobinSkeleton } from 'components/ui/molecules/RobinSkeleton';
import { AnimatePresence } from 'framer-motion';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { memo, useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './CandidateCVPdf.styles';

const options = { cMapUrl: '/cmaps/', standardFontDataUrl: '/standard_fonts/' };

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export const CandidateCVPdf = memo(({ cvFile }: { cvFile: Blob }) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [numPages, setNumPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onDocumentLoadError = (e: Error) => {
    console.error(e);
    setIsLoading(false);
  };

  return (
    <div css={classes.root}>
      <AnimatePresence>
        {isLoading && <RobinSkeleton css={classes.skeleton} loadingMessage={dictionary.loadingCandidateCv} />}
      </AnimatePresence>

      <div css={classes.pdfWrapper}>
        <ReactVirtualizedAutoSizer>
          {({ width }) => (
            <Document
              file={cvFile}
              noData={dictionary.noDataAvailable}
              error={<Typography variant="body.long">{dictionary.somethingWentWrong}</Typography>}
              onLoadError={onDocumentLoadError}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<RobinSkeleton css={classes.skeleton} />}
              renderMode="canvas"
              options={options}>
              {Array.from(new Array(numPages)).map((_, i) => (
                <Page
                  {...{ canvasBackground: 'white' }}
                  key={i}
                  pageNumber={i + 1}
                  width={width}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          )}
        </ReactVirtualizedAutoSizer>
      </div>
    </div>
  );
});

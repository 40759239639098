import { Bell_01 } from '@recruitrobin/robin-theme/web-icons';
import { Badge, Row } from 'components/ui/atoms';
import { routes } from 'config';
import { useGTM } from 'hooks/gtm';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationsContext } from 'shared/contexts/NotificationsContext/NotificationsContext';
import { useStyles } from './NotificationsBell.styles';

export const NotificationsBell = () => {
  const { newNotificationsCount } = useContext(NotificationsContext);

  const classes = useStyles();
  const navigate = useNavigate();
  const gtm = useGTM();

  const onNavigateToNotification = useCallback(() => {
    gtm.clickedNotificationBellIcon();
    navigate(routes.notifications);
  }, []);

  return (
    <div css={classes.root}>
      <Row alignCenter onClick={onNavigateToNotification} data-tutorial-id="notifications-bell" css={classes.content}>
        <Badge
          label={newNotificationsCount}
          showLabel={newNotificationsCount > 0}
          variant="error"
          alignment="top right">
          <Bell_01 color={(c) => c.neutral.variant[70]} size={18} />
        </Badge>
      </Row>
    </div>
  );
};

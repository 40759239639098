import { useCallback, useMemo, useState } from 'react';
import { SelectedCandidateContextProvider } from 'shared/contexts/SelectedCandidateContext';
import { createContext, useContextSelector } from 'use-context-selector';
import { CampaignManagerContext } from '../CampaignManagerContext';
import { CandidateDetailsTab } from '../CandidateContext/searchv3/types';
import { initialState as searchv3InitialState } from '../CandidateContext/searchv3/useSearchV3Candidate';
import { LoadedCvsContextProvider } from '../LoadedCvsContext';
import { CampaignTab, SelectedCampaignContextProps, SelectedCampaignState } from './SelectedCampaignContext.types';
import { useCandidateMatches } from './SelectedCampaignContext.useCandidateMatches';
import { useCandidates } from './SelectedCampaignContext.useCandidates';
import { initialFacetsState, useFacets } from './SelectedCampaignContext.useFacets';
import { initialFiltersState, useFilters } from './SelectedCampaignContext.useFilters';
import { useSelectedCandidate } from './SelectedCampaignContext.useSelectedCandidate';
import { initialStatsState, useStats } from './SelectedCampaignContext.useStats';

const initialState: SelectedCampaignState = {
  campaign: undefined,
  filters: initialFiltersState,
  candidates: { data: [], isLoading: true, total: 0 },
  matches: { data: {}, isLoading: true },
  stats: { data: initialStatsState, isLoading: true },
  facets: { data: initialFacetsState, isLoading: true },
  selectedTab: CampaignTab.New,
  setSelectedTab: () => {
    throw Error('not implemented');
  },
  selectedCandidate: undefined,
  setSelectedCandidate: () => {
    throw Error('not implemented');
  },
  selectedCandidateConfig: {
    candidateDetails: { initialTab: CandidateDetailsTab.CV },
  },
  setSelectedCandidateConfig: () => {
    throw Error('not implemented');
  },
  selectedPage: 1,
  setSelectedPage: () => {
    throw Error('not implemented');
  },
};

export const SelectedCampaignContext = createContext(initialState);

export const SelectedCampaignContextProvider = ({ children }: SelectedCampaignContextProps) => {
  const [selectedCandidateConfig, setSelectedCandidateConfig] = useState(initialState.selectedCandidateConfig);

  const [selectedTab, setSelectedTab] = useState(CampaignTab.New);
  const [selectedPage, setSelectedPage] = useState(1);
  const selectedCampaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);

  const filters = useFilters();
  const {
    candidates,
    isLoading: isCandidatesLoading,
    total,
  } = useCandidates({
    filters,
    page: selectedPage,
    tab: selectedTab,
    campaignId: selectedCampaign?.id,
  });
  const { matches, isLoading: isMatchesLoading } = useCandidateMatches({ candidates, tab: selectedTab });
  const { stats, isLoading: isStatsLoading } = useStats(selectedCampaign?.id);
  const { facets, isLoading: isFacetsLoading } = useFacets(selectedCampaign?.id);

  const { selectedCandidate, setSelectedCandidate } = useSelectedCandidate(candidates, isCandidatesLoading);

  const handleSelectedTab = useCallback<React.Dispatch<React.SetStateAction<CampaignTab>>>((cb) => {
    setSelectedTab((oldTab) => {
      const newTab = typeof cb === 'function' ? cb(oldTab) : cb;

      if (oldTab !== newTab) {
        setSelectedCandidate(undefined);
        setSelectedCandidateConfig(searchv3InitialState.config);
        setSelectedPage(1);
      }

      return newTab;
    });
  }, []);

  const state = useMemo<SelectedCampaignState>(
    () => ({
      campaign: selectedCampaign,
      filters,
      candidates: { data: candidates, isLoading: isCandidatesLoading, total },
      matches: { data: matches, isLoading: isMatchesLoading },
      stats: { data: stats, isLoading: isStatsLoading },
      facets: { data: facets, isLoading: isFacetsLoading },
      selectedTab,
      setSelectedTab: handleSelectedTab,
      setSelectedCandidate,
      selectedCandidate,
      selectedPage,
      setSelectedPage,

      selectedCandidateConfig,
      setSelectedCandidateConfig,
    }),
    [
      selectedCampaign,
      filters,
      candidates,
      isCandidatesLoading,
      matches,
      isMatchesLoading,
      stats,
      isStatsLoading,
      facets,
      isFacetsLoading,
      selectedTab,
      selectedPage,
      total,
      selectedCandidate,
      selectedCandidateConfig,
    ],
  );

  return (
    <SelectedCampaignContext.Provider value={state}>
      <LoadedCvsContextProvider>
        <SelectedCandidateContextProvider>{children}</SelectedCandidateContextProvider>
      </LoadedCvsContextProvider>
    </SelectedCampaignContext.Provider>
  );
};

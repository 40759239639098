import { sentenceCase } from 'change-case';
import { CandidateCardLabelsDefaultOptions } from 'components/ui/molecules/CandidateCardLabels/types';
import { Dictionary } from 'shared/contexts/LocalizationContext/types';
import { sharedUtils } from 'utils';

export const getTranslationsFromLabelKey = (label: string, { dictionary }: { dictionary: Dictionary }): string => {
  const translationFromLabel: Record<CandidateCardLabelsDefaultOptions, string> = {
    contacted: dictionary.contacted,
    interviewScheduled: dictionary.interviewScheduled,
    interviewCompleted: dictionary.interviewCompleted,
    interviewCancelled: dictionary.interviewCancelled,
    leftVoicemail: dictionary.leftVoicemail,
    moreInfoSent: dictionary.moreInfoSent,
    notResponding: dictionary.notResponding,
    notInterested: dictionary.notInterested,
    notOpenToWork: dictionary.notOpenToWork,
  };

  return (sharedUtils.hasKey(translationFromLabel, label) && translationFromLabel[label]) || sentenceCase(label);
};

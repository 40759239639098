import { useContext, useState } from 'react';
import { CandidateOutreachConfirmMessageModalProps } from './types';
import { useStyles } from './styles';
import { Column, Row, Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';

import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { isAxiosError } from 'helpers/clientHelpers';
import { CheckCircle, XCircle } from 'components/ui/atoms/icons';
import { logger } from 'config/logger';

const CandidateOutreachConfirmMessageModal = ({ onCancel, onConfirm }: CandidateOutreachConfirmMessageModalProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const { createSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const handleConfirm = async () => {
    setIsLoadingConfirm(true);
    try {
      await onConfirm();
    } catch (e) {
      logger.error(e);
      createSnackbar(isAxiosError(e) ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });
    }
    setIsLoadingConfirm(false);
  };

  return (
    <Column css={classes.root} gap={32}>
      <Column gap={8}>
        <Typography variant="header.h2" color={(c) => c.monochrome[100]}>
          {dictionary.confirmMessageTitleModal}
        </Typography>
        <Typography variant="body.long">{dictionary.confirmMessageDescriptionModal}</Typography>
      </Column>
      <Row gap={8} right>
        <Button icon={<XCircle />} label={dictionary.messageNotSent} variant="warning" onClick={onCancel} />
        <Button
          icon={<CheckCircle />}
          label={dictionary.messageSent}
          variant="highlight"
          onClick={handleConfirm}
          loading={isLoadingConfirm}
        />
      </Row>
    </Column>
  );
};

export default CandidateOutreachConfirmMessageModal;

import { Pill } from '@recruitrobin/robin-theme/web-components';
import { Star_01, Edit_03 } from '@recruitrobin/robin-theme/web-icons';
import { InsightPillProps } from './InsightPill.types';
import { useStyles } from './InsightPill.styles';

export const InsightPill = ({ label, type }: InsightPillProps) => {
  const classes = useStyles();

  return (
    <div css={type === 'new' ? classes.new : classes.updated}>
      <Pill
        label={label}
        style="icon-removable"
        type="success"
        size="small"
        padding="both"
        startIcon={type === 'new' ? <Star_01 size={14} /> : <Edit_03 size={14} />}
      />
    </div>
  );
};

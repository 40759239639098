import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { dictionary as en } from './languages/en';
import { dictionary as nl } from './languages/nl';
import { ContextProps, LocalizationContextProviderProps, SupportedLanguage, SupportedLocale } from './types';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { localizationClient } from 'clients/LocalizationClient/LocalizationClient';

const defaultContext: ContextProps = {
  language: window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? 'en' : 'nl',
  locale:
    window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? 'en-US' : 'nl-NL',
  dictionary: window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? en : nl,
  setLanguage: () => null,
  countries: {},
  countriesReverse: {},
};

export const LocalizationContext = createContext(defaultContext);

export const LocalizationContextProvider = ({ children }: LocalizationContextProviderProps) => {
  const [language, setLanguage] = useState<SupportedLanguage>(
    (localStorage.getItem('languageV2') as SupportedLanguage) || defaultContext.language,
  );
  const dictionaries = { nl, en };
  const dictionary = dictionaries[language as SupportedLanguage];
  const { isLoggedIn } = useContext(UserContext);

  const locale: SupportedLocale = language === 'en' ? 'en-US' : 'nl-NL';

  const { data: { data: { result: { results = [] } = {} } = {} } = {} } = useQuery(
    ['getAllCountries', { limit: 300 }],
    localizationClient.getAllCountries,
    { cacheTime: 1000 * 60 * 60 * 24, enabled: isLoggedIn }, // 1 Day
  );

  const countries = useMemo(
    () =>
      results.length > 0
        ? results.reduce((result: any, countries: any) => {
            result[countries.value] = countries.name;
            return result;
          }, results[0])
        : null,
    [results],
  );

  const countriesReverse = useMemo(
    () =>
      results.length > 0
        ? results.reduce((result: any, countries: any) => {
            result[countries.name] = countries.value;
            return result;
          })
        : null,
    [results],
  );

  useEffect(() => {
    localStorage.setItem('languageV2', language);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        countries,
        locale,
        countriesReverse,
        language,
        dictionary,
        setLanguage,
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};

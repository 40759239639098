import { InformationTagStyleStates } from './types';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props }) =>
    css(
      {
        borderWidth: 1,
        borderStyle: 'solid',
        ...(props.type === 'default' && {
          color: theme.colors.secondary.sky[60],
          background: theme.colors.secondary.sky[20],
          borderColor: theme.colors.secondary.sky[20],
          ...((props.clickable || props.removeable || props.shouldHover) && {
            background: theme.colors.secondary.sky[30],
            borderColor: theme.colors.secondary.sky[30],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.secondary.sky[50],
              borderColor: theme.colors.secondary.sky[50],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'subtle' && {
          color: theme.colors.neutral.variant[60],
          background: theme.colors.neutral.variant[20],
          borderColor: theme.colors.neutral.variant[20],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.neutral.variant[30],
            borderColor: theme.colors.neutral.variant[30],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.neutral.variant[50],
              borderColor: theme.colors.neutral.variant[50],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'success' && {
          color: theme.colors.secondary.emerald[60],
          background: theme.colors.secondary.emerald[20],
          borderColor: theme.colors.secondary.emerald[20],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.secondary.emerald[30],
            borderColor: theme.colors.secondary.emerald[30],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.secondary.emerald[50],
              borderColor: theme.colors.secondary.emerald[50],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'success-light' && {
          color: theme.colors.secondary.emerald[60],
          background: theme.colors.secondary.emerald[10],
          borderColor: theme.colors.secondary.emerald[10],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.secondary.emerald[20],
            borderColor: theme.colors.secondary.emerald[20],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.secondary.emerald[40],
              borderColor: theme.colors.secondary.emerald[40],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'warning' && {
          color: theme.colors.tertiary.lime[60],
          background: theme.colors.tertiary.lime[10],
          borderColor: theme.colors.tertiary.lime[10],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.tertiary.lime[20],
            borderColor: theme.colors.tertiary.lime[20],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.tertiary.lime[20],
              borderColor: theme.colors.tertiary.lime[20],
              color: theme.colors.tertiary.lime[80],
            },
          }),
        }),
        ...(props.type === 'error' && {
          color: theme.colors.tertiary.red[60],
          background: theme.colors.tertiary.red[20],
          borderColor: theme.colors.tertiary.red[20],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.tertiary.red[30],
            borderColor: theme.colors.tertiary.red[30],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.tertiary.red[50],
              borderColor: theme.colors.tertiary.red[50],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'highlight' && {
          color: theme.colors.primary.fuchsia[60],
          background: theme.colors.primary.fuchsia[20],
          borderColor: theme.colors.primary.fuchsia[20],
          ...((props.clickable || props.removeable) && {
            background: theme.colors.primary.fuchsia[30],
            borderColor: theme.colors.primary.fuchsia[30],
          }),

          ...(props.shouldHover && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              background: theme.colors.primary.fuchsia[50],
              borderColor: theme.colors.primary.fuchsia[50],
              color: theme.colors.neutral.variant[0],
            },
          }),
        }),
        ...(props.type === 'subtle2' && {
          color: theme.colors.primary.fuchsia[50],
          background: 'transparent',
          borderColor: 'transparent',
          ...((props.clickable || props.removeable || props.shouldHover) && {
            [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
              color: theme.colors.primary.fuchsia[60],
              background: theme.colors.primary.fuchsia[10],
              borderColor: theme.colors.primary.fuchsia[10],
            },
          }),
        }),
        ...(props.isDigit && {
          width: 24,
        }),
        ...(props.uppercase && {
          textTransform: 'uppercase',
        }),
        ...(props.clickable && {
          cursor: 'pointer',
        }),
      },
      css`
        max-width: 100%;

        & > svg:first-of-type {
          flex-shrink: 0;
        }

        & span.ellipsis {
          display: flex;
          align-items: center;
          min-width: 0;

          & > div {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: ${props.shouldWrap ? 'wrap' : 'nowrap'};
          }
        }
      `,
    ),
  small: ({ props }) =>
    css({
      ...(props.hasIcon && {
        padding: 3,
        borderRadius: 10,
      }),
      ...(props.hasLabel && {
        padding: '2.5px 5.5px',
        borderRadius: 10,
      }),
      ...(props.hasIcon &&
        props.hasLabel && {
          padding: '1.5px 5.5px 1.5px 3.5px',
          borderRadius: 9,
        }),
    }),
  default: ({ props }) =>
    css({
      borderRadius: 12,
      ...(props.hasIcon && {
        padding: 4,
      }),
      ...(props.hasLabel && {
        padding: '2.5px 7px',
      }),
      ...(props.hasIcon &&
        props.hasLabel && {
          padding: '3px 7px 3px 4px',
        }),
    }),
  large: ({ props }) =>
    css({
      borderRadius: 13,
      ...(props.hasIcon && {
        padding: 6,
      }),
      ...(props.hasLabel && {
        padding: '4.5px 9px',
      }),
      ...(props.hasIcon &&
        props.hasLabel && {
          padding: '5px 9px 5px 7px',
        }),
    }),
  label: ({ props }) =>
    css({
      ...(props.hasIcon &&
        props.hasLabel && {
          lineHeight: '100%',
          letterSpacing: '0.005em',
        }),
    }),
  deleteIcon: ({ theme, props }) =>
    css({
      display: 'inline-flex',
      margin: '-5px -9px -5px 0',
      padding: 4,
      borderRadius: '50%',
      cursor: 'pointer',
      [`${props.shouldHover && '.parent-hover:hover &, &:hover'}`]: {
        ...(props.type === 'default' && {
          background: theme.colors.secondary.sky[20],
        }),
        ...(props.type === 'subtle' && {
          background: theme.colors.neutral.variant[20],
        }),
        ...(props.type === 'success' && {
          background: theme.colors.secondary.emerald[20],
        }),
        ...(props.type === 'success-light' && {
          background: theme.colors.secondary.emerald[10],
        }),
        ...(props.type === 'warning' && {
          background: theme.colors.tertiary.lime[10],
        }),
        ...(props.type === 'error' && {
          background: theme.colors.tertiary.red[20],
        }),
        ...(props.type === 'highlight' && {
          background: theme.colors.primary.fuchsia[50],
        }),
        ...(props.type === 'subtle2' && {
          background: 'transparent',
        }),
      },
    }),
} satisfies Styles<InformationTagStyleStates>;

export const useStyles = withStyles(styles);

import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      width: '100%',
      maxWidth: 580,
      padding: '32px 40px 40px',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

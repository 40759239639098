import { ErrorBoundaryProps } from './types';
import { Component } from 'react';

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { error: unknown } = { error: undefined };

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  static getDerivedStateFromError(error: unknown) {
    console.error('[ErrorBoundary] getDerivedStateFromError', { error });

    return { error };
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.error('[ErrorBoundary] componentDidCatch', { error, info });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (!!error && fallback) {
      return typeof fallback === 'function' ? fallback({ error }) : fallback;
    }

    return children;
  }
}

export default ErrorBoundary;

import { BaseClient } from 'clients/BaseClient/BaseClient';
import { hosts } from 'config';
import { SupportedLanguage } from 'shared/contexts/LocalizationContext/types';

export class LocalizationClient extends BaseClient {
  constructor() {
    super();
    this.setLanguage = this.setLanguage.bind(this);
    this.getAllCountries = this.getAllCountries.bind(this);
  }

  async setLanguage(language: SupportedLanguage) {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Language/SetDefaultLanguage`,
      method: 'POST',
      data: {
        name: language,
      },
    });
  }

  async getAllCountries() {
    return this.request({
      url: `${hosts.userApi()}/data/country/`,
      method: 'GET',
    });
  }
}

export const localizationClient = new LocalizationClient();

import { createProject } from './createProject';
import { getJobDescription } from './getJobDescription';
import { getJobsList } from './getJobsList';
import { getProjectsList } from './getProjectsList';
import { sendMessage } from './sendMessage';

export class IndeedClient {
  private apiKey = '0f2b0de1b8ff96890172eeeba0816aaab662605e3efebbc0450745798c4b35ae';
  private ctk: string | undefined;

  setCtk = (ctk: string) => {
    this.ctk = ctk;
  };

  commonHeaders = () => {
    if (!this.ctk) {
      throw new TypeError('this.ctk must be a string');
    }

    return {
      'content-type': 'application/json',
      'indeed-ctk': this.ctk,
      'indeed-api-key': this.apiKey,
    };
  };

  getProjectList = () => {
    return getProjectsList({ headers: this.commonHeaders() });
  };

  getJobsList = () => {
    return getJobsList();
  };

  getJobDescription = ({ id }: { id: string }) => {
    return getJobDescription({ id });
  };

  sendMessage = (params: SendMessageParams) => {
    return sendMessage({
      headers: this.commonHeaders(),
      data: {
        candidateId: params.candidateId,
        description: params.description,
        jobTitle: params.jobTitle,
        message: params.message,
        senderName: params.senderName,
        senderCompany: params.senderCompany,
        projectKey: params.projectKey,
      },
    });
  };

  createProject = (name: string) => {
    if (!this.ctk) {
      throw new TypeError('this.ctk must be a string');
    }

    return createProject({ csrfToken: this.ctk, name });
  };
}

type SendMessageParams = {
  candidateId: string;
  description: string;
  jobTitle: string;
  message: string;
  senderName: string;
  senderCompany: string;
  projectKey: string;
};

import { useLocalStorage } from 'hooks/shared';
import { useMemo, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { ConfigContext } from '../ConfigContext';
import { initialState } from './CampaignManagerContext.constants';
import { CampaignManagerState } from './CampaignManagerContext.types';

export const useFilters = (): CampaignManagerState['filters'] => {
  const defaultTeamview = useContextSelector(ConfigContext, (state) => state.campaign.teamview);
  const defaultStatus = useContextSelector(ConfigContext, (state) => state.campaign.status);
  const { setValue: setTeamview, value: teamview } = useLocalStorage('showTeamCampaigns', defaultTeamview);

  const [status, setStatus] = useState(defaultStatus);
  const [name, setName] = useState(initialState.filters.name);

  return useMemo(
    () => ({
      status,
      teamview,
      name,

      setStatus,
      setTeamview,
      setName,
    }),
    [status, teamview, name],
  );
};

import { NotificationQuoteProps } from './types';
import { useStyles } from './styles';
import { Row, Typography } from 'components/ui/atoms';
import { InformationTag } from '../index';

const NotificationQuote = ({ message }: NotificationQuoteProps) => {
  const classes = useStyles();

  return (
    <Row css={classes.root} gap={8}>
      <div>
        <InformationTag icon="Bell" type="success" />
      </div>
      <Typography variant="body.short" inline color={(color) => color.mint[60]}>
        {message}
      </Typography>
    </Row>
  );
};

export default NotificationQuote;

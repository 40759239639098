import { useContext } from 'react';
import { Modal as MuiModal } from '@material-ui/core';
import { X } from 'shared/icons';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { AndroidLogo, AppleLogo } from 'components/ui/atoms/icons';
import { Column, Row, Typography } from 'components/ui/atoms';

import { DownloadMobileAppModalProps } from './types';
import { useStyles } from './styles';
import { Button } from '../index';

const DownloadMobileAppModal = ({ visible, onClose }: DownloadMobileAppModalProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/nl/app/recruit-robin/id6472052269', '_blank', 'noreferrer');
  };

  const handleGooglePlayClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.recruitrobin', '_blank', 'noreferrer');
  };

  return (
    <MuiModal css={classes.root} open={visible} onClose={onClose}>
      <Column css={classes.content} gap={34}>
        <div css={classes.closeButton}>
          <Button
            variant="subtle"
            icon={<X color={(color) => color.monochrome[70]} />}
            onClick={onClose}
            tooltipLabel={dictionary.close}
          />
        </div>

        <Column gap={8}>
          <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.primary.fuchsia[50]}>
            {dictionary.downloadRobinMobileApp}
          </Typography>
          <Typography variant={(v) => v.heading[4]} colorv2={(c) => c.neutral.variant[80]}>
            {dictionary.selectYourDevicePlatform}
          </Typography>
        </Column>

        <Row gap={34}>
          <Column css={classes.buttonStore} center alignCenter onClick={handleAppStoreClick}>
            <AppleLogo css={classes.iconStore} />
            <Typography variant={(v) => v.heading[5]} colorv2={(c) => c.neutral.variant[80]}>
              iOS
            </Typography>
          </Column>
          <Column css={classes.buttonStore} center alignCenter onClick={handleGooglePlayClick}>
            <AndroidLogo css={classes.iconStore} />
            <Typography variant={(v) => v.heading[5]} colorv2={(c) => c.neutral.variant[80]}>
              Android
            </Typography>
          </Column>
        </Row>
      </Column>
    </MuiModal>
  );
};

export default DownloadMobileAppModal;

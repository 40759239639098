import { useEffect, useMemo, useState } from 'react';
import { useLoadNotes } from 'shared/contexts/CandidateContext/actions/useLoadNotes';
import { createContext } from 'use-context-selector';
import { useCandidate } from './SharedCandidateContext.useCandidate';
import { SharedCandidateContextProps, SharedCandidateContextProviderProps } from './types';

const initialState: SharedCandidateContextProps = {
  candidate: undefined,
  isLoading: true,
};

export const SharedCandidateContext = createContext<SharedCandidateContextProps>(initialState);

export const SharedCandidateContextProvider = ({ children }: SharedCandidateContextProviderProps) => {
  const [areNotesLoaded, setAreNotesLoaded] = useState(false);
  const { candidate, isLoading: isActiveCandidateLoading } = useCandidate();
  const loadNotes = useLoadNotes({ setIsLoading: () => undefined, candidate });

  useEffect(() => {
    if (candidate && areNotesLoaded === false) {
      setAreNotesLoaded(true);
      loadNotes();
    } else if (candidate === undefined) {
      setAreNotesLoaded(false);
    }
  }, [candidate]);

  const valueMemoized = useMemo<SharedCandidateContextProps>(
    () => ({ candidate, isLoading: isActiveCandidateLoading }),
    [candidate, isActiveCandidateLoading],
  );

  return <SharedCandidateContext.Provider value={valueMemoized}>{children}</SharedCandidateContext.Provider>;
};

import { CandidateResponse } from 'clients/CampaignsClient/CandidateResponse';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useSetActiveJobBoard = (params: WrappedHookParams) => {
  return useCandidateAction<SetActiveJobBoardHookCallbackParams, SetActiveJobBoardCallbackParams>(
    async ({ candidate, jobBoardId }) => {
      const candidateIdWithJobBoard = candidate.jobBoardMatchesWithCandidateId[jobBoardId];

      if (!candidateIdWithJobBoard) {
        throw new TypeError("This candidate doesn't have this jobboard");
      }

      const {
        data: [candidateInfo],
      } = await searchClient.getCandidateById(candidateIdWithJobBoard);

      return { candidateInfo };
    },
    {
      onSuccess: () => {
        return async ({ candidateOrchestrator: co, pageContext, data: { candidateInfo } }) => {
          if (pageContext === PageContext.Campaign) {
            co.campaign.update.info(candidateInfo);
          } else if (pageContext === PageContext.Shared) {
            co.share.update.info(candidateInfo);
          }
        };
      },
    },
  )(params);
};

type SetActiveJobBoardCallbackParams = {
  candidateInfo: CandidateResponse;
};

type SetActiveJobBoardHookCallbackParams = {
  jobBoardId: CampaignJobboard;
};

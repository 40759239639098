import { hosts } from 'config';
import { BaseClient } from 'clients/BaseClient/BaseClient';
import {
  AcknowledgeAllNotificationsHandler,
  AcknowledgeNotificationHandler,
  GetLastCandidateNotificationHandler,
  GetNotificationsHandler,
  GetSentMessagesToCandidateHandler,
  SendPushNotificationHandler,
  type ConnectEmailAccountHandler,
  type ConnectedEmailAccountDetailsHandler,
  type ConnectedEmailAccountStatusHanlder,
  type DisconnectEmailAccountHandler,
} from './NotificationsClient.types';
import { replaceUrlToLocal } from 'helpers';

export class NotificationsClient extends BaseClient {
  getNotifications: GetNotificationsHandler = async (filters = { limit: 100 }) => {
    const response: Awaited<ReturnType<GetNotificationsHandler>> = await this.request({
      url: `${hosts.notificationsApi()}/notification/`,
      method: 'GET',
      params: { ...filters },
    });

    return {
      ...response,
      data: {
        ...response.data,
        results: response.data.results.map((notification) => ({
          ...notification,
          url: replaceUrlToLocal(notification.url, { debug: true }),
        })),
      },
    };
  };

  acknowledgeNotification: AcknowledgeNotificationHandler = (id) => {
    return this.request({
      url: `${hosts.notificationsApi()}/notification/${id}/`,
      method: 'PATCH',
      data: { status: 'acknowledged' },
    });
  };

  acknowledgeAllNotification: AcknowledgeAllNotificationsHandler = () => {
    return this.request({
      url: `${hosts.notificationsApi()}/notification/read_all/`,
      method: 'PATCH',
    });
  };

  getLastCandidateNotification: GetLastCandidateNotificationHandler = ({ send_method }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/notifications/candidates/${send_method}/last`,
      method: 'GET',
    });
  };

  getSentMessageToCandidate: GetSentMessagesToCandidateHandler = ({ candidate_id }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/notifications/candidates/${candidate_id}`,
      method: 'GET',
    });
  };

  sendPushNotification: SendPushNotificationHandler = (data) => {
    return this.request({
      url: `${hosts.notificationsApi()}/expo/push_notification/`,
      method: 'POST',
      data,
    });
  };

  connectEmailAccount: ConnectEmailAccountHandler = () => {
    return this.request({
      url: `${hosts.notificationsApi()}/email/oauth/url`,
      method: 'GET',
    });
  };

  connectedEmailAccountStatus: ConnectedEmailAccountStatusHanlder = () => {
    return this.request({
      url: `${hosts.notificationsApi()}/email/oauth/status`,
      method: 'GET',
    });
  };

  disconnectEmailAccount: DisconnectEmailAccountHandler = () => {
    return this.request({
      url: `${hosts.notificationsApi()}/email/oauth/disconnect/`,
      method: 'POST',
    });
  };

  connectedAccountDetails: ConnectedEmailAccountDetailsHandler = () => {
    return this.request({
      url: `${hosts.notificationsApi()}/email/oauth/details`,
      method: 'GET',
    });
  };
}

export const notificationsClient = new NotificationsClient();

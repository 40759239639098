import { useEffect, useState } from 'react';
import { BrowserExtensionInfoStatus, UseBrowserExtension } from './types';
import { useBrowserExtension } from './useBrowserExtension';

export const useSafeBrowserExtension = (callback: (browserExtension: UseBrowserExtension) => void) => {
  const [alreadyRan, setAlreadyRan] = useState(false);
  const browserExtension = useBrowserExtension();

  useEffect(() => {
    if (!alreadyRan && browserExtension.info.status === BrowserExtensionInfoStatus.ACTIVE) {
      callback(browserExtension);
      setAlreadyRan(true);
    }
  }, [alreadyRan, browserExtension.info.status]);
};

import { useState } from 'react';
import { useSafeBrowserExtension } from 'shared/hooks/useBrowserExtension/useSafeBrowserExtension';

export const useVerifyLinkedinStatus = (): boolean => {
  const [hasLinkedinFreeInviteRemaining, setHasLinkedinFreeCredits] = useState<boolean>(true);

  useSafeBrowserExtension((browserExtension) => {
    browserExtension.hasLinkedinFreeInviteRemaining().then((hasCredits) => {
      setHasLinkedinFreeCredits(hasCredits);
    });
  });

  return hasLinkedinFreeInviteRemaining;
};

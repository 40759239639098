export const CANDIDATE_STATUS = {
  NEW: 'NEW',
  SHORTLISTED: 'SHORTLISTED',
  CONTACTED: 'CONTACTED',
  MATCHED: 'MATCHED',
  HIDDEN: 'HIDDEN',
  INVISIBLE: 'INVISIBLE',
} as const;

export const CANDIDATE_STATUS_FRIENDLY: { [CS in Lowercase<keyof typeof CANDIDATE_STATUS>]: CS } = {
  new: 'new',
  shortlisted: 'shortlisted',
  contacted: 'contacted',
  matched: 'matched',
  hidden: 'hidden',
  invisible: 'invisible',
} as const;

export const CANDIDATE_ACTIVITY_LOG_STATUS = {
  CAMPAIGN_CANDIDATE_UNLOCKED: 'CAMPAIGN_CANDIDATE_UNLOCKED',
  CAMPAIGN_CANDIDATE_MATCHED: 'CAMPAIGN_CANDIDATE_MATCHED',
  CAMPAIGN_CANDIDATE_HIDDEN: 'CAMPAIGN_CANDIDATE_HIDDEN',
  CAMPAIGN_CANDIDATE_SHORTLISTED: 'CAMPAIGN_CANDIDATE_SHORTLISTED',
  CAMPAIGN_CANDIDATE_INVISIBLE: 'CAMPAIGN_CANDIDATE_INVISIBLE',
  CAMPAIGN_CANDIDATE_CONTACTED: 'CAMPAIGN_CANDIDATE_CONTACTED',
  CAMPAIGN_CANDIDATE_SENT_TO_ATS: 'CAMPAIGN_CANDIDATE_SENT_TO_ATS',
  CAMPAIGN_CANDIDATE_LABEL_CHANGED: 'CAMPAIGN_CANDIDATE_LABEL_CHANGED',
} as const;

export type CandidateStatus = keyof typeof CANDIDATE_STATUS;
export type CandidateStatusFriendly = keyof typeof CANDIDATE_STATUS_FRIENDLY;
export type CandidateActivityLogStatus = keyof typeof CANDIDATE_ACTIVITY_LOG_STATUS;

export const CANDIDATE_ACTION = {
  SHORTLIST: 'SHORTLIST',
  CONTACT: 'CONTACT',
  HIDE: 'HIDE',
  MATCH: 'MATCH',
  'SHOW-MORE': 'SHOW-MORE',
  ['SHARE-PROFILE']: 'SHARE-PROFILE',
  DOWNLOAD: 'DOWNLOAD',
} as const;

export const CANDIDATE_SECONDARY_ACTION = {
  MATCH: 'MATCH',
  'SHORTLIST-TO-ANOTHER-SEARCH': 'SHORTLIST-TO-ANOTHER-SEARCH',
  'RETRY-TO-SEND-TO-ATS': 'RETRY-TO-SEND-TO-ATS',
} as const;

export const CANDIDATE_ACTION_FRIENDLY: { [CS in Lowercase<keyof typeof CANDIDATE_ACTION>]: CS } = {
  shortlist: 'shortlist',
  contact: 'contact',
  hide: 'hide',
  match: 'match',
  'show-more': 'show-more',
  ['share-profile']: 'share-profile',
  download: 'download',
} as const;

export const CANDIDATE_SECONDARY_ACTION_FRIENDLY: { [CS in Lowercase<keyof typeof CANDIDATE_SECONDARY_ACTION>]: CS } = {
  match: 'match',
  'shortlist-to-another-search': 'shortlist-to-another-search',
  'retry-to-send-to-ats': 'retry-to-send-to-ats',
} as const;

export type CandidateAction = keyof typeof CANDIDATE_ACTION;
export type CandidateActionFriendly = keyof typeof CANDIDATE_ACTION_FRIENDLY;
export type CandidateSecondaryAction = keyof typeof CANDIDATE_SECONDARY_ACTION;
export type CandidateSecondaryActionFriendly = keyof typeof CANDIDATE_SECONDARY_ACTION_FRIENDLY;

/**
 * Helpers, utils and aliases
 */

export class CandidateStatusHelpers {
  static use<T extends CandidateStatus>(...statuses: T[]) {
    return {
      includes(str: unknown): T | undefined {
        if (statuses.includes(str as T)) {
          return str as T;
        }

        return undefined;
      },
    };
  }
}

export const CANDIDATE_STATUS_ARR = Object.keys(CANDIDATE_STATUS) as CandidateStatus[];

export const candidateStatusRawToFriendly = <T extends CandidateStatus>(raw: T): Lowercase<T> => {
  const rawToFriendly: { [CS in CandidateStatus]: Lowercase<CS> } = {
    [CANDIDATE_STATUS.NEW]: CANDIDATE_STATUS_FRIENDLY.new,
    [CANDIDATE_STATUS.SHORTLISTED]: CANDIDATE_STATUS_FRIENDLY.shortlisted,
    [CANDIDATE_STATUS.CONTACTED]: CANDIDATE_STATUS_FRIENDLY.contacted,
    [CANDIDATE_STATUS.MATCHED]: CANDIDATE_STATUS_FRIENDLY.matched,
    [CANDIDATE_STATUS.HIDDEN]: CANDIDATE_STATUS_FRIENDLY.hidden,
    [CANDIDATE_STATUS.INVISIBLE]: CANDIDATE_STATUS_FRIENDLY.invisible,
  };

  return rawToFriendly[raw];
};

export const candidateStatusFriendlyToRaw = <T extends CandidateStatusFriendly>(friendly: T): Uppercase<T> => {
  const friendlyToRaw: { [CSF in CandidateStatusFriendly]: Uppercase<CSF> } = {
    [CANDIDATE_STATUS_FRIENDLY.new]: CANDIDATE_STATUS.NEW,
    [CANDIDATE_STATUS_FRIENDLY.shortlisted]: CANDIDATE_STATUS.SHORTLISTED,
    [CANDIDATE_STATUS_FRIENDLY.contacted]: CANDIDATE_STATUS.CONTACTED,
    [CANDIDATE_STATUS_FRIENDLY.matched]: CANDIDATE_STATUS.MATCHED,
    [CANDIDATE_STATUS_FRIENDLY.hidden]: CANDIDATE_STATUS.HIDDEN,
    [CANDIDATE_STATUS_FRIENDLY.invisible]: CANDIDATE_STATUS.INVISIBLE,
  };

  return friendlyToRaw[friendly];
};

export const candidateActionRawToFriendly = <T extends CandidateAction>(raw: T): Lowercase<T> => {
  const rawToFriendly: { [CS in CandidateAction]: Lowercase<CS> } = {
    [CANDIDATE_ACTION.SHORTLIST]: CANDIDATE_ACTION_FRIENDLY.shortlist,
    [CANDIDATE_ACTION.CONTACT]: CANDIDATE_ACTION_FRIENDLY.contact,
    [CANDIDATE_ACTION.MATCH]: CANDIDATE_ACTION_FRIENDLY.match,
    [CANDIDATE_ACTION.HIDE]: CANDIDATE_ACTION_FRIENDLY.hide,
    [CANDIDATE_ACTION['SHOW-MORE']]: CANDIDATE_ACTION_FRIENDLY['show-more'],
    [CANDIDATE_ACTION['SHARE-PROFILE']]: CANDIDATE_ACTION_FRIENDLY['share-profile'],
    [CANDIDATE_ACTION['DOWNLOAD']]: CANDIDATE_ACTION_FRIENDLY['download'],
  };

  return rawToFriendly[raw];
};

export const candidateActionFriendlyToRaw = <T extends CandidateActionFriendly>(friendly: T): Uppercase<T> => {
  const friendlyToRaw: { [CSF in CandidateActionFriendly]: Uppercase<CSF> } = {
    [CANDIDATE_ACTION_FRIENDLY.shortlist]: CANDIDATE_ACTION.SHORTLIST,
    [CANDIDATE_ACTION_FRIENDLY.contact]: CANDIDATE_ACTION.CONTACT,
    [CANDIDATE_ACTION_FRIENDLY.match]: CANDIDATE_ACTION.MATCH,
    [CANDIDATE_ACTION_FRIENDLY.hide]: CANDIDATE_ACTION.HIDE,
    [CANDIDATE_ACTION_FRIENDLY['show-more']]: CANDIDATE_ACTION['SHOW-MORE'],
    [CANDIDATE_ACTION_FRIENDLY['share-profile']]: CANDIDATE_ACTION['SHARE-PROFILE'],
    [CANDIDATE_ACTION_FRIENDLY.download]: CANDIDATE_ACTION.DOWNLOAD,
  };

  return friendlyToRaw[friendly];
};

export const candidateSecondaryActionRawToFriendly = <T extends CandidateSecondaryAction>(raw: T): Lowercase<T> => {
  const rawToFriendly: { [CS in CandidateSecondaryAction]: Lowercase<CS> } = {
    [CANDIDATE_SECONDARY_ACTION.MATCH]: CANDIDATE_SECONDARY_ACTION_FRIENDLY.match,
    [CANDIDATE_SECONDARY_ACTION['RETRY-TO-SEND-TO-ATS']]: CANDIDATE_SECONDARY_ACTION_FRIENDLY['retry-to-send-to-ats'],
    [CANDIDATE_SECONDARY_ACTION['SHORTLIST-TO-ANOTHER-SEARCH']]:
      CANDIDATE_SECONDARY_ACTION_FRIENDLY['shortlist-to-another-search'],
  };

  return rawToFriendly[raw];
};

export const candidateSecondaryActionFriendlyToRaw = <T extends CandidateSecondaryActionFriendly>(
  friendly: T,
): Uppercase<T> => {
  const friendlyToRaw: { [CS in CandidateSecondaryActionFriendly]: Uppercase<CS> } = {
    [CANDIDATE_SECONDARY_ACTION_FRIENDLY.match]: CANDIDATE_SECONDARY_ACTION.MATCH,
    [CANDIDATE_SECONDARY_ACTION_FRIENDLY['retry-to-send-to-ats']]: CANDIDATE_SECONDARY_ACTION['RETRY-TO-SEND-TO-ATS'],
    [CANDIDATE_SECONDARY_ACTION_FRIENDLY['shortlist-to-another-search']]:
      CANDIDATE_SECONDARY_ACTION['SHORTLIST-TO-ANOTHER-SEARCH'],
  };

  return friendlyToRaw[friendly];
};

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CandidateActionsResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import {
  CandidateAction,
  CandidateActionFriendly,
  candidateActionFriendlyToRaw,
  CandidateSecondaryAction,
  CandidateSecondaryActionFriendly,
  candidateSecondaryActionFriendlyToRaw,
  CandidateStatus,
} from 'services/candidates/types';

export type CandidateActionsQueryResponse = Record<
  CandidateStatus,
  {
    actions: CandidateAction[];
    extraOptions: Partial<Record<CandidateAction, CandidateSecondaryAction[]>>;
  }
>;

export const useCandidateActionsQuery = () => {
  return useQuery<CandidateActionsQueryResponse>(
    ['useCandidateActionsQuery'],
    async () => {
      const response = await campaignsClient.getCandidateActions();

      return convertCandidateActionsResponseFriendlyToRaw(response.data);
    },
    { keepPreviousData: true, cacheTime: Infinity },
  );
};

const convertCandidateActionsResponseFriendlyToRaw = (
  candidateActions: CandidateActionsResponse,
): CandidateActionsQueryResponse => {
  return (Object.entries(candidateActions) as [CandidateStatus, CandidateActionsResponse[CandidateStatus]][]).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        actions: value.actions.map(candidateActionFriendlyToRaw),
        extraOptions: convertExtraOptionsFriendlyToRaw(value.extra_options ?? {}),
      },
    }),
    {} as CandidateActionsQueryResponse,
  );
};

const convertExtraOptionsFriendlyToRaw = (
  extraOptions: Partial<Record<CandidateActionFriendly, CandidateSecondaryActionFriendly[]>>,
): Partial<Record<CandidateAction, CandidateSecondaryAction[]>> => {
  return (Object.keys(extraOptions) as CandidateActionFriendly[]).reduce(
    (acc, key) => ({
      ...acc,
      [candidateActionFriendlyToRaw(key)]: extraOptions[key]?.map(candidateSecondaryActionFriendlyToRaw),
    }),
    {} as Partial<Record<CandidateAction, CandidateSecondaryAction[]>>,
  );
};

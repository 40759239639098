import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      height: '100%',
      overflow: 'hidden',
    }),
  contentArea: () =>
    css({
      overflow: 'auto',
    }),
  contentInput: () =>
    css({
      minHeight: 108,
    }),
  sendButton: () =>
    css({
      marginTop: 'auto',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

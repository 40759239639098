import { styleUtils } from 'utils';
import { TypographyStyleStates } from './types';
import { css, extractFont, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ props: { color, nowrap, contained, pointer, underline, inline, breakSpaces, capitalize } }) =>
    css({
      whiteSpace: 'pre-wrap',
      display: 'inline-flex',
      alignItems: 'center',
      ...(nowrap && { whiteSpace: 'pre' }),
      ...(contained && { width: '100%' }),
      ...(pointer && { cursor: 'pointer' }),
      ...(underline && { textDecoration: 'underline' }),
      ...(inline && { display: 'inline', position: 'relative' }),
      ...(breakSpaces && { whiteSpace: 'break-spaces' }),
      ...(capitalize && { textTransform: 'capitalize' }),
      '&': { ...(Boolean(color) && styleUtils.extractThemeColorWithState(color)) },
    }),
  ['header.h1']: css(extractFont((font) => font.heading[1], 'web')),
  ['header.h2']: css(extractFont((font) => font.heading[2], 'web')),
  ['header.h3']: css(extractFont((font) => font.heading[3], 'web')),
  ['header.h4']: css(extractFont((font) => font.heading[4], 'web')),
  ['header.h5']: css(extractFont((font) => font.heading[5], 'web')),
  ['header.h6']: css(extractFont((font) => font.heading[6], 'web')),
  ['body.long']: css(extractFont((font) => font.body.long, 'web')),
  ['body.short']: css(extractFont((font) => font.body.short, 'web')),
  ['supporting.code']: css(extractFont((font) => font.supporting.code, 'web')),
  ['supporting.codeInline']: css(extractFont((font) => font.supporting['code-line'], 'web')),
  ['supporting.label']: css(extractFont((font) => font.supporting.label, 'web')),
  ['supporting.labelTiny']: css(extractFont((font) => font.supporting['label-tiny'], 'web')),
  ['supporting.helper']: css(extractFont((font) => font.supporting.helper, 'web')),
  overrides: ({ props: { fontSize } }) =>
    css({
      ...(fontSize && { fontSize }),
    }),
  ellipsis: ({ props: { ellipsis } }) =>
    css({
      ...(ellipsis && {
        display: 'inline',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),
    }),
} satisfies Styles<TypographyStyleStates>;

export const useStyles = withStyles(styles);

import { ButtonGroup, LargeIconButton } from '@recruitrobin/robin-theme/web-components';
import {
  FileSearch_02,
  FileSearch_02Fill,
  LayoutLeft,
  LayoutLeftFill,
  Mail_01,
  Monitor_01,
  Monitor_01Fill,
  PieChart_01,
  PieChart_01Fill,
  PuzzlePiece_01,
  PuzzlePiece_01Fill,
  User_01,
  User_01Fill,
  Users_01,
  Users_01Fill,
} from '@recruitrobin/robin-theme/web-icons';
import { theme } from '@recruitrobin/robin-theme/web-styles';
import { UserRoles } from 'clients/UserClient/types';
import { Typography } from 'components/ui/atoms';
import { routes } from 'config';
import { useContext } from 'react';
import { AuthContext } from 'shared/contexts/AuthContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useAuth } from 'shared/hooks';
import { useContainer } from './Sidebar.useContainer';
import { useStyles } from './SidebarNav.styles';
import { SidebarNavPages } from './SidebarNav.types';
import { useSelectedRoute } from './SidebarNav.useSelectedRoute';
import SidebarOverlayFloating from './SidebarOverlayFloating';

const SIDEBAR_MENU_ICONS_SIZE = 20;

const SidebarNav = () => {
  const { isStaff } = useAuth();
  const classes = useStyles();
  const { navigateTo, verifyAndNavigateToSearchRoute } = useContainer();
  const { dictionary } = useContext(LocalizationContext);
  const { isOpen: isFixedOpen, setIsOpen: setIsFixedOpen } = useContext(SidebarOverlayFixedContext);
  const activeRoute = useSelectedRoute();
  const { currentUser } = useContext(UserContext);
  const { activeTokenType, disposeImpersonatedToken } = useContext(AuthContext);

  return (
    <ButtonGroup gap={16}>
      <LargeIconButton
        selected={SidebarNavPages.Campaign === activeRoute}
        icon={(selected) => {
          return selected ? (
            <FileSearch_02Fill size={SIDEBAR_MENU_ICONS_SIZE} />
          ) : (
            <FileSearch_02 size={SIDEBAR_MENU_ICONS_SIZE} />
          );
        }}
        onClick={() => {
          verifyAndNavigateToSearchRoute();
          setIsFixedOpen(!isFixedOpen);
        }}
        tooltip={!isFixedOpen && <SidebarOverlayFloating />}
        tooltipStyle={classes.fileSearchIcon}
        tooltipArrowStyle={classes.fileSearchIconArrow}
        tooltipArrowStrokeStyle={theme.colors.neutral.variant[10]}
        tooltipArrowStrokeWidthStyle={1}
        customTooltipPosition={{ top: 64, left: 0 }}
        tooltipArrow
      />
      {currentUser?.role !== UserRoles.Recruiter && (
        <>
          <LargeIconButton
            selected={SidebarNavPages.Users === activeRoute}
            icon={(selected) =>
              selected ? <User_01Fill size={SIDEBAR_MENU_ICONS_SIZE} /> : <User_01 size={SIDEBAR_MENU_ICONS_SIZE} />
            }
            onClick={() => navigateTo(routes.users)}
            tooltip={<Typography variant="supporting.helper">{dictionary.users}</Typography>}
            tooltipStyle={classes.tooltip}
          />
          <LargeIconButton
            selected={SidebarNavPages.Teams === activeRoute}
            icon={(selected) =>
              selected ? <Users_01Fill size={SIDEBAR_MENU_ICONS_SIZE} /> : <Users_01 size={SIDEBAR_MENU_ICONS_SIZE} />
            }
            onClick={() => navigateTo(routes.teams)}
            tooltip={<Typography variant="supporting.helper">{dictionary.teams}</Typography>}
            tooltipStyle={classes.tooltip}
          />
        </>
      )}
      <LargeIconButton
        selected={SidebarNavPages.Dashboard === activeRoute}
        icon={(selected) =>
          selected ? <PieChart_01Fill size={SIDEBAR_MENU_ICONS_SIZE} /> : <PieChart_01 size={SIDEBAR_MENU_ICONS_SIZE} />
        }
        onClick={() => navigateTo(routes.dashboard)}
        tooltipStyle={classes.tooltip}
        tooltip={<Typography variant="supporting.helper">{dictionary.dashboard}</Typography>}
      />
      <LargeIconButton
        selected={SidebarNavPages.EmailSettings === activeRoute}
        icon={(selected) =>
          selected ? <Mail_01 size={SIDEBAR_MENU_ICONS_SIZE} /> : <Mail_01 size={SIDEBAR_MENU_ICONS_SIZE} />
        }
        onClick={() => navigateTo(routes.emailSettings)}
        tooltipStyle={classes.tooltip}
        tooltip={<Typography variant="supporting.helper">{dictionary.emailSettings}</Typography>}
      />
      {currentUser?.role !== UserRoles.Recruiter && (
        <LargeIconButton
          selected={SidebarNavPages.AtsConnections === activeRoute}
          icon={(selected) =>
            selected ? <Monitor_01Fill size={SIDEBAR_MENU_ICONS_SIZE} /> : <Monitor_01 size={SIDEBAR_MENU_ICONS_SIZE} />
          }
          onClick={() => navigateTo(routes.atsConnections)}
          tooltipStyle={classes.tooltip}
          tooltip={<Typography variant="supporting.helper">{dictionary.ats}</Typography>}
        />
      )}
      <LargeIconButton
        selected={SidebarNavPages.BrowserExtension === activeRoute}
        icon={(selected) =>
          selected ? (
            <PuzzlePiece_01Fill size={SIDEBAR_MENU_ICONS_SIZE} />
          ) : (
            <PuzzlePiece_01 size={SIDEBAR_MENU_ICONS_SIZE} />
          )
        }
        onClick={() => navigateTo(routes.browserExtension)}
        tooltipStyle={classes.tooltip}
        tooltip={<Typography variant="supporting.helper">{dictionary.browserExtension}</Typography>}
      />
      {isStaff && (
        <LargeIconButton
          selected={SidebarNavPages.Admin === activeRoute}
          icon={(selected) =>
            selected ? <LayoutLeftFill size={SIDEBAR_MENU_ICONS_SIZE} /> : <LayoutLeft size={SIDEBAR_MENU_ICONS_SIZE} />
          }
          onClick={() => {
            if (activeTokenType === 'impersonate') {
              disposeImpersonatedToken();
            }

            navigateTo(routes.admin.dashboard);
          }}
          tooltipStyle={classes.tooltip}
          tooltip={<Typography variant="supporting.helper">{dictionary.adminPanel}</Typography>}
        />
      )}
    </ButtonGroup>
  );
};

export default SidebarNav;

import { CampaignResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { FilterOrder } from 'clients/types';
import { default as Candidate } from 'model/Candidate';
import { CandidateStatus } from 'types/candidate';
import { CandidateConfig, CandidateMatches } from '../CandidateContext/searchv3/types';

export enum CampaignTab {
  New = 'NEW',
  Shortlisted = 'SHORTLISTED',
  Contacted = 'CONTACTED',
  Matched = 'MATCHED',
  Hidden = 'HIDDEN',
  // invisible,
}

export enum CampaignJobboard {
  Database = 'ats',
  Monsterboard = 'monsterboard_v2',
  Linkedin = 'cvt',
  NationaleVacaturebank = 'nvb',
  Werknl = 'werknl',
  Indeed = 'indeed',
  Debanensite = 'debanensite',
  Techniekwerkt = 'techniekwerkt',
  Jobbird = 'jobbird',
  Uitzendbureau = 'uitzendbureau',
  Resumeio = 'resumeio',
  StudentJob = 'studentjob',

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // Werkzoeken = 'werkzoeken',
}

export const CampaignJobboardLabel: Record<CampaignJobboard, string> = {
  [CampaignJobboard.Indeed]: 'Indeed',
  [CampaignJobboard.Linkedin]: 'Linkedin',
  [CampaignJobboard.Werknl]: 'Werknl',
  [CampaignJobboard.Monsterboard]: 'Monsterboard',
  [CampaignJobboard.Debanensite]: 'Debanensite',
  [CampaignJobboard.Uitzendbureau]: 'Uitzendbureau',
  [CampaignJobboard.NationaleVacaturebank]: 'Nationale Vacaturebank',
  [CampaignJobboard.Resumeio]: 'Resume.io',
  [CampaignJobboard.Techniekwerkt]: 'Techniekwerkt',
  [CampaignJobboard.Database]: 'ATS',
  [CampaignJobboard.Jobbird]: 'Jobbird',
  [CampaignJobboard.StudentJob]: 'StudenJob',

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // [CampaignJobboard.Werkzoeken]: 'Werkzoeken',
};

export enum CampaignPage {
  Campaign = 'CAMPAIGN',
  Shared = 'SHARED',
}

export type SelectedCampaignContextProps = {
  children: React.ReactNode;
};

export type SelectedCampaignFilterState = {
  order: FilterOrder;
  setOrder: React.Dispatch<React.SetStateAction<FilterOrder>>;
  labels: string[];
  setLabels: React.Dispatch<React.SetStateAction<string[]>>;
};

export type SelectedCampaignState = {
  campaign: CampaignResponse | undefined;
  filters: SelectedCampaignFilterState;
  candidates: {
    data: Candidate[];
    isLoading: boolean;
    total: number;
  };
  matches: {
    data: CandidateMatches;
    isLoading: boolean;
  };
  stats: {
    data: CampaignStats;
    isLoading: boolean;
  };
  facets: {
    data: CampaignFacets;
    isLoading: boolean;
  };
  selectedTab: CampaignTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<CampaignTab>>;
  selectedCandidate: Candidate | undefined;
  selectedCandidateConfig: CandidateConfig;
  setSelectedCandidateConfig: React.Dispatch<React.SetStateAction<CandidateConfig>>;
  setSelectedCandidate: (candidate: Candidate | undefined) => void;
  selectedPage: number;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
};

export type CampaignStats = Record<CandidateStatus, number>;

export type CampaignFacets = Record<CampaignJobboard, number>;

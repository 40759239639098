import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  new: ({ theme }) => css`
    & > div {
      color: ${theme.colors.secondary.emerald[60]};
      background-color: ${theme.colors.secondary.emerald[10]};
      gap: 0;

      &:first-of-type {
        padding-left: 3px;
      }
    }
  `,
  updated: ({ theme }) => css`
    & > div {
      color: ${theme.colors.secondary.sky[50]};
      background-color: ${theme.colors.secondary.sky[10]};
      gap: 0;

      &:first-of-type {
        padding-left: 3px;
      }
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
